import { configureStore } from "@reduxjs/toolkit";
import authStorage from "./middleware/authStorage";

import reducer from "./reducer";

export default function createStore() {
    return configureStore({
        reducer,
        middleware: [
            authStorage
        ]
    });
}
