import { createSlice } from "@reduxjs/toolkit";

import crust from "./base/crust";


const initialState = {
    name: '',
    preferredMeasurementSystem: 'metricMillis'
};

const slice = createSlice({
    name: "profile",
    initialState,
    reducers: {
        profileReceived: (state, { payload }) => {
            crust.updateProps(state, { ...initialState, ...payload });
        },
        profileUpdated: (state, { payload }) => {
            crust.updateProps(state, payload);
        }
    }
});


export default slice.reducer;

export const {
    profileReceived,
    profileUpdated
} = slice.actions;


export const getProfile = state => state.features.profile;
