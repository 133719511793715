import React from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { didSessionExpire, isLoggedIn } from "../store/auth";

const CaptivePortal = connect(
    (state) => ({
        loggedIn: isLoggedIn(state),
        didSessionExpire: didSessionExpire(state)
    })
)(({ loggedIn, didSessionExpire, auth, element, defaultToPath = null }) => {
    const location = useLocation();
    const navigate = useNavigate();

    const displayContent = loggedIn || didSessionExpire;

    React.useEffect(() => {
        if (!displayContent) {
            if (defaultToPath) {
                if (location.pathname !== defaultToPath) {
                    navigate(defaultToPath);
                }
            }
        }
    }, [displayContent, defaultToPath, location.pathname, navigate]);

    return displayContent ? element : auth;
});

export default CaptivePortal;
