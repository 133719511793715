import React, { useCallback, useState } from "react";
import { Box, Button, Grid, IconButton, Menu, MenuItem, Stack, TextField, Typography } from "@mui/material";

import XTField from "../../../components/XTField/XTField";
import { convertMeasSystemValue, getMeasSystem, jobMeasurementSystemsMenu } from "../../../util/meas";
import { RequireConfirmation } from "../../../util/util";

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';
import DoneIcon from '@mui/icons-material/Done';
import PaletteIcon from '@mui/icons-material/Palette';

import { jobTagColors } from "../../../config/constants/colors";
import strings from "../../../config/strings";


function OptionsMainMenu({ anchor, readonly, open, onClose, onColorsSubmenuClicked, properties, onPropertyChange }) {
    const measSys = getMeasSystem(properties.system);

    return (
        <Menu
            id="options-menu"
            variant="menu"
            anchorEl={anchor}
            open={open}
            onClose={onClose}
        >
            <Box sx={{ p: 2, pt: 1, width: 350 }}>
                <Typography variant="overline">
                    {strings.get('identification')}
                </Typography>
                <Box sx={{ display: 'flex', mt: 2, mb: 2 }}>
                    <XTField readonly={readonly} label={strings.get('title')} variant="outlined" size="small" sx={{ flex: 1, mr: 2 }} value={properties.name} onValueChange={(value) => onPropertyChange({ name: value })} />
                    <IconButton sx={{ color: jobTagColors[properties.colorTag] }} onClick={readonly ? null : onColorsSubmenuClicked}>
                        <PaletteIcon />
                    </IconButton>
                </Box>
                <Typography variant="overline">
                    {strings.get('general')}
                </Typography>
                <Stack direction="row" spacing={0} sx={{ mt: 2, mb: 2 }}>
                    <TextField
                        select
                        disabled={readonly}
                        size="small"
                        label={strings.get('measSystem')}
                        value={properties.system}
                        onChange={(event) => RequireConfirmation(strings.get('qMeasSystemTruncation'), () => onPropertyChange({ system: event.target.value }))}
                        sx={{ flex: 1 }}
                    >
                        {jobMeasurementSystemsMenu.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {strings.get(option.label)}
                            </MenuItem>
                        ))}
                    </TextField>
                </Stack>
                <Stack direction="row" spacing={2} sx={{ mt: 2, mb: 2 }}>
                    <XTField readonly={readonly} label={strings.get('bladeWidth')} variant="outlined" size="small" decoder={measSys.decoder} encoder={measSys.encoder} suffix={measSys.notation} value={properties.bladeWidth} onValueChange={(value) => onPropertyChange({ bladeWidth: value })} />
                    <XTField readonly={readonly} label={strings.get('maxCutsPerStock')} variant="outlined" size="small" type="number" value={properties.maxCuts} onValueChange={(value) => onPropertyChange({ maxCuts: value })} />
                </Stack>
                <Typography variant="overline">
                    {strings.get('wasteAllocation')}
                </Typography>
                <Stack direction="row" spacing={0} sx={{ mt: 2 }}>
                    <XTField readonly={readonly} label={strings.get('minReusableWaste')} variant="outlined" size="small" sx={{ flex: 1 }} decoder={measSys.decoder} encoder={measSys.encoder} suffix={measSys.notation} value={properties.minReusableLength} onValueChange={(value) => onPropertyChange({ minReusableLength: value })} />
                    {/*<TextField label="Max. garbage waste" variant="outlined" size="small" type="number" defaultValue={1000} />*/}
                </Stack>
                <Box sx={{ textAlign: 'center', mt: 4, mb: 1 }}>
                    <Button fullWidth variant="contained" size="small" startIcon={readonly ? null : <DoneIcon />} onClick={onClose}>
                        {readonly ? strings.get('close') : strings.get('saveConfig')}
                    </Button>
                </Box>
            </Box>
        </Menu>
    );
}

function ColorsSubMenu({ anchor, open, onClose, properties, onPropertyChange }) {
    const handleColorButton = useCallback((colorIndex) => {
        onPropertyChange({ colorTag: colorIndex })
        onClose();
    }, [onPropertyChange, onClose]);

    return (
        <Menu
            id="colors-menu"
            variant="menu"
            anchorEl={anchor}
            open={open}
            onClose={onClose}
        >
            <Box sx={{ p: 1 }}>
                <Typography variant="overline" sx={{ ml: 1 }}>
                    {strings.get('jobLabelColor')}
                </Typography>
                <Grid container spacing={1}>
                    {jobTagColors.map((item, index) => (
                        <Grid key={index} item xs={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <IconButton sx={{ color: item }} onClick={() => handleColorButton(index)}>
                                {index === properties.colorTag ? <CheckCircleIcon /> : <CircleIcon />}
                            </IconButton>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Menu>
    );
}

function OptionsMenu({ anchor, readonly, open, onClose, properties, onPropertiesAdjusted }) {
    const [subMenuAnchor, setSubMenuAnchor] = useState(null);
    const [colorsMenuOpen, setColorsMenuOpen] = useState(false);

    const [changes, setChanges] = useState({});

    const onPropertyChange = useCallback((change) => {
        const extras = {};
        if (change.system !== undefined) {
            const currentProperties = { ...properties, ...changes };
            const currentSystem = currentProperties.system;
            if (currentSystem !== change.system) {
                extras.bladeWidth = convertMeasSystemValue(currentProperties.bladeWidth, currentSystem, change.system);
                extras.minReusableLength = convertMeasSystemValue(currentProperties.minReusableLength, currentSystem, change.system);
            }
        }
        setChanges({ ...changes, ...change, ...extras });
    }, [properties, changes]);

    const handleColorsMenuOpen = useCallback((event) => {
        setSubMenuAnchor(event.currentTarget);
        setColorsMenuOpen(true);
    }, []);

    const handleColorsMenuClose = useCallback(() => {
        setColorsMenuOpen(false);
    }, []);

    const handleClose = useCallback(() => {
        if (Object.keys(changes).length > 0) {
            onPropertiesAdjusted(changes);
            setChanges({});
        }
        onClose();
    }, [changes, onPropertiesAdjusted, onClose]);

    const displayProperties = { ...properties, ...changes };

    return (<>
        <OptionsMainMenu
            anchor={anchor}
            readonly={readonly}
            open={open}
            onClose={handleClose}
            onColorsSubmenuClicked={handleColorsMenuOpen}
            properties={displayProperties}
            onPropertyChange={onPropertyChange}
        />
        <ColorsSubMenu
            anchor={subMenuAnchor}
            open={colorsMenuOpen}
            onClose={handleColorsMenuClose}
            properties={displayProperties}
            onPropertyChange={onPropertyChange}
        />
    </>);
}

export { OptionsMainMenu, ColorsSubMenu, OptionsMenu };

