import React from "react";

const DEBUG_MODE = false;

const strings = {
    language: 'ro',

    _get: function(key, substitutions = null) {
        var lang = this[this.language];
        if (lang === undefined) {
            return '?LANG?';
        } else {
            var str = lang[key];
            if (str === undefined) {
                return '?DICT?';
            } else {
                if (substitutions != null) {
                    var adj = str;
                    for (const key in substitutions) {
                        adj = adj.replace(`#[${key}]`, substitutions[key]);
                    }
                    return adj;
                } else {
                    return str;
                }
            }
        }
    },

    get: function(key, substitutions = null) {
        if (DEBUG_MODE) {
            return `{${this._get(key, substitutions)}}`;
        } else {
            return this._get(key, substitutions);
        }
    },

    getMultiline: function(key, substitutions = null) {
        const splits = this.get(key, substitutions).split('\n');
        return splits.map((text, index) => (
            <React.Fragment key={key + '-' + index}>
                {text}
                {index < splits.length - 1 && <br />}
            </React.Fragment>
        ));
    },

    // TODO: Localize Stock manager, Account screen
    en: {
        navJobs: 'Jobs',
        navInventory: 'Inventory',
        navAccount: 'Account',
        navFeedback: 'Feedback',
        jobsTitle: 'Optimization jobs',
        newOptimization: 'New optimization',
        refresh: 'Refresh',
        loading: 'Loading...',
        viewConf: 'View configuration',
        configure: 'Configure',
        import: 'Import',
        demand: 'Demand',
        stock: 'Stock',
        addOrder: 'Add order',
        angles: 'Angles',
        labels: 'Labels',
        noMatsInAbstract: 'Materials unavailable in Abstract',
        delMat: 'Deleted material',
        noMat: 'No material',
        selMat: 'Select material...',
        start: 'Start',
        processing: 'Processing...',
        order: 'order',
        stockToBuy: 'stock to buy',
        availableStock: 'available stock',
        _and_: ' and ',
        invalidValuesForJob: 'You have invalid values for #[0]. Please correct all invalid values.',
        cannotOptimizeWithoutStock: 'Optimization cannot start without any cutting orders or any stock.',
        optimizationImpossible: 'Optimization cannot start as there are cuts that are longer than the provided stock.',
        optimizeWithoutMaterialConfirm: 'Are you sure you want to start the optimization without using a Material? Only do this if you do not need to keep track of inventory for this optimization.',
        qClearAllStock: 'This will clear all values for "Available stock". Are you sure?',
        qDeleteJob: 'Are you sure you want to delete this optimization job? This cannot be undone!',
        qCloseWindow: 'Are you sure you want to close this window?',
        qRemMaterial: 'Are you sure you want to remove the assigned material?',
        noJobResultYet: 'Result available once optimization finishes.',
        NCServer: 'Could not communicate with server. Please try again.',
        qClearAllFields: 'Are you sure you want to clear all fields?',
        succDel: 'Successfully deleted.',
        alreadyDel: 'It looks like the job you were trying to delete has already been deleted.',
        cannotDel: 'Unable delete job.',
        cantFetchMats: 'Could not fetch materials at this moment. Please try again.',
        cantLoadMat: 'Could not load the selected material. Please try again.',
        resultNowReady: 'Your optimization result is now ready.',
        cantFetchJobs: 'Unable to fetch jobs.',
        cantOpenJob: 'Unable to open optimization job.',
        cantLoadStock: 'Could not load stock data. Please close the window and try again.',
        cantLoadMatMaybeDeleted: 'Could not load material for this optimization job as it may have been deleted.',
        cantOpenUnexpectedError: 'Unable to open due to an unexpected error. Please try refreshing.',
        solutionAcceptedSuccessfully: 'Solution accepted successfully. Your stock has been updated.',
        cantAcceptSolutionRequiresRefresh: 'Could not perform adjustments on the inventory as it has been changed in the meantime. Please check the new proposed solution and try again.',
        somethingWentWrongWithYourRequest: 'Something went wrong while trying to process your request. Please try again.',
        unableToStartJob: 'Unable to start optimization job.',
        liveRefreshDisabledBecauseYouRoam: 'Live refresh is disabled on this page because you are using the app elsewhere.\nPlease refresh using the button.',
        importDidntWork: "That didn't work. Please make sure you select a valid file to import.",
        StockToBuy: 'Stock to buy',
        AvailableStock: 'Available stock',
        length: 'Length',
        qty: 'Qty.',
        label: 'Label',
        priority: 'Priority',
        angleL: 'Angle L',
        angleR: 'Angle R',
        invalidValue: 'Invalid value',
        orderName: 'Order name',
        viewResult: 'View Result',
        viewJobDetails: 'View Optimization Details',
        detailed: 'Detailed',
        lostMaterial: 'Deleted material',
        usingMaterial: 'Using #[0]',
        orderLabel: 'Order-Label',
        noOfCuts: '# of cuts',
        offcutSummary: 'Offcut summary',
        offcut: 'Offcut',
        reusable: 'Reusable',
        percentageLoss: 'Percentage loss',
        useFromAvStock: 'Use from available stock',
        resultingReusable: 'Resulting reusable stock',
        resultingWaste: 'Resulting waste',
        solutionAccepted: 'Solution accepted',
        acceptSolution: 'Accept solution',
        acceptSolutionTip: 'Clicking "Accept solution" will use the above result to adjust your inventory for #[0], subtracting any available stock and inserting the resulted reusable and waste stock (if any).',
        solutionAcceptedTip: 'Your inventory has been updated according to the above graph.',
        noInventorySolutionTip: 'The inventory data could not be loaded. Please close this window and try again.',
        failedToLoadStockTip: 'Failed to load stock data for the used material.\nThe stock below may not reflect the actual, current stock data for this material.\nYou will also not be able to use the "Accept solution" functionality.\nPlease close this window and try again.',
        matLostAfterOptimization: 'The material that was used with this optimization job has been deleted in the meantime.\nThe stock below may not reflect the actual data from the stock manager, as the material does not exist anymore.',
        snapshotTip: 'This is a snapshot of the available stock for this material as it was when the solution was accepted.\nIt does not reflect the current inventory.',
        stock_: 'Stock ',
        pcs: 'pcs.',
        buyNPcs: 'Buy #[0] pcs. (#[1] pcs. available from stock)',
        cycle_: 'Cycle ',
        identification: 'Identification',
        title: 'Title',
        general: 'General',
        measSystem: 'Measurement system',
        qMeasSystemTruncation: 'Changing the measurement system after you have already entered values may result in truncation depending on the system you select.\nAre you sure you want to continue?',
        bladeWidth: 'Blade width',
        maxCutsPerStock: 'Max. cuts / Stock unit',
        wasteAllocation: 'Waste allocation',
        minReusableWaste: 'Min. reusable offcut',
        jobLabelColor: 'Job label color',
        close: 'Close',
        saveConfig: 'Save configuration',
        prefMetricMillis: 'Metric (mm)',
        prefMetric: 'Metric (m)',
        prefImperial: 'Imperial',
        jobMetricMillis: 'Metric (mm), 2 decimal places',
        jobMetric: 'Metric (m), 2 decimal places',
        jobImperial: 'Imperial, 4 decimal places',
        jobAbstract: 'Abstract, Integer only, No stock',
        myAccount: 'My account',
        logout: 'Log out',
        inventoryTitle: 'Stock manager',
        materials: 'Materials',
        newMat: 'New material',
        varOpt: 'Variant (optional)',
        densOpt: 'Density (optional)',
        qDelMaterial: 'Are you sure you want to remove this material?',
        qDiscardMods: 'This will discard any modifications.',
        name: 'Name',
        cantFetchMatsInInventory: 'Could not fetch materials. Please try refreshing.',
        cantFetchStockForMat: 'Could not fetch stock data for this material. Please try again.',
        matsAddedSuccessfully: 'Material(s) added successfully.',
        cantAddMats: 'Could not add material(s). Please try again.',
        matAdjSuccessfully: 'Material adjusted successfully.',
        cantAdjMat: 'Could not adjust material. Please try again.',
        matDelSuccessfully: 'Material deleted successfully.',
        cantDelMat: 'Could not delete material. Please try again.',
        stockManOpFailed: 'Could not perform operation. Please check the list and try again.',
        stockManCantComm: 'An unexpected error has occurred while trying to communicate with the server. Please try again.',
        stockOpItemStock: 'Stock',
        stockOpItemWaste: 'Waste',
        stockOpDidRem: 'removed',
        stockOpDidAdd: 'added',
        stockOpDidMove: 'moved',
        stockOpSuccessful: '#[0] #[1] successfully.',
        usableStock: 'Usable stock',
        waste: 'Waste',
        remSel: 'Remove selected',
        nSelected: '#[0] selected',
        viewAs: 'View as',
        filterByTypes: 'Filter by types...',
        qClearSel: 'Clear selection?',
        addMat: 'Add material',
        custom: 'Custom',
        standardized: 'Standardized',
        matName: 'Material name',
        invalidName: 'Enter a valid name',
        dens: 'Density',
        enterAValidValue: 'Enter a valid value',
        stockVariantsTip: 'If your stock comes in many different variants of the same material, you can define them here.\nThey will be added as separate entries in the material browser and you can manage them on a per-variant basis.',
        useDens: 'Use density',
        matVars: 'Material variants',
        addVar: 'Add variant',
        nVarName: 'Variant #[0] name',
        nVarDens: 'Variant #[0] density',
        varNameTip: 'Width, color, anything descriptive',
        invalidVariantName: 'Enter a valid name or remove this variant',
        diffDens: 'Different density',
        qDiscard: 'Are you sure you want to discard?',
        qAbandonSelection: 'Are you sure you want to abandon the current selection?',
        matLibTip: 'Quickly import industry-standard materials from the library.\nThe material names will stick to your current language (English).',
        matCat: 'Material category',
        selImportVar: 'Select variants to import',
        genericVariant: 'Generic',
        loginPrompt: 'Welcome back',
        loginTip: 'Please enter your credentials.',
        failedLoginTip: 'Failed to log in.\nPlease ensure the user name and password combination is correct.',
        loginSessionExpiredPrompt: 'Session expired',
        loginSessionExpiredTip: 'Please re-enter your credentials to continue.',
        username: 'User name',
        password: 'Password',
        loginButton: 'Log in',
        result: 'Result',
        nextStep: 'Next step',
        orderN: 'Order #[0]',
        cannotReuseOptiData: 'Something went wrong while trying to reuse the requested stock data.',
        notAllCouldBeReused: 'Not all reusables have been added to the list as some of them are no longer in your inventory.',
        reuseStock: 'Reuse stock',
        reuseStockTip: 'A new optimization job will be created using the available reusable stock.',
        reuseTitle: 'Reused stock'
    },

    ro: {
        navJobs: 'Optimizări',
        navInventory: 'Inventar',
        navAccount: 'Cont',
        navFeedback: 'Feedback',
        jobsTitle: 'Sarcini optimizare',
        newOptimization: 'Optimizare nouă',
        refresh: 'Reîmprospătare',
        loading: 'Încărcare...',
        viewConf: 'Vezi configurarea',
        configure: 'Configurează',
        import: 'Import',
        demand: 'Cerere',
        stock: 'Stoc',
        addOrder: 'Comandă nouă',
        angles: 'Unghiuri',
        labels: 'Etichete',
        noMatsInAbstract: 'Materiale indisponibile în Abstract',
        delMat: 'Material șters',
        noMat: 'Fără material',
        selMat: 'Alege material...',
        start: 'Start',
        processing: 'În procesare...',
        order: 'comandă',
        stockToBuy: 'stoc de cumpărat',
        availableStock: 'stoc disponibil',
        _and_: ' și ',
        invalidValuesForJob: 'Există valori invalide pentru #[0]. Corectați valorile invalide și încercați din nou.',
        cannotOptimizeWithoutStock: 'Nu se poate porni optimizarea fără comenzi sau fără stoc.',
        optimizationImpossible: 'Optimizarea nu poate porni fiindcă există tăieri care sunt mai lungi ca toate elementele din stoc.',
        optimizeWithoutMaterialConfirm: 'Sunteți sigur că doriți pornirea optimizării fără a selecta un material? Faceți asta doar dacă nu doriți să țineți evidența inventarului.',
        qClearAllStock: 'Această acțiune va șterge toate elementele din secțiunea "Stoc disponibil". Sunteți sigur?',
        qDeleteJob: 'Sunteți sigur că doriți ștergerea acestei sarcini de optimizare? Acțiunea este permanentă!',
        qCloseWindow: 'Sigur doriți închiderea acestei ferestre?',
        qRemMaterial: 'Sunteți sigur că doriți să dezasociați materialul?',
        noJobResultYet: 'Rezultatul va fi disponibil odată ce sarcina de optimizare este procesată.',
        NCServer: 'Nu s-a putut efectua comunicarea cu serverul. Încercați din nou.',
        qClearAllFields: 'Sunteți sigur că doriți ștergerea acestor valori?',
        succDel: 'Șters.',
        alreadyDel: 'Se pare că optimizarea pe care ați încercat să o eliminați a fost ștearsă deja.',
        cannotDel: 'Nu s-a putut șterge.',
        cantFetchMats: 'Nu s-au putut încărca materialele. Încercați din nou.',
        cantLoadMat: 'Nu s-a putut încărca acest material. Încercați din nou.',
        resultNowReady: 'Rezultatul optimizării dvs. este acum disponibil.',
        cantFetchJobs: 'Nu s-au putut încărca optimizările.',
        cantOpenJob: 'Deschiderea acestei optimizări a eșuat.',
        cantLoadStock: 'Nu s-a putut încărca inventarul. Închideți fereastra și încercați din nou.',
        cantLoadMatMaybeDeleted: 'Materialul asociat optimizării nu a putut fi încărcat. Este posibil ca acesta să fi fost șters între timp.',
        cantOpenUnexpectedError: 'Nu s-au putut încărca datele din cauza unei erori neprevăzute. Vă rugăm să reîncărcați pagina.',
        solutionAcceptedSuccessfully: 'Soluția a fost acceptată și inventarul dvs. a fost actualizat.',
        cantAcceptSolutionRequiresRefresh: 'Nu s-au putut aplica modificările pe inventar deoarece acesta a fost ajustat între timp. Verificați soluția nouă, și încercați din nou.',
        somethingWentWrongWithYourRequest: 'A apărut o eroare neprevăzută. Vă rugăm încercați din nou.',
        unableToStartJob: 'Nu s-a putut lansa sarcina de optimizare.',
        liveRefreshDisabledBecauseYouRoam: 'Actualizările live sunt dezactivate deoarece ați deschis aplicația în alt tab sau pe alt dispozitiv.\nReîmprospătarea listei de optimizări se poate face manual prin buton',
        importDidntWork: 'Comanda a eșuat. Asigurați-vă că ați deschis un fișier valid.',
        StockToBuy: 'Stoc de cumpărat',
        AvailableStock: 'Stoc disponibil',
        length: 'Lungime',
        qty: 'Cantitate',
        label: 'Etichetă',
        priority: 'Prioritate',
        angleL: 'Unghi S',
        angleR: 'Unghi D',
        invalidValue: 'Valoare incorectă',
        orderName: 'Nume comandă',
        viewResult: 'Vezi rezultat',
        viewJobDetails: 'Vezi detalii',
        detailed: 'Detaliat',
        lostMaterial: 'Material șters',
        usingMaterial: 'Utilizând #[0]',
        orderLabel: 'Comandă-Etichetă',
        noOfCuts: 'nr. de tăieri',
        offcutSummary: 'Rezumat resturi',
        offcut: 'Rest',
        reusable: 'Reutilizabil',
        percentageLoss: 'Procentaj pierderi',
        useFromAvStock: 'Utilizare din inventar',
        resultingReusable: 'Stoc reutilizabil rezultant',
        resultingWaste: 'Pierderi rezultante',
        solutionAccepted: 'Soluție acceptată',
        acceptSolution: 'Acceptă soluția',
        acceptSolutionTip: 'Facând clic pe "Acceptă soluția", se va ajusta inventarul aferent materialului #[0], scăzând stocul disponibil și inserând reutilizabilele și pierderile aferente (după caz).',
        solutionAcceptedTip: 'Inventarul a fost actualizat în conformitate cu graficul de mai sus.',
        noInventorySolutionTip: 'Inventarul nu a putut fi încărcat din cauza unei erori. Închideți și redeschideți această fereastră pentru a reîncerca.',
        failedToLoadStockTip: 'Nu s-a putut încărca stocul disponibil pentru acest material.\nDatele de mai jos nu vor reflecta realitatea din inventar.\nDeasemenea, funcționalitatea de "Acceptă soluția" nu va fi disponibilă momentan.\nÎnchideți această fereastră și reîncercați.',
        matLostAfterOptimization: 'Materialul asociat acestei sarcini de optimizare a fost șters între timp.\nDatele de mai jos nu vor mai reflecta realitatea din inventar, întrucât fiindcă materialul a fost șters, inventarul său nu mai există.',
        snapshotTip: 'Aceasta este o captură a situației din inventarul aferent materialului, așa cum se prezenta la momentul acceptării soluției.\nDatele de mai jos nu reflectă valorile curente din inventar.',
        stock_: 'Stoc ',
        pcs: 'buc.',
        buyNPcs: 'Cumpără #[0] buc. (#[1] buc. disponibile din stoc)',
        cycle_: 'Ciclul ',
        identification: 'Identificare',
        title: 'Titlu',
        general: 'General',
        measSystem: 'Sistem de măsură',
        qMeasSystemTruncation: 'Schimbarea sistemului de măsură poate cauza trunchierea valorilor deja introduse (dacă există).\nSunteți sigur că doriți să continuați cu această selecție?',
        bladeWidth: 'Lățime lamă',
        maxCutsPerStock: 'Tăieri max. pe stoc',
        wasteAllocation: 'Alocare pierderi',
        minReusableWaste: 'Lungime min. reutilizabile',
        jobLabelColor: 'Culoare etichetă',
        close: 'Închide',
        saveConfig: 'Salvează configurația',
        prefMetricMillis: 'Metric (mm)',
        prefMetric: 'Metric (m)',
        prefImperial: 'Imperial',
        jobMetricMillis: 'Metric (mm), 2 zecimale',
        jobMetric: 'Metric (m), 2 zecimale',
        jobImperial: 'Imperial, 4 zecimale',
        jobAbstract: 'Abstract, Doar întreg, Fără stoc',
        myAccount: 'Contul meu',
        logout: 'Deconectare',
        inventoryTitle: 'Gestiune inventar',
        materials: 'Materiale',
        newMat: 'Material nou',
        varOpt: 'Variantă (opțional)',
        densOpt: 'Densitate (opțional)',
        qDelMaterial: 'Sunteți sigur că doriți ștergerea acestui material?',
        qDiscardMods: 'Modificările efectuate nu vor fi aplicate.',
        name: 'Nume',
        cantFetchMatsInInventory: 'Nu s-au putut încărca materialele. Încercați să reîmprospătați pagina.',
        cantFetchStockForMat: 'Nu s-a putut încărca inventarul pentru acest material. Încercați din nou.',
        matsAddedSuccessfully: 'Material(e) adăugat(e) cu succes.',
        cantAddMats: 'Nu s-au putut adăuga materialele. Încercați din nou.',
        matAdjSuccessfully: 'Materialul a fost ajustat cu succes.',
        cantAdjMat: 'Nu s-a putut ajusta materialul. Încercați din nou.',
        matDelSuccessfully: 'Materialul a fost șters cu succes.',
        cantDelMat: 'Nu s-a putut șterge materialul. Încercați din nou.',
        stockManOpFailed: 'Nu s-a putut aplica operația. Verificați lista și încercați din nou.',
        stockManCantComm: 'A apărut o eroare de comunicare cu serverul. Încercați din nou.',
        stockOpItemStock: 'Stocurile',
        stockOpItemWaste: 'Deșeurile',
        stockOpDidRem: 'șterse',
        stockOpDidAdd: 'adăugate',
        stockOpDidMove: 'mutate',
        stockOpSuccessful: '#[0] selectate au fost #[1] cu succes.',
        usableStock: 'Stoc utilizabil',
        waste: 'Deșeuri',
        remSel: 'Elimină selecția',
        nSelected: '#[0] selectate',
        viewAs: 'Vezi ca',
        filterByTypes: 'Filtrare după...',
        qClearSel: 'Anulezi selecția?',
        addMat: 'Adaugă material',
        custom: 'Particularizat',
        standardized: 'Standardizat',
        matName: 'Nume material',
        invalidName: 'Introd. un nume valid',
        dens: 'Densitate',
        enterAValidValue: 'Introd. o valoare validă',
        stockVariantsTip: 'Dacă stocurile dvs. vin în mai multe variante, le puteți crea aici.\nVariantele vor fi adăugate ca intrări separate în listă și se pot gestiona separat.',
        useDens: 'Cu densitate',
        matVars: 'Variante',
        addVar: 'Variantă nouă',
        nVarName: 'Nume variantă #[0]',
        nVarDens: 'Densitate variantă #[0]',
        varNameTip: 'Grosime, culoare, orice e descriptiv',
        invalidVariantName: 'Introd. o valoare validă sau ștergeți varianta',
        diffDens: 'Are altă densitate',
        qDiscard: 'Sigur doriți să anulați?',
        qAbandonSelection: 'Sigur doriți să anulați selecția curentă?',
        matLibTip: 'Importă rapid standarde industriale pentru materiale din biblioteca noastră.\nNumele materialelor vor rămâne așa cum apar în limba selectată (Română).',
        matCat: 'Categorie material',
        selImportVar: 'Alegeți variantele',
        genericVariant: 'Generic',
        loginPrompt: 'Bine ați revenit',
        loginTip: 'Introduceți datele de autentificare.',
        failedLoginTip: 'Nu ați putut fi autentificat.\nAsigurați-vă că datele introduse sunt valide.',
        loginSessionExpiredPrompt: 'Sesiunea a expirat',
        loginSessionExpiredTip: 'Este necesară reintroducerea datelor de autentificare pentru a continua.',
        username: 'Nume utilizator',
        password: 'Parolă',
        loginButton: 'Conectare',
        result: 'Rezultat',
        nextStep: 'Pas următor',
        orderN: 'Comanda #[0]',
        cannotReuseOptiData: 'Nu s-au putut încărca datele pentru stocul specificat.',
        notAllCouldBeReused: 'Nu s-au refolosit toate reutilizabilele întrucât o parte din ele nu mai există în stoc.',
        reuseStock: 'Reutilizare stoc',
        reuseStockTip: 'O nouă sarcină de optimizare va fi creată cu stocul reutilizabil rezultant din soluția prezentei.',
        reuseTitle: 'Reutilizare stoc'
    }
};

export default strings;
