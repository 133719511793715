import { createSlice } from "@reduxjs/toolkit";


const slice = createSlice({
    name: "ui",
    initialState: {
        appbar: {
            title: null,
            toolbar: null
        }
    },
    reducers: {
        appbarTitleRefUpdated: (state, { payload }) => {
            state.appbar.title = payload.title;
        },
        appbarToolbarRefUpdated: (state, { payload }) => {
            state.appbar.toolbar = payload.toolbar;
        }
    }
});


export default slice.reducer;

export const {
    appbarTitleRefUpdated,
    appbarToolbarRefUpdated
} = slice.actions;


export const getAppbarTitleRef = (state) => state.ui.appbar.title;
export const getAppbarToolbarRef = (state) => state.ui.appbar.toolbar;
