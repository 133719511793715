function RequireConfirmation(prompt, callback) {
    if (window.confirm(prompt)) {
        callback();
    }
}

function makeError(...args) {
    let error = '';
    for (let i = 0; i < args.length; i++) {
        error += args[i];
        if (i < args.length - 1) {
            error += ' ';
        }
    }
    return error;
}

function rgbaMul(src, r, g, b, a) {
    let srcVec = src.replaceAll(' ', '').replace('rgba(', '').replace(')', '').split(',');
    if (srcVec.length === 4) {
        srcVec[0] *= r;
        srcVec[1] *= g;
        srcVec[2] *= b;
        srcVec[3] *= a;
        return `rgba(${srcVec[0]}, ${srcVec[1]}, ${srcVec[2]}, ${srcVec[3]})`;
    } else {
        return src;
    }
}

function makeSolidColor(color, background) {
    let colorVec = color.replaceAll(' ', '').replace('rgba(', '').replace(')', '').split(',');
    let bgVec = background.replaceAll(' ', '').replace('rgba(', '').replace(')', '').split(',');
    let resultVec = [...bgVec];
    for (let i = 0; i < 3; i++) {
        resultVec[i] = colorVec[i] * colorVec[3] + bgVec[i] * (1 - colorVec[3]);
    }
    resultVec[3] = 1;
    return `rgba(${resultVec[0]}, ${resultVec[1]}, ${resultVec[2]}, ${resultVec[3]})`;
}

let currentAltRowStyleIndex = 0;

function getAltRowStyleIndex(...args) {
    let argsAllZero = true;
    for (let i = 0; i < args.length; i++) {
        if (args[i] !== 0) {
            argsAllZero = false;
            break;
        }
    }
    if (args.length > 0) {
        if (argsAllZero) {
            currentAltRowStyleIndex = 0;
        }
    }
    return currentAltRowStyleIndex++;
}

function multiplyComponent(amount, renderComponent) {
    const list = [];
    for (let i = 0; i < amount; i++) {
        list.push(renderComponent(i));
    }
    return list;
}

export {
    RequireConfirmation,
    makeError,
    rgbaMul,
    makeSolidColor,
    getAltRowStyleIndex,
    multiplyComponent
};
