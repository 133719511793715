import { store } from "../App";
import { accessTokenRefreshed, getAccessToken, getRefreshToken, sessionExpired } from "../store/auth";

import materials from "./materials";

const BASE_URL = 'https://test.opticut.expert';
const FAILURE_CODES = {
    AUTH_OTHER: -2, // Access token expired and app tried to use refresh token but server failed to process request
    OTHER: -1, // Server failed to process request
    EXCEPTION: 0, // App exception while processing request
    SESSION: 1 // User is no longer logged in or session expired
};

function reduxState() {
    return store.getState();
}

function dispatch(action) {
    store.dispatch(action);
}

const ENABLE_SELF_DESTRUCT_SESSION = false;
let sd = 5;
const getSd = () => sd <= 0 ? '1' : '';

async function callAPI(route, method, body = {}, headers = {}) {
    if (ENABLE_SELF_DESTRUCT_SESSION) sd--;
    return await fetch(BASE_URL + route, {
        method,
        headers: {
            'Content-Type': 'application/json',
            'Token': getAccessToken(reduxState()) + getSd(),
            ...headers
        },
        ...((method !== 'GET' && method !== 'HEAD') && {
            body: (typeof(body) === 'object' ? JSON.stringify(body) : body.toString())
        })
    });
}

async function buildResultData(result) {
    let data;
    try {
        data = await result.json();
    } catch (error) {
        data = {};
    }
    return data;
}

async function call(route, method = 'GET', body, headers) {
    console.log('API: ' + method + ' ' + route);
    console.log(headers);
    console.log(body);

    try {
        let response = await callAPI(route, method, body, headers);

        if (response.status === 401) {
            console.log('Access token expired, reauthenticating...');
            const authResponse = await callAPI('/reauthorize', 'POST', { refreshToken: getRefreshToken(reduxState()) + getSd() });
            const data = await buildResultData(response);
            const authData = await buildResultData(authResponse);
            const commonResult = {
                status: response.status,
                authStatus: authResponse.status,
                headers: response.headers,
                authHeaders: authResponse.headers,
                data,
                authData
            };

            if (authResponse.status === 401) {
                console.log('Refresh token expired, deleting session.');
                dispatch(sessionExpired());
                return {
                    ok: false,
                    reason: FAILURE_CODES.SESSION,
                    ...commonResult
                };
            } else {
                if (authResponse.ok) {
                    console.log('Access token refreshed successfully, retrying request...');
                    store.dispatch(accessTokenRefreshed(authData.accessToken));
                    response = await callAPI(route, method, body, headers);
                } else {
                    console.log('Failed to refresh access token.');
                    return {
                        ok: false,
                        reason: FAILURE_CODES.AUTH_OTHER,
                        ...commonResult
                    };
                }
            }
        }

        const data = await buildResultData(response);
        console.log('=> ' + response.status); console.log(data);
        return {
            ok: response.ok,
            status: response.status,
            headers: response.headers,
            data
        };
    } catch (error) {
        console.log('API call for ' + route + ' failed:');
        console.log(error);
        return {
            ok: false,
            reason: FAILURE_CODES.EXCEPTION
        };
    }
}

const api = {
    BASE_URL,
    FAILURE_CODES,
    call,
    materials
};

export default api;
