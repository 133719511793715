import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { connect, Provider } from "react-redux";
import {
    BrowserRouter as Router,
    Routes,
    Navigate,
    Route
} from "react-router-dom";

import createStore from './store/createStore';

import UIBase, { AppBarContent } from "./components/UIBase";
import LoginScreen from "./components/LoginScreen";
import CaptivePortal from "./components/CaptivePortal";
import AccountScreen from "./components/AccountScreen";
import JobsScreen from "./screens/JobsScreen/JobsScreen";
import StocksScreen from "./screens/StocksScreen/StocksScreen";
import WasteScreen from "./components/WasteScreen";
import { Alert, Box, CssBaseline, Fade, Snackbar, useMediaQuery } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { didSessionExpire, isLoggedIn, userLoggedIn } from "./store/auth";

const store = createStore();

const theme = createTheme({
    components: {
        MuiTextField: {
            defaultProps: {
                autoComplete: 'off'
            }
        }
    }
});

console.log(theme);

const GlobalUIContext = React.createContext({});

function useGlobalUI() {
    return useContext(GlobalUIContext);
}

function withGlobalUI(Component) {
    return function ComponentWithGlobalUI(props) {
        return (
            <GlobalUIContext.Consumer>
                {(ui) => <Component {...props} ui={ui} />}
            </GlobalUIContext.Consumer>
        );
    };
}

const Main = connect(
    (state) => ({
        isLoggedIn: isLoggedIn(state),
        didSessionExpire: didSessionExpire(state)
    }),
    (dispatch) => ({
        userLoggedIn: (tokens) => dispatch(userLoggedIn(tokens))
    })
)(({ isLoggedIn, didSessionExpire, userLoggedIn }) => {
    const handheld = useMediaQuery(theme.breakpoints.down('md'));

    const [snackbarText, setSnackbarText] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('info');
    const [snackbarVisible, setSnackbarVisible] = useState(false);

    // Used for tracking open state without recreating showSnackbar and in turn the context
    const hasSnackbar = useRef(false);

    const onSnackbarClose = useCallback(() => {
        setSnackbarVisible(false);
        hasSnackbar.current = false;
    }, []);

    const showSnackbar = useCallback((text, severity = 'info') => {
        const show = () => {
            setSnackbarText(text);
            setSnackbarSeverity(severity);
            setSnackbarVisible(true);
            hasSnackbar.current = true;
        };
        setTimeout(() => {
            if (hasSnackbar.current) {
                onSnackbarClose();
                setTimeout(() => {
                    show();
                }, 200);
            } else {
                show();
            }
        }, 100);
    }, [onSnackbarClose]);

    const uiContextValue = useMemo(() => ({
        showSnackbar
    }), [showSnackbar]);

    useEffect(() => {
        const storedAuth = localStorage.getItem('oc-auth');
        if (storedAuth) {
            try {
                const parsedAuth = JSON.parse(storedAuth);
                userLoggedIn(parsedAuth);
            } catch (error) {
                console.log('Could not recover session from localStorage:');
                console.log(error);
            }
        }
    }, [userLoggedIn]);

    return (
        <GlobalUIContext.Provider value={uiContextValue}>
            <Router>
                <Routes>
                    <Route path="/" element={
                        <CaptivePortal auth={<LoginScreen />} element={<UIBase />} />
                    }>
                        <Route path="/" element={<Navigate to="/account" />} />
                        <Route path="*" element={<><AppBarContent title="Error" />404<br />This endpoint is implemented yet</>} />
                        <Route path="/jobs" element={<JobsScreen />} />
                        <Route path="/account" element={<AccountScreen noResult2 />} />
                        <Route path="/inventory" element={<StocksScreen />} />
                        <Route path="/waste" element={<WasteScreen />} />
                    </Route>
                </Routes>
                {(!isLoggedIn && didSessionExpire) && <Fade in={true}>
                    <Box sx={{
                        width: '100vw',
                        height: '100vh',
                        top: 0,
                        left: 0,
                        position: 'absolute',
                        backdropFilter: 'blur(15px)',
                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                        zIndex: 1000000
                    }}>
                        <LoginScreen />
                    </Box>
                </Fade>}
            </Router>
            <Snackbar
                anchorOrigin={{
                    horizontal: handheld ? 'left' : 'center',
                    vertical: handheld ? 'bottom' : 'top'
                }}
                sx={(theme) => ({
                    [theme.breakpoints.down('sm')]: {
                        marginBottom: 7
                    }
                })}
                open={snackbarVisible}
                autoHideDuration={8000}
                onClose={onSnackbarClose}
            >
                <Alert severity={snackbarSeverity}>
                    {snackbarText}
                </Alert>
            </Snackbar>
        </GlobalUIContext.Provider>
    );
});

function App() {
    return (
        <Provider store={store}>
            <CssBaseline />
            <ThemeProvider theme={theme}>
                <Main />
            </ThemeProvider>
        </Provider>
    );
}


export default App;
export { store };
export { useGlobalUI, withGlobalUI };
