import { createSlice } from "@reduxjs/toolkit";


const slice = createSlice({
    name: "auth",
    initialState: {
        accessToken: null,
        refreshToken: null,
        sessionExpired: false
    },
    reducers: {
        userLoggedIn: (state, { payload }) => {
            state.accessToken = payload.accessToken;
            state.refreshToken = payload.refreshToken;
            state.sessionExpired = false;
        },
        userLoggedOut: (state, action) => {
            state.accessToken = null;
            state.refreshToken = null;
            state.sessionExpired = false;
        },
        sessionExpired: (state, action) => {
            state.accessToken = null;
            state.refreshToken = null;
            state.sessionExpired = true;
        },
        accessTokenRefreshed: (state, { payload }) => {
            state.accessToken = payload;
        }
    }
});


export default slice.reducer;

export const {
    userLoggedIn,
    userLoggedOut,
    sessionExpired,
    accessTokenRefreshed
} = slice.actions;


export const isLoggedIn = state => state.auth.accessToken !== null;
export const didSessionExpire = state => state.auth.sessionExpired;
export const getAccessToken = state => state.auth.accessToken;
export const getRefreshToken = state => state.auth.refreshToken;
