import { combineReducers } from "redux";

import uiReducer from "./ui";
import authReducer from "./auth";
import featuresReducer from "./features/reducer";

export default combineReducers({
    ui: uiReducer,
    auth: authReducer,
    features: featuresReducer
});
