
function numberDecoder(value) {
    let parsed = Number(value);
    if (Number.isNaN(parsed)) {
        return null;
    } else {
        return parsed;
    }
}

function posNumberDecoder(value) {
    let parsed = numberDecoder(value);
    if (parsed === null) {
        return null;
    } else {
        if (parsed <= 0) {
            return null;
        }
    }
    return parsed;
}

function integerDecoder(value) {
    let parsed = Number(value);
    if (Number.isNaN(parsed)) {
        return null;
    } else {
        return Math.floor(parsed);
    }
}

function posIntegerDecoder(value) {
    let parsed = integerDecoder(value);
    if (parsed === null) {
        return null;
    } else {
        if (parsed <= 0) {
            return null;
        }
    }
    return parsed;
}

export {
    numberDecoder,
    posNumberDecoder,
    integerDecoder,
    posIntegerDecoder
};
