import React, { useCallback, useState } from "react";
import { connect } from "react-redux";
import { Autocomplete, Avatar, Box, Button, Card, CardContent, CircularProgress, Collapse, IconButton, InputAdornment, TextField, Typography } from "@mui/material";

import XTField from "../../../components/XTField/XTField";
import { numberDecoder, posIntegerDecoder } from "../../../components/XTField/encoder";
import { convertMeasSystemValue, getMeasSystem, measurementSystems, precisionMeasurementSystems } from "../../../util/meas";
import { RequireConfirmation } from "../../../util/util";

import {
    getJobAvailableStock,
    getJobProperties, jobAutoStockAdjusted, jobAutoStockDeleted, jobAvailableStockAdjusted, jobAvailableStockDeleted
} from "../../../store/features/job";

import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import LockIcon from '@mui/icons-material/Lock';
import WarningIcon from '@mui/icons-material/Warning';
import LinkIcon from '@mui/icons-material/Link';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import InfoIcon from '@mui/icons-material/Info';

import { RESERVED_STOCK_PRIORITY } from "../../../config/constants/stock";
import { getUsableStocks } from "../../../store/features/stocks";
import strings from "../../../config/strings";


function priorityDecoder(value) {
    const parsed = numberDecoder(value);
    if (parsed !== null) {
        if (parsed >= RESERVED_STOCK_PRIORITY) {
            return null;
        }
        if (parsed < 0) {
            return null;
        }
    }
    return parsed;
}

function AutoStockEntryBase({ readonly, data, jobProperties, entryAdjusted, entryDeleted }) {
    const measSys = getMeasSystem(jobProperties.system);
    
    return (
        <tr>
            <td><XTField readonly={readonly} variant="outlined" fullWidth size="small" decoder={measSys.nonNullDecoder} encoder={measSys.encoder} suffix={measSys.notation} value={data.length} onValueChange={(value) => entryAdjusted({ length: value })} /></td>
            <td><XTField readonly={readonly} variant="outlined" fullWidth size="small" decoder={priorityDecoder} value={data.priority} onValueChange={(value) => entryAdjusted({ priority: value })} /></td>
            <td>
                {!readonly && <IconButton sx={{ color: 'inherit', ml: 3 }} onClick={entryDeleted}>
                    <RemoveCircleIcon color="error" />
                </IconButton>}
            </td>
        </tr>
    );
}

const AutoStockEntry = connect(
    (state) => ({
        jobProperties: getJobProperties(state)
    }),
    (dispatch, { index }) => ({
        entryAdjusted: (fields) => dispatch(jobAutoStockAdjusted({ stockIndex: index, fields })),
        entryDeleted: () => RequireConfirmation(strings.get('qClearAllFields'), () => dispatch(jobAutoStockDeleted(index)))
    })
)(AutoStockEntryBase);

function AutoStock({ readonly, suggestions, onAddButton, onClearButton, children }) {
    const [contentsVisible, setContentsVisible] = useState(true);

    const handleContentVisibilityToggle = useCallback(() => {
        setContentsVisible(!contentsVisible);
    }, [contentsVisible]);

    const handleAddButton = useCallback(() => {
        onAddButton && onAddButton();
        setContentsVisible(true);
    }, [onAddButton]);

    return (<>
        <Typography variant="subtitle" sx={{ display: 'flex', alignItems: 'center' }}>
            {strings.get('StockToBuy')}
            <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                {!readonly && <>
                    <IconButton sx={{ color: 'inherit', mr: 1 }} onClick={onClearButton}>
                        <DeleteSweepIcon color="error" />
                    </IconButton>
                    <IconButton sx={{ color: 'inherit', mr: 3 }} onClick={handleAddButton}>
                        <AddCircleIcon color="success" />
                    </IconButton>
                </>}
                <IconButton sx={{ color: 'inherit' }} onClick={handleContentVisibilityToggle}>
                    {!contentsVisible ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
            </Box>
        </Typography>
        {Array.isArray(suggestions) && <Box sx={{ mt: 1, mb: 1, pb: 1, '& button': { mr: 2 }, overflowX: 'auto' }}>
            {suggestions.map((item) => (
                <Button variant="outlined" size="small">
                    {item}
                </Button>
            ))}
        </Box>}
        <Collapse in={contentsVisible} mountOnEnter unmountOnExit>
            <Typography variant="subtitle2" component="table" cellPadding={0} cellSpacing={0} sx={{
                mt: 1,
                width: '100%',
                '& tr': {
                    verticalAlign: 'top'
                },
                '& td': {
                    paddingBottom: 0.5
                },
                '& th': {
                    textAlign: 'left'
                },
                '& td:nth-of-type(n+1):nth-last-of-type(n+3)': {
                    paddingRight: 0.5
                },
                '& .MuiInputBase-input': {
                    pl: 1, pr: 1
                }
            }}>
                <tbody>
                    <tr>
                        <th style={{ width: '70%' }}>
                            {strings.get('length')}
                        </th>
                        <th style={{ width: '30%' }}>
                            {strings.get('priority')}
                        </th>
                        <th>
                            <IconButton sx={{ opacity: 0, ml: 1 }} disabled>
                                <RemoveCircleIcon />
                            </IconButton>
                        </th>
                    </tr>
                    {children}
                </tbody>
            </Typography>
        </Collapse>
    </>);
}

function StockEntryBase({ readonly, data, jobProperties, jobAvailableStock, entryAdjusted, entryDeleted, canned = false, stocks }) {
    const cannedItem = canned ? (stocks.find((x) => x.id === data.sku) ?? null) : null;
    const measSys = getMeasSystem(jobProperties.system);

    const getConvertedCannedItem = useCallback((length) => convertMeasSystemValue(
        length, 'metricMillis', jobProperties.system,
        precisionMeasurementSystems, measurementSystems
    ), [jobProperties.system]);

    let cannedItemError;
    if (!cannedItem) {
        cannedItemError = true;
    } else {
        cannedItemError = getConvertedCannedItem(cannedItem.length) === 0;
    }

    let filteredOptions = stocks.filter((x) => !jobAvailableStock.some(y => y.sku === x.id) || x === cannedItem);

    return (
        <tr>
            <td>
                {!canned && <XTField readonly={readonly} variant="outlined" fullWidth size="small" decoder={measSys.nonNullDecoder} encoder={measSys.encoder} suffix={measSys.notation} value={data.length} onValueChange={(value) => entryAdjusted({ length: value })} />}
                {canned && (
                    <Autocomplete
                        fullWidth
                        size="small"
                        options={filteredOptions}
                        value={cannedItem}
                        autoHighlight
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                error={cannedItemError}
                                helperText={cannedItemError && strings.get('invalidValue')}
                                inputProps={{
                                    ...params.inputProps
                                }}
                            />
                        )}
                        getOptionLabel={(option) => measSys.encode(getConvertedCannedItem(option.length)) + ' ' + measSys.notation}
                        renderOption={(params, option) => (
                            <Box component="li" {...params} key={option.id} sx={(theme) => ({
                                color: getConvertedCannedItem(option.length) === 0 ? theme.palette.error.main : 'inherit'
                            })}>
                                <Box>
                                    {measSys.encode(getConvertedCannedItem(option.length)) + ' ' + measSys.notation}
                                </Box>
                                <Box sx={{
                                    flex: 1,
                                    opacity: 0.75,
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    fontSize: '0.8em',
                                    fontWeight: 'bold'
                                }}>
                                    {option.count} {strings.get('pcs')}
                                </Box>
                            </Box>
                        )}
                        onChange={(event, newValue) => {
                            entryAdjusted({
                                qty: newValue ? newValue.count : null,
                                length: newValue ? getConvertedCannedItem(newValue.length) : null,
                                sku: newValue ? newValue.id : null
                            });
                        }}
                    />
                )}
            </td>
            <td>
                <XTField
                    readonly={readonly || canned}
                    variant="outlined"
                    fullWidth
                    size="small"
                    decoder={(value) => {
                        let decoded = posIntegerDecoder(value);
                        if (canned) {
                            if (cannedItem) {
                                if (decoded > cannedItem.count) {
                                    decoded = null;
                                }
                            } else {
                                // decoded = null;
                            }
                        }
                        return decoded;
                    }}
                    value={data.qty}
                    onValueChange={(value) => entryAdjusted({ qty: value })}
                    validationErrorProps={{
                        ...((canned) && {
                            helperText: null
                        }),
                        ...((readonly) && {
                            helperText: 'Out of stock'
                        })
                    }}
                    InputProps={{
                        ...((canned) && {
                            endAdornment: <InputAdornment position="end"><LockIcon sx={{ opacity: 0.75 }} /></InputAdornment>
                        })
                        /*
                        ...((cannedItem) && {
                            endAdornment: <InputAdornment position="end"> / {cannedItem.count} pcs</InputAdornment>
                        })
                    */}}
                />
            </td>
            <td><XTField readonly={readonly} variant="outlined" fullWidth size="small" decoder={priorityDecoder} value={data.priority} onValueChange={(value) => entryAdjusted({ priority: value })} /></td>
            <td>
                {!readonly && <IconButton sx={{ color: 'inherit', ml: 3 }} onClick={entryDeleted}>
                    <RemoveCircleIcon color="error" />
                </IconButton>}
            </td>
        </tr>
    );
}

const StockEntry = connect(
    (state) => ({
        jobProperties: getJobProperties(state),
        jobAvailableStock: getJobAvailableStock(state),
        stocks: getUsableStocks(state)
    }),
    (dispatch, { index }) => ({
        entryAdjusted: (fields) => dispatch(jobAvailableStockAdjusted({ stockIndex: index, fields })),
        entryDeleted: () => RequireConfirmation(strings.get('qClearAllFields'), () => dispatch(jobAvailableStockDeleted(index)))
    })
)(StockEntryBase);

function Stock({ readonly, onAddButton, onClearButton, children, fetching, fetchError, lostMaterial, snapshot, linkedMaterialName }) {
    const [contentsVisible, setContentsVisible] = useState(true);

    const handleContentVisibilityToggle = useCallback(() => {
        setContentsVisible(!contentsVisible);
    }, [contentsVisible]);

    const handleAddButton = useCallback(() => {
        onAddButton && onAddButton();
        setContentsVisible(true);
    }, [onAddButton]);

    const MaterialLinkIcon = lostMaterial ? LinkOffIcon : (fetchError ? WarningIcon : LinkIcon);
    const materialIconColor = lostMaterial ? 'warning' : (fetchError ? 'error' : 'secondary');

    return (<Box sx={{ whiteSpace: 'normal' }}>
        <Typography variant="subtitle" sx={{ display: 'flex', alignItems: 'center' }}>
            {strings.get('AvailableStock')}
            {linkedMaterialName && <>
                <MaterialLinkIcon color={materialIconColor} sx={{ mx: 1 }} /> <b>{linkedMaterialName}</b>
            </>}
            {fetching && <CircularProgress size="1em" sx={{ ml: 1 }} />}
            <Box sx={{ ml: 1, flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                {!readonly && <>
                    <IconButton sx={{ mr: 1 }} disabled={fetching} color="error" onClick={onClearButton}>
                        <DeleteSweepIcon color="inherit" />
                    </IconButton>
                    <IconButton sx={{ mr: 3 }} disabled={fetching} color="success" onClick={handleAddButton}>
                        <AddCircleIcon color="inherit" />
                    </IconButton>
                </>}
                <IconButton sx={{ color: 'inherit' }} onClick={handleContentVisibilityToggle}>
                    {!contentsVisible ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
            </Box>
        </Typography>
        {(fetchError || lostMaterial || snapshot) && <Card variant="outlined" sx={{ my: 2 }}>
            <CardContent sx={{ display: 'flex' }}>
                <Avatar sx={(theme) => ({ bgcolor: theme.palette[snapshot ? 'info' : 'warning'].light, mr: 2 })}>
                    {snapshot ? <InfoIcon /> : <WarningIcon />}
                </Avatar>
                {(fetchError && !snapshot) && <Typography variant="caption" sx={{ flex: 1 }}>
                    {strings.getMultiline('failedToLoadStockTip')}
                </Typography>}
                {(lostMaterial && !snapshot) && <Typography variant="caption" sx={{ flex: 1 }}>
                    {strings.getMultiline('matLostAfterOptimization')}
                </Typography>}
                {snapshot && <Typography variant="caption" sx={{ flex: 1 }}>
                    {strings.getMultiline('snapshotTip')}
                </Typography>}
            </CardContent>
        </Card>}
        <Collapse in={contentsVisible} mountOnEnter unmountOnExit>
            <Typography variant="subtitle2" component="table" cellPadding={0} cellSpacing={0} sx={{
                mt: 1,
                width: '100%',
                '& tr': {
                    verticalAlign: 'top'
                },
                '& td': {
                    paddingBottom: 0.5
                },
                '& th': {
                    textAlign: 'left'
                },
                '& td:nth-of-type(n+1):nth-last-of-type(n+3)': {
                    paddingRight: 0.5
                },
                '& .MuiInputBase-input': {
                    pl: 1, pr: 1
                }
            }}>
                <tbody>
                    <tr>
                        <th style={{ width: '40%' }}>
                            {strings.get('length')}
                        </th>
                        <th style={{ width: '30%' }}>
                            {strings.get('qty')}
                        </th>
                        <th style={{ width: '30%' }}>
                            {strings.get('priority')}
                        </th>
                        <th>
                            <IconButton sx={{ opacity: 0, ml: 1 }} disabled>
                                <RemoveCircleIcon />
                            </IconButton>
                        </th>
                    </tr>
                    {children}
                </tbody>
            </Typography>
        </Collapse>
    </Box>);
}

export { AutoStockEntryBase, AutoStockEntry, AutoStock, StockEntryBase, StockEntry, Stock };

