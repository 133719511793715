import React, { useCallback, useRef, useState } from 'react';
import { TextField } from '@mui/material';
import strings from '../../config/strings';

const builtInValidationErrorProps = {
    error: true,
    helperText: strings.get('invalidValue')
};

export default function XTField({
    Component,
    value, onValueChange, onFocus, onBlur, onKeyPress,
    initialValue = null, buffered = true, validated = true, forwardInvalid = true, blurOnEnter = true,
    validationErrorProps, decoder, encoder, suffix, readonly,
    ...props
}) {
    const [focused, setFocused] = useState(false);
    const [editText, setEditText] = useState('');
    const [knownValue, setKnownValue] = useState(null);

    const acquiredInternalTextState = useRef(false);
    const checkForUserError = useRef(false);
    const userError = useRef(false);
    let latchedText = null;
    // Uncontrolled w/ initial value
    if ((value === undefined) && (initialValue !== null)) {
        // editText not updated yet
        if (!acquiredInternalTextState.current) {
            latchedText = initialValue;
        }
    }

    let valid = true;
    let checkValue;
    if (focused) {
        checkValue = editText;
    } else {
        // Uncontrolled w/ initial value
        if (latchedText !== null) {
            checkValue = latchedText;
        } else {
            if (value === undefined) {
                checkValue = editText;
            } else {
                checkValue = String(value);
            }
        }
    }
    if (decoder) {
        valid = (decoder(checkValue) !== null);
        if (checkForUserError.current) {
            userError.current = !valid;
            checkForUserError.current = false;
        } else {
            if (valid) {
                userError.current = false;
            }
        }
    }

    const GetComponent = useCallback(() => {
        if (Component) {
            return Component;
        } else {
            return TextField;
        }
    }, [Component]);
    const handleValueChange = useCallback((value) => {
        if (onValueChange) {
            if (decoder) {
                const parsed = decoder(value);
                if ((parsed !== null) || (forwardInvalid)) {
                    onValueChange(parsed);
                }
            } else {
                let parsed = value;
                if (props.type === "number") {
                    parsed = Number(value);
                }
                onValueChange(parsed);
            }
        }
    }, [decoder, forwardInvalid, onValueChange, props.type]);
    const onChange = useCallback((event) => {
        const newValue = event.target.value;
        setEditText(newValue);
        checkForUserError.current = true;
        acquiredInternalTextState.current = true;
        if (!buffered) {
            handleValueChange(newValue);
        }
    }, [buffered, handleValueChange]);
    const handleFocus = useCallback((event) => {
        if (readonly) return;
        let targetValue = event.target.value;
        // Remove suffix
        if (suffix !== undefined) {
            const lastIndex = targetValue.lastIndexOf(suffix);
            console.log(lastIndex);
            if (lastIndex === (targetValue.length - suffix.length)) {
                // -1 to take the space into account
                targetValue = targetValue.slice(0, lastIndex - 1);
            }
        }
        setKnownValue(targetValue);
        setEditText(targetValue);
        setFocused(true);
        if (onFocus) {
            onFocus(event);
        }
    }, [onFocus, suffix, readonly]);
    const handleBlur = useCallback((event) => {
        if (readonly) return;
        setFocused(false);
        if (buffered) {
            if (event.target.value !== knownValue) {
                handleValueChange(event.target.value);
            }
        }
        if ((valid) && (value !== undefined)) {
            // Only keep editText if decoded value is invalid for controlled inputs;
            // getValue will persist editText on an unfocused invalid controlled input
            setEditText('');
        }
        if (onBlur) {
            onBlur(event);
        }
    }, [valid, buffered, knownValue, handleValueChange, onBlur, readonly, value]);
    const handleKeyPress = useCallback((event) => {
        if (onKeyPress) {
            onKeyPress(event);
        }
        if (event.key === 'Enter') {
            if (blurOnEnter) {
                event.target.blur();
            }
        }
    }, [blurOnEnter, onKeyPress]);
    const getValue = useCallback(() => {
        if (focused) {
            return editText;
        }
        if (!valid) {
            // Uncontrolled with initial value
            if (latchedText !== null) {
                return latchedText;
            } else {
                // Uncontrolled, no initial value
                if (value === undefined) {
                    // Always show internal state for uncontrolled without any initial value
                    return editText;
                } else {
                    // Controlled with non-null value
                    if (value !== null) {
                        return String(value);
                    } else {
                        // Controlled with null value and validation
                        if (validated) {
                            // Persist editText to emphasize mistake in case of user error
                            if (userError.current) {
                                return editText;
                            } else {
                                return '';
                            }
                        } else {
                            // Controlled with null value, without validation
                            // Display empty string
                            return '';
                        }
                    }
                }
            }
        } else {
            let retVal;
            // Uncontrolled with initial value
            if (latchedText !== null) {
                retVal = latchedText;
            } else {
                // Controlled
                if (value !== undefined) {
                    retVal = String(value);
                }
                // Uncontrolled
                else {
                    retVal = editText;
                }
            }
            if (encoder) {
                return encoder(retVal);
            } else {
                return retVal;
            }
        }
    }, [latchedText, value, valid, validated, editText, focused, encoder]);
    const getDisplayValue = useCallback(() => {
        if ((suffix !== undefined) && ((!focused) || (readonly)) && (valid)) {
            return getValue() + ' ' + suffix;
        } else {
            return getValue();
        }
    }, [getValue, suffix, focused, valid, readonly]);
    const getValidationProps = useCallback(() => {
        const baseErrorProps = Component ? {} : builtInValidationErrorProps;
        if (validated) {
            if (!valid) {
                if (validationErrorProps) {
                    return {
                        ...baseErrorProps,
                        ...validationErrorProps
                    };
                } else {
                    return baseErrorProps;
                }
            }
        }
        return {};
    }, [Component, validated, valid, validationErrorProps]);

    const TextFieldComponent = GetComponent();

    return <TextFieldComponent
        {...props}
        {...getValidationProps()}
        value={getDisplayValue()}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyPress={handleKeyPress}
        onChange={readonly ? null : onChange}
    />;
}
