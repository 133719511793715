function updateProps(self, props, exceptions = null) {
    for (const propName in props) {
        if (exceptions !== null) {
            if (exceptions.indexOf(propName) !== -1) {
                continue;
            }
        }
        if (self[propName] !== undefined) {
            self[propName] = props[propName];
        }
    }
}


function entitiesReceived(namespace, state, { payload }) {
    state[namespace] = payload;
}

function entitiesAdded(namespace, state, { payload }) {
    state[namespace].push.apply(state[namespace], payload);
}

function entityAdded(namespace, state, action) {
    entitiesAdded(namespace, state, { payload: [action.payload] });
}

// TODO-LP: Modify adjustment/deletion reducers to accept predicates/flexible matching, "id" is too opinionated
// TODO-LP: Change namespace behaviour to allow 'root' namespace (no namespacing) and nested namespace resolution

function entitiesAdjusted(namespace, state, { payload }) {
    payload.forEach((adjust) => {
        state[namespace].forEach((element) => {
            if (element.id === adjust.id) {
                for (var key in adjust) {
                    element[key] = adjust[key];
                }
            }
        });
    });
}

function entitiesUpdated(namespace, state, { payload }) {
    const { items, alignment = 1 } = payload;
    items.forEach((newElement) => {
        if (state[namespace].some(element => element.id === newElement.id)) {
            state[namespace].forEach((element) => {
                if (element.id === newElement.id) {
                    for (var key in newElement) {
                        element[key] = newElement[key];
                    }
                }
            });
        } else {
            if (alignment > 0) {
                state[namespace].push(newElement);
            } else {
                state[namespace].unshift(newElement);
            }
        }
    });
}

function entityAdjusted(namespace, state, action) {
    entitiesAdjusted(namespace, state, { payload: [action.payload] });
}

function entityUpdated(namespace, state, action) {
    entitiesUpdated(namespace, state, { payload: [action.payload] });
}

function entitiesDeleted(namespace, state, { payload }) {
    state[namespace] = state[namespace].filter(element => payload.indexOf(element.id) === -1);
}

function entityDeleted(namespace, state, action) {
    entitiesDeleted(namespace, state, { payload: [action.payload] });
}

/*
    {
        namespace: {
            action: [generic]reducer,
            ...
        }
    }
*/
function getSliceReducers(namespaces) {
    let reducers = {};

    for (const namespaceKey in namespaces) {
        let namespace = namespaces[namespaceKey];
        for (const actionName in namespace) {
            reducers[actionName] = (state, action) => namespace[actionName](namespaceKey, state, action);
        }
    }

    return reducers;
}


const crust = {
    updateProps,

    entitiesReceived,
    entitiesAdded,
    entitiesAdjusted,
    entitiesUpdated,
    entitiesDeleted,

    entityAdded,
    entityAdjusted,
    entityUpdated,
    entityDeleted,

    getSliceReducers
};

export default crust;
