import { styled } from "@mui/system";


const DesktopActions = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    position: 'sticky',
    top: 0,
    zIndex: theme.zIndex.appBar - 1,
    // marginBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
        display: 'none'
    }
}));

const PseudoToolbar = styled('div')(({ theme }) => ({
    ...theme.mixins.toolbar
}));


export {
    DesktopActions,
    PseudoToolbar
};

