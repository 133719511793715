import React, { useCallback, useState } from "react";
import { connect } from "react-redux";
import { Box, Chip, Collapse, Divider, IconButton, Typography } from "@mui/material";

import { getJobOrders, getJobProperties } from "../../../store/features/job";
import { getMeasSystem } from "../../../util/meas";

import AutorenewIcon from '@mui/icons-material/Autorenew';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PaidIcon from '@mui/icons-material/Paid';
import StraightenIcon from '@mui/icons-material/Straighten';
import DoneIcon from '@mui/icons-material/Done';
import strings from "../../../config/strings";


const getCutDetails = (jobOrders, order, item) => {
    let result = '';
    let candidate;

    if (order !== -1) {
        candidate = jobOrders[order].name;
        if (candidate === null) {
            candidate = '';
        }
        result += candidate;
    }
    if (item !== -1) {
        candidate = jobOrders[order].entries[item].label;
        if (candidate === null) {
            candidate = '';
        }
        if (result !== '') {
            if (candidate !== '') {
                result += '-';
            }
        }
        result += candidate;
    }

    return result;
}


const ResultItemBase = ({ stockUnit, index, detailed, jobProperties, jobOrders }) => {
    const [expanded, setExpanded] = useState(true);

    const onExpandedToggle = useCallback(() => {
        setExpanded(!expanded);
    }, [expanded]);

    const measSys = getMeasSystem(jobProperties.system);

    return (
        <Box sx={(theme) => ({
            borderRadius: theme.shape.borderRadius / 2,
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: theme.palette.divider,
            ml: 2, mr: 2,
            mb: 3
        })}>
            <Box sx={(theme) => ({ p: 1, pl: 2, display: 'flex', alignItems: 'center', backgroundColor: expanded ? theme.palette.primary.main : theme.palette.success.main, borderRadius: theme.shape.borderRadius / 2, borderBottomLeftRadius: expanded ? 0 : theme.shape.borderRadius * 2, borderBottomRightRadius: expanded ? 0 : theme.shape.borderRadius * 2, color: theme.palette.background.paper })}>
                <Chip label={<Typography variant="overline" sx={(theme) => ({ color: expanded ? theme.palette.primary.main : theme.palette.success.main })}>{expanded ? strings.get('stock_') : ''}#<b>{index + 1}</b></Typography>} size="small" variant="filled" sx={(theme) => ({ mr: 2, backgroundColor: theme.palette.background.paper })} />
                <StraightenIcon sx={{ color: 'inherit', mr: 1 }} />
                <Typography variant="body1" sx={{ fontWeight: 'bold', mr: 0.5 }}>
                    {measSys.encoder(stockUnit.length)}
                </Typography>
                <Typography variant="body2" sx={{ lineHeight: 'revert', mr: 0.5 }}>
                    {measSys.notation}
                </Typography>
                {/*<Divider orientation="vertical" sx={{ borderColor: 'rgba(255, 255, 255, 0.25)', ml: 0.5, mr: 1.5 }} flexItem />*/}
                <Typography variant="body1" sx={{ mr: 1 }}>
                    ⨉
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: 'bold', mr: 0.5 }}>
                    {stockUnit.count}
                </Typography>
                <Typography variant="body2" sx={{ lineHeight: 'revert', display: 'flex', alignItems: 'center' }}>
                    {strings.get('pcs')}
                </Typography>
                {(stockUnit.buy > 0 && !expanded && false) && <>
                    &nbsp;({stockUnit.count - stockUnit.buy > 0 && <>{stockUnit.count - stockUnit.buy} + </>}buy {stockUnit.buy})
                </>}
                {(stockUnit.buy > 0 && !expanded ) && <Chip
                    size="small"
                    icon={<PaidIcon />}
                    color="warning"
                    variant="filled"
                    label={<b>{stockUnit.buy}</b>}
                    sx={{ ml: 0.5, transform: 'scale(0.9, 0.9)' }}
                />}
                <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <IconButton sx={{ color: 'inherit' }} onClick={onExpandedToggle}>
                        {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                </Box>
            </Box>
            <Collapse in={expanded} mountOnEnter unmountOnExit>
                {stockUnit.buy > 0 && <Chip
                    icon={<PaidIcon />}
                    color="warning"
                    variant="filled"
                    label={strings.get('buyNPcs', [stockUnit.buy, stockUnit.count - stockUnit.buy])}
                    sx={{ m: 1, mb: 0 }}
                />}
                {stockUnit.cycles.map((cycle, index) => (<Box key={index} sx={{ p: 1 }}>
                    <Box sx={(theme) => ({ width: '100%', paddingRight: theme.shape.borderRadius * 2 + 'px', display: 'flex', alignItems: 'flex-end' })}>
                        <Box sx={(theme) => ({
                            borderTopLeftRadius: theme.shape.borderRadius * 2,
                            borderTopRightRadius: theme.shape.borderRadius * 2,
                            backgroundColor: theme.palette.action.hover,
                            borderStyle: 'solid',
                            borderWidth: 1,
                            borderColor: theme.palette.divider,
                            borderBottomWidth: 0,
                            width: 'fit-content',
                            display: 'flex',
                            alignItems: 'center',
                            p: 1,
                            pt: 0, pb: 0
                        })}>
                            <ContentCutIcon fontSize="inherit" sx={{ mr: 1 }} />
                            <Typography variant="overline" sx={{ mr: 1 }}>{strings.get('cycle_')}<b>{index + 1}</b></Typography>
                            <Box>
                                <Typography variant="body2" sx={(theme) => ({ backgroundColor: theme.palette.secondary.main, color: theme.palette.background.paper, borderRadius: 100, pl: 0.75, pr: 0.75, pt: 0.1, pb: 0.1 })}>
                                    ⨉ {cycle.repeat}
                                </Typography>
                            </Box>
                        </Box>
                        <Box sx={(theme) => ({ flex: 1, height: '1px', backgroundColor: theme.palette.divider, position: 'relative', top: 1, right: 1, marginRight: '-1px' })} />
                    </Box>
                    <Box sx={{ position: 'relative' }}>
                        <Box sx={{ position: 'absolute', width: '100%', height: '100%', display: 'flex' }}>
                            <Box sx={(theme) => ({
                                borderRadius: theme.shape.borderRadius / 2,
                                borderTopRightRadius: 0,
                                borderBottomRightRadius: 0,
                                borderTopLeftRadius: 0,
                                flex: 1,
                                backgroundColor: theme.palette.action.hover,
                                borderStyle: 'solid',
                                borderWidth: 1,
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderColor: theme.palette.divider,
                            })} />
                            <Box sx={(theme) => ({
                                borderRadius: theme.shape.borderRadius / 2,
                                borderTopLeftRadius: 0,
                                borderBottomLeftRadius: 0,
                                width: theme.shape.borderRadius * 2,
                                backgroundColor: theme.palette.action.hover,
                                borderStyle: 'solid',
                                borderWidth: 1,
                                borderLeftWidth: 0,
                                borderColor: theme.palette.divider,
                            })} />
                        </Box>
                        <Box sx={{ p: 1, pb: 0, position: 'relative' }}>
                            {cycle.pattern.map((cut, index) => (
                                <Chip key={index} label={<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <Collapse
                                        orientation="horizontal"
                                        in={detailed && (getCutDetails(jobOrders, cut.orderIndex, cut.entryIndex) !== '')}
                                        mountOnEnter
                                        unmountOnExit
                                        sx={{
                                            alignSelf: 'stretch',
                                            '& .MuiCollapse-wrapperInner': {
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center'
                                            }
                                        }}
                                    >
                                        <Typography variant="body2" sx={{ mr: 0.5, opacity: 0.75, fontWeight: 'bold' }}>
                                            {getCutDetails(jobOrders, cut.orderIndex, cut.entryIndex)}
                                        </Typography>
                                        <Divider orientation="vertical" flexItem sx={{ mr: 0.5 }} variant="middle" />
                                    </Collapse>
                                    <Typography variant="body1" sx={{ mr: 0.5 }}>
                                        {measSys.encoder(cut.length)} {measSys.notation}
                                    </Typography>
                                    <Typography variant="body2" sx={(theme) => ({ color: theme.palette.background.paper, backgroundColor: theme.palette.primary.main, borderRadius: 100, pl: 0.5, pr: 0.5 })}>
                                        ⨉ {cut.repeat}
                                    </Typography>
                                </Box>} size="small" variant="outlined" color="primary" sx={(theme) => ({ mr: 1, mb: 1, backgroundColor: theme.palette.background.paper, '& .MuiChip-label': { pr: '1px' } })} />
                            ))}
                            <Chip icon={cycle.offcut === 0 ? <DoneIcon /> : (cycle.offcut < jobProperties.minReusableLength ? <DeleteForeverIcon /> : <AutorenewIcon />)} label={<Typography variant="body1">{measSys.encoder(cycle.offcut)} {measSys.notation}</Typography>} size="small" variant="outlined" color={(cycle.offcut < jobProperties.minReusableLength && cycle.offcut !== 0) ? "error" : "success"} sx={(theme) => ({ mb: 1, backgroundColor: theme.palette.background.paper })} />
                        </Box>
                    </Box>
                </Box>))}
            </Collapse>
        </Box>
    )
};

const ResultItem = connect(
    (state) => ({
        jobProperties: getJobProperties(state),
        jobOrders: getJobOrders(state)
    }),
    null
)(ResultItemBase);


export { ResultItem };

