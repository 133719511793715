const IMPERIAL_FRACT_MAX_DENOMINATOR = 64;

function isApproxInteger(real) {
    let int = Math.floor(real);
    let sub = real - int;
    return ((sub >= 0.99) || (sub < 0.009));
}

function fractImperial(real, pow2 = true) {
    if (isApproxInteger(real)) {
        return real;
    }
    let int = Math.floor(real);
    let sub = real - int;
    for (let denominator = 2; denominator <= IMPERIAL_FRACT_MAX_DENOMINATOR; denominator = (pow2 ? denominator * 2 : denominator + 1)) {
        let numerator = sub * denominator;
        if (isApproxInteger(numerator)) {
            numerator = Math.round(numerator);
            return `${int} ${numerator}/${denominator}`;
        }
    }
    return real;
}

function stringParsesAsNumber(str) {
    return (!isNaN(parseFloat(str)) && isFinite(str));
}

function unassigned(value) {
    return ((value === null) || (value === undefined));
}

const imperialDecoder = (value) => {
    if (stringParsesAsNumber(value)) {
        return Number(value);
    } else {
        if (unassigned(value)) {
            return null;
        }
        if (value.indexOf('.') === -1) {
            value.trim();
            if (value.indexOf(' ') === -1) {
                value = '0 ' + value;
            }
            let splits = value.split(' ');
            if (splits.length === 2) {
                if (stringParsesAsNumber(splits[0])) {
                    if (splits[1].indexOf('/') !== -1) {
                        let fSplits = splits[1].split('/');
                        if (stringParsesAsNumber(fSplits[0]) && stringParsesAsNumber(fSplits[1])) {
                            let numerator = Number(fSplits[0]);
                            let denominator = Number(fSplits[1]);
                            if (Math.log2(denominator) % 1 === 0) {
                                if (numerator > 0) {
                                    if (numerator < denominator) {
                                        if (denominator <= IMPERIAL_FRACT_MAX_DENOMINATOR) {
                                            return Number(splits[0]) + (numerator / denominator);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    return null;
}

const imperialEncoder = (value) => {
    return String(fractImperial(value));
}

const basicSysDecoder = (value) => {
    if (stringParsesAsNumber(value)) {
        return Number(value);
    } else {
        return null;
    }
}

const basicSysEncoder = (value) => {
    // Format as decimal without scientific notation
    let str = String(value);
    if (str.indexOf('e') !== -1) {
        str = value.toFixed(20);
        const dp = str.indexOf('.');
        if (dp !== -1) {
            while (str[str.length - 1] === '0') {
                str = str.slice(0, -1);
            }
        }
        if (str[str.length - 1] === '.') {
            str = str.slice(0, -1);
        }
    }
    return str;
}

function sysGetNormalizationFactor(sys) {
    return Math.pow(10, sys.fractDigits);
}

const measurementSystems = {
    abstract: {
        notation: '',
        fractDigits: 0,
        decoder: basicSysDecoder,
        encoder: basicSysEncoder,
        convertFrom: null,
        convertTo: null
    },
    imperial: {
        notation: '"',
        fractDigits: 4,
        decoder: imperialDecoder,
        encoder: imperialEncoder,
        convertFrom: (value) => {
            return value * 25.4;
        },
        convertTo: (value) => {
            return value / 25.4;
        }
    },
    metricMillis: {
        notation: 'mm',
        fractDigits: 2,
        decoder: basicSysDecoder,
        encoder: basicSysEncoder,
        convertFrom: null,
        convertTo: null
    },
    metricNorm: {
        notation: 'm',
        fractDigits: 2,
        decoder: basicSysDecoder,
        encoder: basicSysEncoder,
        convertFrom: (value) => {
            return value * 1000;
        },
        convertTo: (value) => {
            return value / 1000;
        }
    }
};

const precisionMeasurementSystems = {
    ...measurementSystems,
    imperial: {
        ...measurementSystems.imperial,
        fractDigits: 4,
    },
    metricMillis: {
        ...measurementSystems.metricMillis,
        fractDigits: 4
    },
    metricNorm: {
        ...measurementSystems.metricNorm,
        fractDigits: 7
    }
};

const preferredMeasSystemMenu = [
    {
        label: 'prefMetricMillis',
        value: 'metricMillis'
    },
    {
        label: 'prefMetric',
        value: 'metricNorm'
    },
    {
        label: 'prefImperial',
        value: 'imperial'
    }
];

const jobMeasurementSystemsMenu = [
    {
        label: 'jobMetricMillis',
        value: 'metricMillis'
    },
    {
        label: 'jobMetric',
        value: 'metricNorm'
    },
    {
        label: 'jobImperial',
        value: 'imperial'
    },
    {
        label: 'jobAbstract',
        value: 'abstract'
    }
];

// Following higher-order decoder/encoder apply scaling and truncation, and disallow negative numbers

function sysDecode(sys, value) {
    if (typeof(value) !== 'string') {
        return null;
    }
    if (value.indexOf('-') !== -1) {
        return null;
    }
    let decoded = sys.decoder(value);
    if (decoded !== null) {
        // Convert parsed input directly to string to avoid FPU errors
        let str = decoded.toFixed(sys.fractDigits);
        // Concatenate with normalization factor zeroes
        str += String(sysGetNormalizationFactor(sys)).slice(1);
        // Find decimal point
        let dpPos = str.indexOf('.');
        if (dpPos !== -1) {
            // Remove point at original location
            str = str.replace('.', '');
            // Advance decimal point by fractDigits, simulating multiplication
            str = str.slice(0, dpPos + sys.fractDigits) + '.' + str.slice(dpPos + sys.fractDigits);
        }
        // This is lossy
        // return Math.floor(decoded * sysGetNormalizationFactor(sys));

        // Convert big number string back to Number, at no loss of precision
        // Using floor to truncate
        return Math.floor(Number(str));
    } else {
        return null;
    }
}

function sysEncode(sys, value) {
    if (value !== null) {
        return sys.encoder(value / sysGetNormalizationFactor(sys));
    } else {
        return null;
    }
}

function getMeasSystem(system, collection = measurementSystems) {
    let sys = collection[system];
    if (sys === undefined) {
        sys = collection.abstract;
    }
    const decoder = (value) => sysDecode(sys, value);
    const encoder = (value) => sysEncode(sys, value);
    const nonNullDecoder = (value) => {
        const parsed = decoder(value);
        if (parsed !== null) {
            if (parsed <= 0) {
                return null;
            }
        }
        return parsed;
    }
    return {
        decoder,
        encoder,
        nonNullDecoder,
        decode: decoder,
        encode: encoder,
        notation: sys.notation,
        def: sys
    };
}

function getPrecisionMeasSystem(system) {
    return getMeasSystem(system, precisionMeasurementSystems);
}

function convertMeasSystemValue(value, from, to, collection = measurementSystems, targetCollection = null) {
    const fromCollection = collection;
    const toCollection = targetCollection ?? collection;

    let fromSys = fromCollection[from];
    let toSys = toCollection[to];
    let fromAbstract = false, toAbstract = false;

    if (fromSys === undefined) {
        fromSys = fromCollection.abstract;
    }
    if (toSys === undefined) {
        toSys = toCollection.abstract;
    }
    if (fromSys === fromCollection.abstract) fromAbstract = true;
    if (toSys === toCollection.abstract) toAbstract = true;

    // Perform abstract conversions
    const abstractFractDigitsScale = Math.pow(10, toSys.fractDigits - fromSys.fractDigits);

    if (fromAbstract && toAbstract) {
        return value;
    }
    if (fromAbstract) {
        return value * abstractFractDigitsScale;
    }
    if (toAbstract) {
        return Math.round(value * abstractFractDigitsScale);
    }

    // Perform intermediary (from -> mm -> to) conversions
    const convertFrom = fromSys.convertFrom ?? ((value) => value);
    const convertTo = toSys.convertTo ?? ((value) => value);

    const baseFractDigits = toCollection.metricMillis.fractDigits;
    const fractDigitsFrom = fromSys.fractDigits;
    const fractDigitsTo = toSys.fractDigits;
    const digitsScaleToBase = Math.pow(10, baseFractDigits - fractDigitsFrom);
    const digitsScaleToTarget = Math.pow(10, fractDigitsTo - baseFractDigits);
    return Math.round(convertTo(convertFrom(value * digitsScaleToBase * digitsScaleToTarget)));
}

function convertPrecisionMeasSystemValue(value, from, to) {
    return convertMeasSystemValue(value, from, to, precisionMeasurementSystems);
}

export {
    measurementSystems, precisionMeasurementSystems,
    preferredMeasSystemMenu, jobMeasurementSystemsMenu,
    getMeasSystem, getPrecisionMeasSystem,
    convertMeasSystemValue, convertPrecisionMeasSystemValue
};
