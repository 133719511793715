import { accessTokenRefreshed, sessionExpired, userLoggedIn, userLoggedOut } from "../auth";

const authStorage = store => next => action => {
    let authData;
    try {
        switch (action.type) {
            default: break;
            case userLoggedIn().type:
                const { accessToken, refreshToken } = action.payload;
                localStorage.setItem('oc-auth', JSON.stringify({ accessToken, refreshToken }));
            break;
            case accessTokenRefreshed().type:
                authData = JSON.parse(localStorage.getItem('oc-auth'));
                if (authData) {
                    authData.accessToken = action.payload.accessToken;
                    localStorage.setItem('oc-auth', JSON.stringify(authData));
                }
            break;
            case userLoggedOut().type: case sessionExpired().type:
                if (localStorage.getItem('oc-auth')) {
                    localStorage.removeItem('oc-auth');
                }
            break;
        }
    } catch (error) {
        console.log('authStorage middleware error:');
        console.log(error);
    }
    return next(action);
};

export default authStorage;
