import { combineReducers } from "redux";

import profileReducer from "./profile";
import stocksReducer from "./stocks";
import materialsReducer from "./materials";
import jobsReducer from "./jobs";
import jobReducer from "./job";

export default combineReducers({
    profile: profileReducer,
    stocks: stocksReducer,
    materials: materialsReducer,
    jobs: jobsReducer,
    job: jobReducer
});
