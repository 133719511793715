import { createSlice } from "@reduxjs/toolkit";

import crust from "./base/crust";


// Materials
/*
    {
        id: number,
        name: string,
        variant: string,
        weight: number,
        meta: object,
        stocks: number,
        waste: number
    }
*/

class Material {
    id = 0;
    name = '';
    variant = '';
    weight = 0;
    meta = {};
    stocks = 0;
    waste = 0;

    constructor(props) {
        crust.updateProps(this, props);
    }
}


const slice = createSlice({
    name: "materials",
    initialState: {
        list: [/*
            {
                id: '0',
                name: 'V-Slot Alu',
                variant: '30mm',
                weight: 10,
                meta: {}
            },
            {
                id: '1',
                name: 'V-Slot Alu',
                variant: '50mm',
                weight: 25,
                meta: {}
            },
            {
                id: '2',
                name: 'V-Slot Alu',
                variant: '75mm',
                weight: 40,
                meta: {}
            },
            {
                id: '3',
                name: 'V-Slot Alu',
                variant: '100mm',
                weight: 50,
                meta: {}
            },
            {
                id: '4',
                name: 'Steel rod',
                variant: '15mm',
                weight: 100,
                meta: {}
            },
            {
                id: '5',
                name: 'Steel rod',
                variant: '30mm',
                weight: 200,
                meta: {}
            },
            {
                id: '6',
                name: 'Copper wire',
                variant: '20AWG',
                weight: 0.25,
                meta: {}
            }
        */]
    },
    reducers: crust.getSliceReducers({
        list: {
            materialsReceived: crust.entitiesReceived,
            materialsAdded: crust.entitiesAdded,
            materialAdjusted: crust.entityAdjusted,
            materialDeleted: crust.entityDeleted
        }
    })
});


export default slice.reducer;


export { Material };


export const {
    materialsReceived,
    materialsAdded,
    materialAdjusted,
    materialDeleted
} = slice.actions;


export const getMaterials = state => state.features.materials.list;
export const getMaterialsAsGroups = state => (explicitFilterPredicate = null) => {
    const materials = state.features.materials.list;
    let groupedMaterials = [];
    materials.forEach((material) => {
        let pushInto;
        const found = groupedMaterials.find(x => x.name === material.name);
        if (found) {
            pushInto = found.variants;
        } else {
            pushInto = [];
            groupedMaterials.push({
                name: material.name,
                variants: pushInto
            });
        }
        pushInto.push(material);
    });
    if (explicitFilterPredicate) {
        const sortedMaterials = [];
        for (let check = 0; check < 2; check++) {
            groupedMaterials.forEach((group) => {
                const result = group.variants.some(explicitFilterPredicate);
                let push = false;
                // Filtered ones go on top
                if (check === 0) {
                    push = result;
                }
                // Unfiltered groups go in their original order, after the filtered ones
                else {
                    push = !result;
                }
                if (push) {
                    sortedMaterials.push(group);
                }
            });
        }
        groupedMaterials = sortedMaterials;
    }
    return groupedMaterials;
};
