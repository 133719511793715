import { store } from "../App";
import { getAccessToken } from "../store/auth";

let handle = null;
let closing = false;
let notifyCallback = null;
let disconnectionCallback = null;


function onErrorOccurred() {
    if (disconnectionCallback) {
        disconnectionCallback();
    }
}

function onMessage(event) {
    if (notifyCallback) {
        try {
            let msg = JSON.parse(event.data);
            notifyCallback(msg);
        } catch (error) {
            onErrorOccurred();
        }
    }
}

function onClose() {
    // If unexpected
    if (!closing) {
        onErrorOccurred();
    } else {
        // Clear flag
        closing = false;
    }
}

function setNotifyCallback(callback) {
    notifyCallback = callback;
}

function setDisconnectionCallback(callback) {
    disconnectionCallback = callback;
}

function stopListening() {
    if (handle) {
        try {
            closing = true;
            handle.close();
            console.log('liveJobs WS closed.');
            handle = null;
        } catch (error) {}
    }
}

function onError() {
    onErrorOccurred();
    stopListening();
}

function onUnmount() {
    stopListening();
    notifyCallback = null;
    disconnectionCallback = null;
}

function startListening() {
    stopListening();
    try {
        handle = new WebSocket('wss://test.opticut.expert/wjobs?token=' + getAccessToken(store.getState()));
        console.log('liveJobs WS connected.');
        handle.onmessage = onMessage;
        handle.onerror = onError;
        handle.onclose = onClose;
        return true;
    } catch (error) {
        console.log('Could not open liveJobs WS: ' + error);
        return false;
    }
}

const isConnected = () => handle !== null;


const liveJobs = {
    setNotifyCallback,
    setDisconnectionCallback,
    startListening,
    stopListening,
    onUnmount,
    isConnected
}

export default liveJobs;
