import React, { useRef } from 'react';
import { Box, Fade, Grow, IconButton, Modal, Paper, Slide, Tabs, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material';
import { styled } from "@mui/system";

import { fxSx } from '../util/mui';

import CloseIcon from '@mui/icons-material/Close';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';


const Container = styled(Box)({
    width: '100%', flex: 1, position: 'relative'
});

const SectionHeader = styled((props) => <Paper elevation={0} square {...props} />, {
    shouldForwardProp: (prop) => prop !== 'secondary' && prop !== 'color'
})(
    ({ theme, secondary, color = null }) => ({
        flex: 1,
        display: 'flex',
        background: color === null ? (secondary ? theme.palette.secondary.main : theme.palette.primary.main) : theme.palette[color].main,
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: theme.zIndex.appBar - 1,
        color: 'white',
        pt: 0.25,
        pb: 0.25
    })
);

const OverflowPanelsContainer = styled(Box)({ flex: 1, whiteSpace: 'nowrap', overflow: 'hidden', position: 'relative' });

const OverflowPanelsSelector = styled(Box, { shouldForwardProp: (prop) => prop !== 'panel' })(
    ({ theme, panel }) => ({
        position: 'absolute',
        width: '100%',
        height: '100%',
        [theme.breakpoints.down('md')]: {
            left: (panel * -100) + '%',
            transition: 'left ' + theme.transitions.duration.enteringScreen + 'ms'
        }
    })
);

const OverflowPanel = styled(Box, { shouldForwardProp: (prop) => prop !== 'separated' })(
    ({ theme, separated }) => ({
        display: 'inline-block',
        verticalAlign: 'top',
        overflowY: 'auto',
        [theme.breakpoints.up('xs')]: {
            width: '100%'
        },
        [theme.breakpoints.up('md')]: {
            width: '50%'
        },
        height: '100%',
        ...(separated && { borderRightWidth: 1, borderRightColor: theme.palette.divider, borderRightStyle: 'solid' })
    })
);

const SubScreen = styled(Box, { shouldForwardProp: (prop) => prop !== 'visible' })(
    ({ visible }) => ({
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        ...(!visible && { visibility: 'hidden' })
    })
);

const BottomTabs = styled((props) => <Tabs variant="fullWidth" {...props} />)(
    { '& .MuiTabs-indicator': { top: 0, height: 3 } }
);

const DesktopWindowCtrlArea = styled(Box, { shouldForwardProp: (prop) => prop !== 'justify' })(
    ({ theme, justify = 'flex-start' }) => ({
        display: 'flex',
        justifyContent: justify,
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4)
    })
);

function ModalWindow({
    open, onClose, title, handheldToolbar, desktopToolbar, toolbar, justifyDesktopToolbar = 'flex-start', sx, children
}) {
    const theme = useTheme();
    const handheld = useMediaQuery(theme.breakpoints.down('md'));

    const transition = useRef(Fade);
    const opened = useRef(false);

    // Latch transition on rising edge of open prop
    if (!opened.current) {
        if (open) {
            transition.current = handheld ? Slide : Grow;
        }
    }
    opened.current = open;

    let Transition = transition.current;

    let header = handheld ? <Paper elevation={3} square sx={{ zIndex: theme.zIndex.appBar }}>
        <Toolbar sx={{ display: 'flex', pl: { xs: 0 }, overflow: 'hidden' }}>
            <IconButton onClick={onClose} sx={{ ml: 1, mr: 1 }}>
                <ChevronLeftIcon />
            </IconButton>
            <Typography variant="h6">
                {title}
            </Typography>
            <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
                {handheldToolbar ? handheldToolbar : toolbar}
            </Box>
        </Toolbar>
    </Paper> : <Paper elevation={3} square sx={{ zIndex: theme.zIndex.appBar }}>
        <Toolbar sx={{ backgroundColor: theme.palette.background.paper }}>
            <Typography variant="h6" sx={{ mr: 3 }}>
                {title}
            </Typography>
            <Box sx={{ flex: 1, display: 'flex', justifyContent: justifyDesktopToolbar }}>
                {desktopToolbar ? desktopToolbar : toolbar}
            </Box>
            <IconButton onClick={onClose}>
                <CloseIcon />
            </IconButton>
        </Toolbar>
    </Paper>;

    return (
        <Modal
            open={open}
            onClose={onClose}
        >
            <Transition in={open} direction="up">
                <Box sx={{
                    width: {
                        xs: '100%',
                        md: `calc(100vw - ${theme.spacing(8)})`
                    },
                    height: {
                        xs: '100%',
                        md: `calc(100vh - ${theme.spacing(8)})`
                    },
                    position: 'absolute',
                    top: {
                        xs: 0,
                        md: theme.spacing(4)
                    },
                    left: {
                        xs: 0,
                        md: theme.spacing(4)
                    },
                    outline: 0,
                    pb: {
                        xs: 'env(safe-area-inset-bottom)',
                        md: 0
                    },
                    [theme.breakpoints.down('md')]: {
                        backgroundColor: theme.palette.background.paper
                    }
                }}>
                    <Paper elevation={10} sx={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        overflow: 'hidden'
                    }} square={handheld}>
                        {header}
                        <Box sx={(theme) => ({ width: '100%', flex: 1, p: 2, ...fxSx(sx, theme) })}>
                            {children}
                        </Box>
                    </Paper>
                </Box>
            </Transition>
        </Modal>
    );
}


export {
    ModalWindow,
    Container,
    SectionHeader,
    OverflowPanelsContainer,
    OverflowPanelsSelector,
    OverflowPanel,
    SubScreen,
    BottomTabs,
    DesktopWindowCtrlArea
};
