import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Box, Button, Typography } from '@mui/material';

import { AppBarContent } from "./UIBase";

import {
    userLoggedOut,
    sessionExpired
} from "../store/auth";
import {
    profileReceived
} from "../store/features/profile";

import {
    isLoggedIn,
    getAccessToken,
    getRefreshToken
} from "../store/auth";
import api from "../api/api";
import { useGlobalUI } from "../App";
import strings from "../config/strings";


const AccountScreenBase = (props) => {
    const ui = useGlobalUI();

    useEffect(() => {
        async function getUserData() {
            try {
                const profileResponse = await api.call('/user');
                if (profileResponse.ok) {
                    props.profileReceived({ name: profileResponse.data.userData });
                } else {
                    throw new Error();
                }
            } catch (error) {
                ui.showSnackbar('Could not retrieve user profile. Please try reloading the page.', 'error');
            }
        }
        if (props.isLoggedIn) {
            getUserData();
        }
    }, [ui, props]);

    return (<Box sx={{ p: 2 }}>
        <AppBarContent title={strings.get('myAccount')} />
        <Typography variant="h5">
            {props.userName}
        </Typography>
        <br />
        <Button
            onClick={() => {
                props.sessionExpired();
            }}
        >
            Expire session (Debug)
        </Button>
        <br />
        <br />
        <Button
            onClick={() => {
                props.userLoggedOut();
            }}
        >
            {strings.get('logout')}
        </Button>
    </Box>);
};

const AccountScreen = connect(
    (state) => ({
        isLoggedIn: isLoggedIn(state),
        accessToken: getAccessToken(state),
        refreshToken: getRefreshToken(state),
        userName: state.features.profile.name
    }),
    (dispatch) => ({
        userLoggedOut: () => dispatch(userLoggedOut()),
        sessionExpired: () => dispatch(sessionExpired()),
        profileReceived: (data) => dispatch(profileReceived(data))
    })
)(AccountScreenBase);

export default AccountScreen;
