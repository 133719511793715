import React, { useCallback, useRef, useState } from "react";
import { connect } from "react-redux";
import { Box, Collapse, IconButton, TextField, Typography } from "@mui/material";

import XTField from "../../../components/XTField/XTField";
import { posIntegerDecoder } from "../../../components/XTField/encoder";
import { getMeasSystem } from "../../../util/meas";
import { RequireConfirmation } from "../../../util/util";

import {
    getJobOrderEntries,
    getJobProperties,
    jobOrderAdjusted,
    jobOrderDeleted,
    JobOrderEntry,
    jobOrderEntryAdded,
    jobOrderEntryAdjusted,
    jobOrderEntryDeleted
} from "../../../store/features/job";

import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import strings from "../../../config/strings";


function OrderEntryBase({ readonly, angles, labels, fields, jobProperties, entryAdjusted, entryDeleted }) {
    const measSys = getMeasSystem(jobProperties.system);

    return (
        <tr>
            <td><XTField readonly={readonly} decoder={measSys.nonNullDecoder} encoder={measSys.encoder} suffix={measSys.notation} variant="outlined" fullWidth size="small" value={fields.length} onValueChange={(value) => entryAdjusted({ length: value })} /></td>
            <td><XTField readonly={readonly} decoder={posIntegerDecoder} variant="outlined" fullWidth size="small" value={fields.qty} onValueChange={(value) => entryAdjusted({ qty: value })} /></td>
            {angles && <>
                <td><TextField variant="outlined" fullWidth size="small" /></td>
                <td><TextField variant="outlined" fullWidth size="small" /></td>
            </>}
            {labels && <td><XTField readonly={readonly} variant="outlined" fullWidth size="small" value={fields.label} onValueChange={(value) => entryAdjusted({ label: value })} /></td>}
            <td>
                {!readonly && <IconButton sx={{ color: 'inherit', ml: 1 }} onClick={entryDeleted}>
                    <RemoveCircleIcon color="error" />
                </IconButton>}
            </td>
        </tr>
    );
}

const OrderEntry = connect(
    (state) => ({
        jobProperties: getJobProperties(state)
    }),
    (dispatch, { orderIndex, index }) => ({
        entryAdjusted: (fields) => dispatch(jobOrderEntryAdjusted({ orderIndex, entryIndex: index, fields })),
        entryDeleted: () => RequireConfirmation(strings.get('qClearAllFields'), () => dispatch(jobOrderEntryDeleted({ orderIndex, entryIndex: index })))
    })
)(OrderEntryBase);

function OrderBase({
    index, readonly, data, angles, labels,
    entries,
    orderDeleted,
    orderAdjusted,
    entryAdded
}) {
    const nameInputRef = useRef(null);

    const [contentsVisible, setContentsVisible] = useState(true);
    const [editName, setEditName] = useState(false);

    const handleContentVisibilityToggle = useCallback(() => {
        setContentsVisible(!contentsVisible);
    }, [contentsVisible]);

    const handleEditNameToggle = useCallback(() => {
        if (editName) {
            if (nameInputRef.current) {
                if (nameInputRef.current.value !== '') {
                    let name = nameInputRef.current.value;
                    if (name.length <= 50) {
                        orderAdjusted({ name });
                    }
                }
            }
        }
        setEditName(!editName);
    }, [editName, orderAdjusted]);


    let fields = 2 + Number(angles) * 2 + Number(labels);

    const { name } = data;

    return (<>
        <Box sx={{ width: '100%', height: 70, pl: 2, pr: 1, display: 'flex', alignItems: 'center' }}>
            {editName ?
                <XTField
                    autoFocus
                    label={strings.get('orderName')}
                    variant="standard"
                    initialValue={name}
                    decoder={(value) => value.length <= 50 ? value : null}
                    inputRef={nameInputRef}
                    onBlur={handleEditNameToggle}
                />
                :
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                    {name === null ? 'Untitled Order' : name}
                </Typography>
            }
            <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                {!readonly && <>
                    <IconButton sx={{ mr: 1, color: 'inherit' }} onClick={handleEditNameToggle} disabled={editName}>
                        {editName ? <EditOffIcon color="primary" /> : <EditIcon color="primary" />}
                    </IconButton>
                    <IconButton sx={{ mr: 1, color: 'inherit' }} onClick={orderDeleted}>
                        <DeleteSweepIcon color="error" />
                    </IconButton>
                    <IconButton sx={{ mr: 3, color: 'inherit' }} onClick={() => {
                        entryAdded({ ...new JobOrderEntry() });
                        setContentsVisible(true);
                    }}>
                        <AddCircleIcon color="success" />
                    </IconButton>
                </>}
                <IconButton sx={{ color: 'inherit' }} onClick={handleContentVisibilityToggle}>
                    {!contentsVisible ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
            </Box>
        </Box>
        <Collapse in={contentsVisible} mountOnEnter unmountOnExit>
            <Box sx={{ width: '100%', pl: 2, pr: 1 }}>
                <Typography variant="subtitle2" component="table" cellPadding={0} cellSpacing={0} sx={{
                    width: '100%',
                    '& tr': {
                        verticalAlign: 'top'
                    },
                    '& td': {
                        paddingBottom: 0.5
                    },
                    '& th': {
                        textAlign: 'left',
                        width: (100 / fields) + '%'
                    },
                    '& td:nth-of-type(n+1):nth-last-of-type(n+3)': {
                        paddingRight: 0.5
                    },
                    '& .MuiInputBase-input': {
                        pl: 1, pr: 1
                    }
                }}>
                    <tbody>
                        <tr>
                            <th>
                                {strings.get('length')}
                            </th>
                            <th>
                                {strings.get('qty')}
                            </th>
                            {angles && <>
                                <th>
                                    {strings.get('angleL')}
                                </th>
                                <th>
                                    {strings.get('angleR')}
                                </th>
                            </>}
                            {labels && <th>
                                {strings.get('label')}
                            </th>}
                            <th>
                                <IconButton sx={{ opacity: 0, ml: 1 }} disabled>
                                    <RemoveCircleIcon />
                                </IconButton>
                            </th>
                        </tr>
                        {entries.map((entry, entryIndex) => <OrderEntry key={entryIndex} readonly={readonly} orderIndex={index} index={entryIndex} fields={entry} angles={angles} labels={labels} />)}
                    </tbody>
                </Typography>
            </Box>
        </Collapse>
    </>);
}

const Order = connect(
    (state, { index }) => ({
        entries: getJobOrderEntries(state, index)
    }),
    (dispatch, { index }) => ({
        orderAdjusted: (fields) => dispatch(jobOrderAdjusted({ orderIndex: index, fields })),
        orderDeleted: () => RequireConfirmation(strings.get('qClearAllFields'), () => dispatch(jobOrderDeleted(index))),
        entryAdded: (entry) => dispatch(jobOrderEntryAdded({ orderIndex: index, entry }))
    })
)(OrderBase);


export { OrderEntryBase, OrderEntry, OrderBase, Order };

