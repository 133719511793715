import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import EditIcon from '@mui/icons-material/Edit';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LineStyleIcon from '@mui/icons-material/LineStyle';
import ScaleIcon from '@mui/icons-material/Scale';
import {
    Box, ButtonBase, Chip, CircularProgress, Collapse, Divider, Fade, InputAdornment, Stack, TextField, Typography, useMediaQuery
} from "@mui/material";
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/system';
import { useCallback, useState } from "react";
import Times from '../../../components/Times';
import strings from '../../../config/strings';
import { rgbaMul } from '../../../util/util';

function MaterialVariantInput({ material, editingVariant, onVariantChanged }) {
    return (
        <TextField
            sx={{ flex: 1 }}
            size="small"
            variant="outlined"
            label={strings.get('varOpt')}
            value={editingVariant}
            onChange={onVariantChanged && ((event) => onVariantChanged(event.target.value))}
            InputProps={{
                sx: {
                    fontWeight: 'bold',
                    opacity: 0.75
                }
            }}
        />
    )
};

function MaterialExtras({ handheld, editing, material, editingVariant, onVariantChanged, editingWeight, onWeightChanged, weightValid }) {
    return (
        <Box sx={{ flex: editing ? 1 : 3, height: '100%', display: 'flex', alignItems: 'center' }}>
            {(material.weight !== 0 || editing || !handheld) && <Box sx={{
                flex: 1,
                height: '100%',
                mr: editing ? 0 : 2,
                display: 'flex',
                alignItems: 'center'
            }}>
                {(handheld && editing) && <>
                    <MaterialVariantInput material={material} editingVariant={editingVariant} onVariantChanged={onVariantChanged} />
                    <Divider orientation="vertical" flexItem sx={{ mx: 1.5, my: 2 }} />
                </>}
                {(!editing && material.weight !== 0) && <Chip
                    sx={{ pointerEvents: 'none', width: '100%' }}
                    size="small"
                    variant="outlined"
                    color="info"
                    icon={<ScaleIcon />}
                    label={material.weight + ' kg / m'}
                />}
                {editing && <TextField
                    sx={{ flex: 1 }}
                    color="info"
                    size="small"
                    variant="outlined"
                    label={strings.get('densOpt')}
                    value={editingWeight}
                    onChange={onWeightChanged && ((event) => onWeightChanged(event.target.value))}
                    error={!weightValid}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">
                            <ScaleIcon color="info" />
                        </InputAdornment>,
                        endAdornment: <InputAdornment position="end">
                            kg / m
                        </InputAdornment>
                    }}
                />}
            </Box>}
            {!editing && <>
                <Box sx={{ flex: 1, mr: 2 }}>
                    <Chip
                        sx={{ pointerEvents: 'none', width: '100%' }}
                        size="small"
                        variant="outlined"
                        color="success"
                        icon={<LineStyleIcon />}
                        label={<><Times /> {material.stocks}</>}
                    />
                </Box>
                <Box sx={{ flex: 1 }}>
                    <Chip
                        sx={{ pointerEvents: 'none', width: '100%' }}
                        size="small"
                        variant="outlined"
                        color="error"
                        icon={<DeleteIcon />}
                        label={<><Times /> {material.waste}</>}
                    />
                </Box>
            </>}
        </Box>
    );
}

function MaterialCard({ index, material, onClick, onAdjust, onDelete }) {
    const theme = useTheme();
    const handheld = useMediaQuery(theme.breakpoints.down('md'));

    const [editing, setEditing] = useState(false);
    const [editName, setEditName] = useState('');
    const [editVariant, setEditVariant] = useState('');
    const [editWeight, setEditWeight] = useState(null);
    const [weightValid, setWeightValid] = useState(true);
    const [expanded, setExpanded] = useState(false);
    const [working, setWorking] = useState(false);
    const [act, setAct] = useState('');

    const bringIntoView = useCallback(() => {
        setTimeout(() => {
            document.getElementById(`material-${material.id}-card`).scrollIntoView({
                behavior: 'smooth',
                block: 'end'
            });
        }, 300);
    }, [material]);

    const onDeleteClicked = useCallback(async () => {
        if (window.confirm(strings.get('qDelMaterial'))) {
            if (onDelete) {
                setAct('delete');
                setWorking(true);
                await onDelete(material.id);
                setWorking(false);
            }
        }
    }, [material, onDelete]);

    const onWeightChanged = useCallback((value) => {
        let parsed = Number(value);
        let valid = true;
        if (isNaN(parsed)) {
            valid = false;
        } else {
            if (parsed < 0) {
                valid = false;
            }
        }
        setEditWeight(value);
        setWeightValid(valid);
    }, []);

    return (
        <Box id={`material-${material.id}-card`} sx={
            (theme) => ({
                backgroundColor: index % 2 === 0 ? null : rgbaMul(theme.palette.divider, 1, 1, 1, 0.25),
                pointerEvents: working ? 'none' : null,
                overflow: 'hidden',
                position: 'relative'
            })
        }>
            <Box sx={{ width: '100%', height: 70, display: 'flex', alignItems: 'center', pr: 2 }}>
                <ButtonBase
                    sx={{ height: '100%', display: 'flex', flex: 1, pl: 2 }}
                    onClick={editing ? null : onClick}
                    disableRipple={editing}
                    TouchRippleProps={{
                        style: {
                            overflow: 'visible'
                        }
                    }}
                >
                    <Typography component="div" variant="h6" sx={{
                        flex: 2, height: '100%', display: 'flex', alignItems: 'center', pr: handheld ? 0 : 2, overflow: 'hidden'
                    }}>
                        {!editing && material.name}
                        {editing && <TextField
                            sx={{ flex: 1, mr: handheld ? 0 : 2 }}
                            size="small"
                            variant="outlined"
                            label={strings.get('name')}
                            value={editName}
                            onChange={(event) => setEditName(event.target.value)}
                        />}
                        {(!editing && material.variant !== '') && <Divider orientation="vertical" flexItem sx={{ mx: 1.5, my: 2 }} />}
                        {(material.variant !== '' && !editing) && <Box
                            sx={{ fontWeight: 'bold', fontSize: '0.8em', opacity: 0.75 }}
                        >
                            {material.variant}
                        </Box>}
                        {(!handheld && editing) && <MaterialVariantInput
                            material={material}
                            editingVariant={editVariant}
                            onVariantChanged={setEditVariant}
                        />}
                    </Typography>
                    {!handheld && <MaterialExtras
                        handheld={handheld}
                        editing={editing}
                        material={material}
                        editingVariant={editVariant}
                        onVariantChanged={setEditVariant}
                        editingWeight={editWeight}
                        onWeightChanged={onWeightChanged}
                        weightValid={weightValid}
                    />}
                </ButtonBase>
                <Stack direction={handheld ? 'row' : 'row-reverse'} spacing={2} sx={{ ml: 2 }}>
                    {(handheld && editing) && <IconButton onClick={onDeleteClicked}>
                        <DeleteIcon color="error" />
                    </IconButton>}
                    <IconButton disabled={!weightValid} onClick={async () => {
                        if (editing) {
                            setEditing(false);
                            if (onAdjust) {
                                setAct('edit');
                                setWorking(true);
                                await onAdjust({
                                    id: material.id,
                                    name: editName,
                                    variant: editVariant,
                                    weight: editWeight.length === 0 ? 0 : (Number(editWeight) <= 0 ? 0 : Number(editWeight)),
                                    meta: JSON.stringify(material.meta)
                                });
                                setWorking(false);
                            }
                        } else {
                            setEditName(material.name);
                            setEditVariant(material.variant);
                            setEditWeight(material.weight === 0 ? '' : material.weight.toString());
                            setEditing(true);
                            if (handheld) {
                                bringIntoView();
                            }
                        }
                    }}>
                        {!editing && <EditIcon color="primary" />}
                        {editing && <>
                            {weightValid && <CheckIcon color="success" />}
                            {!weightValid && <DoNotDisturbIcon />}
                        </>}
                    </IconButton>
                    {editing && <IconButton
                        onClick={() => window.confirm(strings.get('qDiscardMods')) && setEditing(false)}
                    >
                        <ClearIcon color="primary" />
                    </IconButton>}
                    {(!handheld && editing) && <IconButton onClick={onDeleteClicked}>
                        <DeleteIcon color="error" />
                    </IconButton>}
                    {handheld && !editing && <IconButton onClick={() => {
                        setExpanded(!expanded);
                        !expanded && bringIntoView();
                    }}>
                        {expanded ? <ExpandLessIcon color="secondary" /> : <ExpandMoreIcon color="secondary" />}
                    </IconButton>}
                </Stack>
            </Box>
            <Collapse in={handheld && (expanded || editing)} mountOnEnter unmountOnExit>
                <Box>
                    <Divider sx={{ mx: 2.5 }} />
                    <ButtonBase
                        sx={{ width: '100%', height: 70, display: 'flex', px: 2 }}
                        onClick={editing ? null : onClick}
                        disableRipple={editing}
                        TouchRippleProps={{
                            style: {
                                overflow: 'visible'
                            }
                        }}
                    >
                        <MaterialExtras
                            handheld={handheld}
                            editing={editing}
                            material={material}
                            editingVariant={editVariant}
                            onVariantChanged={setEditVariant}
                            editingWeight={editWeight}
                            onWeightChanged={onWeightChanged}
                            weightValid={weightValid}
                        />
                    </ButtonBase>
                </Box>
            </Collapse>
            <Divider />
            <Fade in={working} mountOnEnter unmountOnExit>
                <Box sx={(theme) => ({
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'rgba(255, 255, 255, 0.75)' // TODO-LP: Use color math
                })}>
                    {act === 'delete' && <DeleteIcon color="error" sx={{ position: 'absolute' }} />}
                    {act === 'edit' && <EditIcon color="primary" sx={{ position: 'absolute' }} />}
                    <CircularProgress color={act === 'delete' ? 'error' : 'primary'} />
                </Box>
            </Fade>
        </Box>
    );
}

export default MaterialCard;
