import { createSlice } from "@reduxjs/toolkit";

import crust from "./base/crust";


// Stock units
/*
    {
        id: number,
        length: number,
        count: number
    }
*/

class StockUnit {
    id = 0;
    length = 0;
    count = 0;

    constructor(props) {
        crust.updateProps(this, props);
    }
}

/*
const getUsableSamples = (n) => {
    let id = 0, length = 100000, cnt = 5;
    let r = [];
    for (let i = 0; i < n; i++) {
        r.push({ id, length, count: cnt });
        id++;
        length += 1500000;
        cnt++;
    }
    return r;
};

const getWasteSamples = (n) => {
    let id = 1000, length = 1000, cnt = 10;
    let r = [];
    for (let i = 0; i < n; i++) {
        r.push({ id, length, count: cnt });
        id++;
        length += 150;
        cnt += 5;
    }
    return r;
};
*/

const slice = createSlice({
    name: "stocks",
    initialState: {
        usable: [], // getUsableSamples(20),
        waste: [] // getWasteSamples(35)
    },
    reducers: crust.getSliceReducers({
        usable: {
            usableStocksReceived: crust.entitiesReceived,
            usableStocksAdded: crust.entitiesAdded,
            usableStocksAdjusted: crust.entitiesAdjusted,
            usableStocksDeleted: crust.entitiesDeleted,
        },
        waste: {
            wasteStocksReceived: crust.entitiesReceived,
            wasteStocksAdded: crust.entitiesAdded,
            wasteStocksAdjusted: crust.entitiesAdjusted,
            wasteStocksDeleted: crust.entitiesDeleted
        }
    })
});


export default slice.reducer;


export { StockUnit };


export const {
    usableStocksReceived,
    usableStocksAdded,
    usableStocksAdjusted,
    usableStocksDeleted,

    wasteStocksReceived,
    wasteStocksAdded,
    wasteStocksAdjusted,
    wasteStocksDeleted
} = slice.actions;


export const getUsableStocks = state => state.features.stocks.usable;

export const getWasteStocks = state => state.features.stocks.waste;
