import { createSlice } from "@reduxjs/toolkit";

import crust from "./base/crust";

import { convertMeasSystemValue } from "../../util/meas";
import { JobMetadata } from "./jobs";
import strings from "../../config/strings";


class JobOrder {
    name = '';
    entries = [];

    constructor(props) {
        crust.updateProps(this, props);
    }
}

class JobOrderEntry {
    length = 0;
    qty = 1;
    label = '';
    langle = null;
    rangle = null;

    constructor(props) {
        crust.updateProps(this, props);
    }
}

class JobStock {
    length = 0;
    priority = 0;
    qty = null;
    sku = null;

    constructor(props) {
        crust.updateProps(this, props);
    }
}


function getInitialState(system) {
    return {
        orders: [],
        autoStock: [],
        availableStock: [],
        result: [],
        properties: {
            ...new JobMetadata({ system })
        }
    }
}

const slice = createSlice({
    name: "job",
    initialState: {
        ...getInitialState()
    },
    reducers: {
        jobModalReset: (state, { payload }) => {
            const initialState = getInitialState(payload);
            Object.keys(initialState).forEach((key) => {
                state[key] = initialState[key];
            });
        },
        jobDataReceived: (state, { payload }) => {
            state.properties = {
                ...state.properties,
                ...payload.properties
            };
            state.orders = payload.orders;
            state.autoStock = payload.autoStock;
            state.availableStock = payload.availableStock;
        },
        jobOrderAdded: (state, { payload }) => {
            state.orders.push(payload);
        },
        jobOrderAdjusted: (state, { payload }) => {
            let order = state.orders[payload.orderIndex];
            for (const key in payload.fields) {
                order[key] = payload.fields[key];
            }
        },
        jobOrderDeleted: (state, { payload }) => {
            state.orders.splice(payload, 1);
        },
        jobOrderEntryAdded: (state, { payload }) => {
            state.orders[payload.orderIndex].entries.push(payload.entry);
        },
        jobOrderEntryAdjusted: (state, { payload }) => {
            let entry = state.orders[payload.orderIndex].entries[payload.entryIndex];
            for (const key in payload.fields) {
                entry[key] = payload.fields[key];
            }
        },
        jobOrderEntryDeleted: (state, { payload }) => {
            state.orders[payload.orderIndex].entries.splice(payload.entryIndex, 1);
        },
        jobImported: (state, { payload }) => {
            try {
                const importedOrders = payload.order_array;
                const orders = [];
                importedOrders.forEach((order) => {
                    const currentOrder = { ...new JobOrder({ name: strings.get('orderN', [order.order_id]) }) };
                    order.length_array.forEach((item) => {
                        const { length, count, mark, shape_id } = item;
                        const currentItem = {
                            ...new JobOrderEntry({
                                length: convertMeasSystemValue(length * 100, 'metricMillis', state.properties.system),
                                qty: count,
                                label: shape_id + '-' + mark
                            })
                        };
                        currentOrder.entries.push(currentItem);
                    });
                    orders.push(currentOrder);
                });
                state.orders = orders;
            } catch (error) {}
        },
        jobAutoStockAdded: (state, { payload }) => {
            state.autoStock.push(payload);
        },
        jobAutoStockAdjusted: (state, { payload }) => {
            let entry = state.autoStock[payload.stockIndex];
            for (const key in payload.fields) {
                entry[key] = payload.fields[key];
            }
        },
        jobAutoStockDeleted: (state, { payload }) => {
            state.autoStock.splice(payload, 1);
        },
        jobAutoStockCleared: (state) => {
            state.autoStock = [];
        },
        jobAvailableStockReceived: (state, { payload }) => {
            state.availableStock = payload;
        },
        jobAvailableStockAdded: (state, { payload }) => {
            state.availableStock.push(payload);
        },
        jobAvailableStockAdjusted: (state, { payload }) => {
            let entry = state.availableStock[payload.stockIndex];
            for (const key in payload.fields) {
                entry[key] = payload.fields[key];
            }
        },
        jobAvailableStockDeleted: (state, { payload }) => {
            state.availableStock.splice(payload, 1);
        },
        jobAvailableStockCleared: (state) => {
            state.availableStock = []
        },
        jobResultReceived: (state, { payload }) => {
            state.result = payload;
        },
        jobPropertiesAdjusted: (state, { payload }) => {
            if (payload.system !== undefined) {
                if (payload.system !== state.properties.system) {
                    const from = state.properties.system;
                    const to = payload.system;
                    const conversionPredicate = (entry) => entry.length = convertMeasSystemValue(entry.length, from, to);
                    state.orders.forEach((order) => order.entries.forEach(conversionPredicate));
                    state.autoStock.forEach(conversionPredicate);
                    state.availableStock.forEach(conversionPredicate);
                }
            }
            // Measurement system conversions for properties get updated from the window directly
            // No need to update them in the process above
            for (const key in payload) {
                state.properties[key] = payload[key];
            }
            // Clear material when switching to abstract
            if (payload.system === 'abstract') {
                state.properties.material = null;
                state.availableStock.forEach((entry) => entry.sku = null);
            }
        },
    }
});


export default slice.reducer;

export { JobOrder, JobOrderEntry, JobStock };

export const {
    jobModalReset,
    jobDataReceived,
    jobOrderAdded,
    jobOrderAdjusted,
    jobOrderDeleted,
    jobOrderEntryAdded,
    jobOrderEntryAdjusted,
    jobOrderEntryDeleted,
    jobImported,
    jobAutoStockAdded,
    jobAutoStockAdjusted,
    jobAutoStockDeleted,
    jobAutoStockCleared,
    jobAvailableStockReceived,
    jobAvailableStockAdded,
    jobAvailableStockAdjusted,
    jobAvailableStockDeleted,
    jobAvailableStockCleared,
    jobResultReceived,
    jobPropertiesAdjusted
} = slice.actions;

export const getJobOrders = (state) => state.features.job.orders;
export const getJobOrderEntries = (state, order) => state.features.job.orders[order].entries;
export const getJobAutoStock = (state) => state.features.job.autoStock;
export const getJobAvailableStock = (state) => state.features.job.availableStock;
export const getJobResult = (state) => state.features.job.result;
export const getJobProperties = (state) => state.features.job.properties;
