const jobTagColors = [
    '#d50000', // Red A700
    '#ff6d00', // Orange A700
    '#f48fb1', // Pink 200
    '#7e57c2', // Deep Purple 400
    '#2196f3', // Blue 500
    '#26c6da', // Cyan 400
    '#26a69a', // Teal 400
    '#9ccc65', // Light Green 400
    '#ffeb3b', // Yellow 500
    '#a1887f', // Brown 300
    '#ddd',    // White (Pictured gray)
    '#000',    // Black
];

export { jobTagColors };
