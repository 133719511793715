import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import StraightenIcon from '@mui/icons-material/Straighten';
import {
    Box, CircularProgress, Collapse, Divider, Fab, Paper, Stack, TextField
} from "@mui/material";
import IconButton from '@mui/material/IconButton';
import { useCallback, useEffect, useState } from "react";
import XTField from '../../../components/XTField/XTField';
import strings from '../../../config/strings';
import { convertPrecisionMeasSystemValue, getPrecisionMeasSystem } from "../../../util/meas";


function AddWidget({ open = false, onClose, onSubmit, locked, measurementSystem }) {
    const [length, setLength] = useState(null);
    const [count, setCount] = useState(1);
    const [countError, setCountError] = useState(false);
    const [working, setWorking] = useState(false);

    const measSys = getPrecisionMeasSystem(measurementSystem);

    const onValueChange = useCallback((setError, setValue) => (event) => {
        let value = Number(event.target.value);
        if (isNaN(value)) {
            setError(true);
        } else {
            value = Math.round(value);
            if (value <= 0) {
                setError(true);
            } else {
                setError(false);
                setValue(value);
                // Apply rounded value
                event.target.value = String(value);
            }
        }
    }, []);

    const onSubmitButton = useCallback(async () => {
        if (onSubmit) {
            setWorking(true);
            const ok = await onSubmit({ length: convertPrecisionMeasSystemValue(length, measurementSystem, 'metricMillis'), count });
            setWorking(false);
            (ok && onClose) && onClose();
        }
    }, [onSubmit, onClose, length, count, measurementSystem]);

    useEffect(() => {
        if (!open) {
            setLength(null);
            setCountError(false);
            setCount(1);
        }
    }, [open]);

    return (
        <Collapse sx={{ overflow: 'visible', zIndex: 1 }} in={open} mountOnEnter unmountOnExit>
            <Paper square elevation={15} sx={{ zIndex: 0 }}>
                <Divider />
                <Box sx={{ p: 2, display: 'flex', alignItems: 'center' }}>
                    <StraightenIcon sx={{ mr: 2 }} />
                    <XTField
                        buffered={false}
                        decoder={measSys.nonNullDecoder}
                        encoder={measSys.encoder}
                        suffix={measSys.notation}
                        variant="outlined"
                        label={strings.get('length') + (length === null ? ` (${measSys.notation})` : '')}
                        size="small"
                        sx={{ mr: 1 }}
                        value={length}
                        onValueChange={setLength}
                        validationErrorProps={{
                            helperText: null
                        }}
                    />
                    <ClearIcon sx={{ mr: 1 }} />
                    <TextField
                        defaultValue={count}
                        disabled={working || locked}
                        type="number"
                        label={strings.get('qty')}
                        variant="outlined"
                        size="small"
                        sx={{ mr: 3 }}
                        error={countError}
                        onChange={onValueChange(setCountError, setCount)}
                    />
                    <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                        <IconButton
                            color="error"
                            sx={{ mr: 2, borderStyle: 'solid', borderWidth: 1 }}
                            disabled={working || locked}
                            onClick={onClose}
                        >
                            <ClearIcon />
                        </IconButton>
                        <IconButton
                            color="success"
                            sx={{ borderStyle: 'solid', borderWidth: 1 }}
                            onClick={onSubmitButton}
                            disabled={length === null || countError || working || locked}
                        >
                            {!working && <CheckIcon />}
                            {working && <CircularProgress size="1em" color="inherit" />}
                        </IconButton>
                    </Box>
                </Box>
            </Paper>
        </Collapse>
    );
}

function SelectWidget({ open = false, onSelectAll, onSelectNone, items = null, locked, onDelete }) {
    const [working, setWorking] = useState(false);

    const onDeleteButton = useCallback(async () => {
        setWorking(true);
        await onDelete();
        setWorking(false);
    }, [onDelete]);

    return (
        <Collapse sx={{ overflow: 'visible' }} in={open} mountOnEnter unmountOnExit>
            <Paper square elevation={15} sx={{ zIndex: 0 }}>
                <Divider />
                <Box sx={{ pl: 2, pr: 3, py: 2, display: 'flex', alignItems: 'center' }}>
                    <Stack direction="row" spacing={2} sx={{ mr: 2 }}>
                        <IconButton color="inherit" onClick={onSelectAll} disabled={locked || working}>
                            <LibraryAddCheckIcon />
                        </IconButton>
                        <IconButton color="inherit" onClick={onSelectNone} disabled={locked || working}>
                            <FilterNoneIcon />
                        </IconButton>
                    </Stack>
                    {strings.get('nSelected', [items])}
                    <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
                        <Fab
                            variant="extended"
                            size="small"
                            color="error"
                            disabled={locked || working}
                            onClick={onDeleteButton}
                        >
                            {!working && <DeleteIcon sx={{ mr: 1 }} />}
                            {working && <CircularProgress sx={{ mr: 1 }} size="1.5em" color="inherit" />}
                            {strings.get('remSel')}
                        </Fab>
                    </Box>
                </Box>
            </Paper>
        </Collapse>
    );
}

export { AddWidget, SelectWidget };

