import { Portal } from "@mui/base";
import AssignmentIcon from '@mui/icons-material/Assignment';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import FeedbackIcon from '@mui/icons-material/Feedback';
import MenuIcon from '@mui/icons-material/Menu';
import LineStyleIcon from '@mui/icons-material/LineStyle';
import PersonIcon from '@mui/icons-material/Person';
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import strings from "../config/strings";


const drawerWidth = 240;

const AppBarDrawerPointerBlocker = styled((props) => (
    <MuiAppBar position="fixed" {...props}>
        <Toolbar />
    </MuiAppBar>
), {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    [theme.breakpoints.down('md')]: {
        display: 'none'
    },
    [theme.breakpoints.up('md')]: {
        zIndex: theme.zIndex.drawer + 1,
        width: drawerWidth,
        left: 0,
        opacity: 0,
        ...(open && {
            display: 'none'
        })
    }
}));

const AppBar = styled((props) => <MuiAppBar position="fixed" {...props} />, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    [theme.breakpoints.up('md')]: {
        zIndex: theme.zIndex.drawer + 2,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            })
        })
    },
    /*[theme.breakpoints.up('md')]: {
        ...(open && {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            })
        })
    }*/
}));

const collapsibleDrawerOpenedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const collapsibleDrawerClosedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`
});

const CollapsibleDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...collapsibleDrawerOpenedMixin(theme),
            '& .MuiDrawer-paper': {
                ...collapsibleDrawerOpenedMixin(theme),
                overflow: 'hidden'
            }
        }),
        ...(!open && {
            ...collapsibleDrawerClosedMixin(theme),
            '& .MuiDrawer-paper': {
                ...collapsibleDrawerClosedMixin(theme),
                overflow: 'hidden'
            }
        }),
        '& .MuiDrawer-paper: hover': {
            width: drawerWidth
        },
        [theme.breakpoints.down('md')]: {
            display: 'none'
        },/*
        [theme.breakpoints.down('md')]: {
            ...collapsibleDrawerClosedMixin(theme),
            '& .MuiDrawer-paper': {
                ...collapsibleDrawerClosedMixin(theme),
                overflow: 'hidden'
            }
        }*/
    })
);

const DrawerContainer = styled('div')(() => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
}));

const DrawerContent = styled('div')(() => ({
    width: '100%',
    flex: 1,
    overflowX: 'hidden',
    overflowY: 'hidden',
    '&: hover': {
        overflowY: 'auto'
    }
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar
}));

const AppBarContext = React.createContext({
    titleRef: null,
    setTitleRef: () => {},
    toolbarRef: null,
    setToolbarRef: () => {}
});

function AppBarProvider({ children }) {
    const [titleRef, setTitleRef] = React.useState(null);
    const [toolbarRef, setToolbarRef] = React.useState(null);

    const context = {
        titleRef,
        setTitleRef,
        toolbarRef,
        setToolbarRef
    };

    console.log('render appbar provider');
    console.log(context);

    return (
        <AppBarContext.Provider value={context}>
            {children}
        </AppBarContext.Provider>
    );
}

function AppBarForwarder({ forwardTitleRef, forwardToolbarRef, children }) {
    console.log('render appbar fwd');
    const ConsumerChild = React.useCallback(({ setTitleRef, setToolbarRef }) => {
        console.log('render appbar consumer');
        const childrenWithProps = React.Children.map(children, (child) => {
            if (React.isValidElement(child)) {
                return React.cloneElement(child, {
                    ...(forwardTitleRef && { ref: (title) => { console.log('set title ref'); console.log(title); setTitleRef(title); } }),
                    ...(forwardToolbarRef && { ref: (toolbar) => { console.log('set toolbar ref'); console.log(toolbar); setToolbarRef(toolbar); } })
                });
            }
            return child;
        });
        return (<>{childrenWithProps}</>)
    }, [forwardTitleRef, forwardToolbarRef, children]);
    return (
        <AppBarContext.Consumer>
            {ConsumerChild}
        </AppBarContext.Consumer>
    );
}

const AppBarContent = ({ title, children }) => (
    <AppBarContext.Consumer>
        {(appbar) => {
            console.log('render appbar');
            console.log({ titleRef: appbar.titleRef, toolbarRef: appbar.toolbarRef, randomRef: appbar.randomRef });
            return (<>
                {appbar.titleRef && <Portal container={appbar.titleRef}>{title}</Portal>}
                {appbar.toolbarRef && <Portal container={appbar.toolbarRef}>{children}</Portal>}
            </>);
        }}
    </AppBarContext.Consumer>
);

function UIBase(props) {
    const [mobileDrawerOpen, setMobileDrawerOpen] = React.useState(false);
    const [drawerOpen, setDrawerOpen] = React.useState(true);

    const location = useLocation();
    const navigate = useNavigate();

    const handleMobileDrawerToggle = () => {
        setMobileDrawerOpen(!mobileDrawerOpen);
    };

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    }

    const drawer = (
        <DrawerContainer>
            <DrawerHeader>
                <Box sx={{
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <img src="logo.svg" style={{ height: 25 }} alt="Opticut" />
                </Box>
                <IconButton onClick={handleDrawerToggle} sx={{
                    display: {
                        xs: 'none',
                        md: 'inherit'
                    }
                }}>
                    <ChevronLeftIcon />
                </IconButton>
            </DrawerHeader>
            <Divider />
            <DrawerContent>
                <div onClick={() => setMobileDrawerOpen(false)}>
                    {/*<List>
                        {[{ text: 'Home', icon: <GridViewIcon />, path: '/' }].map((option, index) => (
                            <ListItem button component={Link} to={option.path} key={option.text}>
                                <ListItemIcon>
                                    {option.icon}
                                </ListItemIcon>
                                <ListItemText primary={option.text} />
                            </ListItem>
                        ))}
                    </List>
                    <Divider />*/}
                    <List>
                        {[{ text: strings.get('navJobs'), icon: <AssignmentIcon />, path: 'jobs' }, { text: strings.get('navInventory'), icon: <LineStyleIcon />, path: 'inventory' }, /*{ text: 'Reports', icon: <InsightsIcon />, path: '/reports' }, */{ text: strings.get('navAccount'), icon: <PersonIcon />, path: '/account' }].map((option, index) => (
                            <ListItem button component={Link} to={option.path} key={option.text}>
                                <ListItemIcon>
                                    {option.icon}
                                </ListItemIcon>
                                <ListItemText primary={option.text} />
                            </ListItem>
                        ))}
                    </List>
                    <Divider />
                    <List>
                        {[{ text: strings.get('navFeedback'), icon: <FeedbackIcon />, path: '/feedback' }].map((option, index) => (
                            <ListItem button component={Link} to={option.path} key={option.text}>
                                <ListItemIcon>
                                    {option.icon}
                                </ListItemIcon>
                                <ListItemText primary={option.text} />
                            </ListItem>
                        ))}
                    </List>
                </div>
            </DrawerContent>
        </DrawerContainer>
    );

    console.log('render base');

    return (
        <AppBarProvider>
            <Box sx={{ height: '100%', display: 'flex', overflow: 'hidden', pb: 'env(safe-area-inset-bottom)' }}>
                <AppBarDrawerPointerBlocker open={drawerOpen} />
                <AppBar open={drawerOpen}>
                    <Toolbar sx={{ display: 'flex' }}>
                        {
                            // Mobile Drawer Button
                        }
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleMobileDrawerToggle}
                            sx={{
                                mr: 2,
                                display: {
                                    xs: 'none',
                                    sm: 'inherit',
                                    md: 'none'
                                }
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                        {
                            // Desktop Drawer Button
                        }
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerToggle}
                            edge="start"
                            sx={{
                                mr: 2,
                                display: {
                                    xs: 'none',
                                    /*md*/md: drawerOpen ? 'none' : 'inherit'
                                }
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <AppBarForwarder forwardTitleRef>
                            <Typography variant="h6" noWrap component="div" />
                        </AppBarForwarder>
                        {
                        /*
                            <Box sx={{ ml: 2, lineHeight: 'normal', borderColor: 'white', border: '1px dashed', pl: 1, pr: 1, pt: 0, pb: 0, borderRadius: 2 }}>
                                <Typography sx={{ display: { sm: 'none' } }}>
                                    xs
                                </Typography>
                                <Typography sx={{ display: { xs: 'none', sm: 'inherit', md: 'none' } }}>
                                    sm
                                </Typography>
                                <Typography sx={{ display: { xs: 'none', md: 'inherit', lg: 'none' } }}>
                                    md
                                </Typography>
                                <Typography sx={{ display: { xs: 'none', lg: 'inherit', xl: 'none' } }}>
                                    lg
                                </Typography>
                                <Typography sx={{ display: { xs: 'none', xl: 'inherit' } }}>
                                    xl
                                </Typography>
                            </Box>
                        */
                        }
                        <AppBarForwarder forwardToolbarRef>
                            <Box sx={{
                                flex: 1,
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }} />
                        </AppBarForwarder>
                    </Toolbar>
                </AppBar>
                <Box
                    component="nav"
                    aria-label="mailbox folders"
                >
                    {
                        // Tablet Drawer
                    }
                    <Drawer
                        variant="temporary"
                        open={mobileDrawerOpen}
                        onClose={handleMobileDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                            display: { xs: 'none', sm: 'block', md: 'none' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                    >
                        {drawer}
                    </Drawer>
                    {
                        // Desktop Drawer
                    }
                    <CollapsibleDrawer
                        variant="permanent"
                        open={drawerOpen}
                    >
                        {drawer}
                    </CollapsibleDrawer>
                </Box>
                <Box
                    sx={{ flex: 1, height: '100%', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}
                >
                    <Toolbar />
                    <Box
                        component="main"
                        sx={{ width: '100%', flex: 1, overflow: 'hidden' }}
                    >
                        <Outlet />
                    </Box>
                    <Paper elevation={5} sx={{
                        display: {
                            sm: 'none'
                        },
                        width: '100%'
                    }} square>
                        <Divider />
                        <Box sx={{ width: '100%' }}>
                            <BottomNavigation
                                showLabels
                                value={location.pathname}
                                onChange={(event, newValue) => {
                                    navigate(newValue);
                                }}
                            >
                                {/*<BottomNavigationAction label="Home" icon={<GridViewIcon />} value="/" />*/}
                                <BottomNavigationAction label={strings.get('navJobs')} icon={<AssignmentIcon />} value="/jobs" />
                                <BottomNavigationAction label={strings.get('navInventory')} icon={<LineStyleIcon />} value="/inventory" />
                                {/*<BottomNavigationAction label="Reports" icon={<InsightsIcon />} value="/reports" />*/}
                                <BottomNavigationAction label={strings.get('navAccount')} icon={<PersonIcon />} value="/account" />
                            </BottomNavigation>
                        </Box>
                    </Paper>
                </Box>
            </Box>
        </AppBarProvider>
    );
}


export { AppBarContent };

export default UIBase;


/*
    INITIAL:
        - Base + Forwarder Consumers
        - Portals
        - Forwarder Consumers by Context update
        - Portals by Context update
        x Forwarder update re-renders & resets refs through null -> instance reference; results in Context equality, no more re-renders
    SUBSEQUENT:
        - Base + Forwarder Consumers
        - Portals
        x Page update re-renders & resets refs through null -> instance reference; results in Context equality, no more re-renders
*/
