import React from "react";
import { connect } from "react-redux";
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';

import {
    wasteStocksReceived,
    wasteStocksAdded,
    wasteStocksAdjusted,
    wasteStocksDeleted
} from "../store/features/stocks";

import {
    getWasteStocks
} from "../store/features/stocks";

let lastWasteDemoId = 10;

const tableCols = [
    { field: 'id', headerName: 'ID', flex: 1 },
    { field: 'count', headerName: 'Count', type: 'number', flex: 1 },
    { field: 'materialId', headerName: 'Material', flex: 4 },
    { field: 'length', headerName: 'Length', type: 'number', flex: 1 }
];

class WasteScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            logoutSnackbarVisible: false
        };
    }

    showLogoutSnackbar = () => {
        this.setState({
            logoutSnackbarVisible: true
        });
    }

    onLogoutSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        this.setState({
            logoutSnackbarVisible: false
        });
    }

    render() {
        return (
            <div style={{ width: '100%', height: 500 }}>
                <DataGrid
                    rows={this.props.wasteStocks}
                    columns={tableCols}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    checkboxSelection
                />
                <br />
                <Button
                    onClick={() => {
                        this.props.wasteStocksReceived([
                            { id: 0, materialId: 'x', length: 500, count: 100 },
                            { id: 1, materialId: 'y', length: 600, count: 50 },
                            { id: 2, materialId: 'z', length: 700, count: 10 }
                        ]);
                    }}
                >
                    Get Sample Waste
                </Button>
                <Button
                    onClick={() => {
                        this.props.wasteStocksAdded([{
                            id: lastWasteDemoId++,
                            materialId: 'Waste Samplium',
                            length: 50 + Math.round(Math.random() * 100),
                            count: Math.round(Math.random() * 500)
                        }]);
                    }}
                >
                    Add Sample Waste Stocks
                </Button>
                <Button
                    onClick={() => {
                        this.props.wasteStocksAdjusted([{
                            id: 2,
                            materialId: 'Usable Modifyium',
                            count: Math.round(Math.random() * 500)
                        }]);
                    }}
                >
                    Adjust Waste 2
                </Button>
                <Button
                    onClick={() => {
                        this.props.wasteStocksDeleted([1]);
                    }}
                >
                    Delete Waste 1
                </Button>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    wasteStocks: getWasteStocks(state)
});

const mapDispatchToProps = (dispatch) => ({
    wasteStocksReceived: (stocks) => dispatch(wasteStocksReceived(stocks)),
    wasteStocksAdded: (stocks) => dispatch(wasteStocksAdded(stocks)),
    wasteStocksAdjusted: (stocks) => dispatch(wasteStocksAdjusted(stocks)),
    wasteStocksDeleted: (stocks) => dispatch(wasteStocksDeleted(stocks))
});

export default connect(mapStateToProps, mapDispatchToProps)(WasteScreen);
