import React from "react";
import { connect } from "react-redux";
import Button from '@mui/material/Button';
import { Box, Card, CardActions, CardContent, CircularProgress, Fade, TextField, Typography } from "@mui/material";

import {
    didSessionExpire,
    userLoggedIn
} from "../store/auth";
import {
    profileReceived
} from "../store/features/profile";

import { useState } from "react";
import api from "../api/api";
import { useNavigate } from "react-router-dom";
import strings from "../config/strings";

const LoginScreenBase = (props) => {
    const [username, setUsername] = useState('');
    const [pwd, setPwd] = useState('');
    const [loggingIn, setLoggingIn] = useState(false);
    const [loginFailed, setLoginFailed] = useState(false);

    const navigate = useNavigate();

    return (
        <Box
            sx={{ flexGrow: 1, p: 3, height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
            <Fade in timeout={500}>
                <form action="#">
                    <Card sx={{ width: 300, maxWidth: '80vw', mb: 7, position: 'relative' }}>
                        <CardContent sx={{ textAlign: 'center' }}>
                            <Box sx={{ mt: 1, mb: 2 }}>
                                <img src="logo.svg" style={{ height: 30 }} alt="Opticut" />
                            </Box>
                            <Typography sx={{ mb: 1 }} variant="h5" component="div">
                                {props.didSessionExpire ? strings.get('loginSessionExpiredPrompt') : strings.get('loginPrompt')}
                            </Typography>
                            <Typography sx={{ fontSize: 14, mb: 2 }} color="text.secondary" gutterBottom>
                                {loginFailed ? <Box sx={(theme) => ({ color: theme.palette.error.main })}>
                                    {strings.getMultiline('failedLoginTip')}
                                </Box> : (
                                    props.didSessionExpire ? <>
                                        {strings.get('loginSessionExpiredTip')}
                                    </> : <>
                                        {strings.get('loginTip')}
                                    </>
                                )}
                            </Typography>
                            <TextField
                                sx={{ mb: 2 }}
                                autoComplete="on"
                                error={loginFailed}
                                name="username"
                                label={strings.get('username')}
                                variant="outlined"
                                size="small"
                                value={username}
                                onChange={(event) => {
                                    setUsername(event.target.value);
                                    setLoginFailed(false);
                                }}
                            />
                            <TextField
                                type="password"
                                autoComplete="on"
                                error={loginFailed}
                                name="password"
                                label={strings.get('password')}
                                variant="outlined"
                                size="small"
                                value={pwd}
                                onChange={(event) => {
                                    setPwd(event.target.value);
                                    setLoginFailed(false);
                                }}
                            />
                        </CardContent>
                        <CardActions sx={{ justifyContent: 'center', pb: 2 }}>
                            <Button
                                type="submit"
                                size="small"
                                onClick={async (event) => {
                                    // We're only using <form> for the credential auto-complete feature in modern web browsers
                                    event.preventDefault();
                                    setLoggingIn(true);
                                    await new Promise(resolve => setTimeout(resolve, 500));
                                    setLoggingIn(false);
                                    setLoginFailed(false);
                                    try {
                                        const response = await api.call('/login', 'POST', {
                                            name: username,
                                            password: pwd
                                        });
                                        if (response.ok) {
                                            const { accessToken, refreshToken } = response.data;
                                            props.userLoggedIn({ accessToken, refreshToken });
                                            // Trigger password remember dialog by performing a navigation no-op
                                            navigate('#');
                                            return;
                                        } else {
                                            throw new Error();
                                        }
                                    } catch (error) {
                                        setLoginFailed(true);
                                    }
                                }}
                            >
                                {strings.get('loginButton')}
                            </Button>
                        </CardActions>
                        <Fade in={loggingIn} mountOnEnter unmountOnExit>
                            <Box sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                zIndex: 1,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backdropFilter: 'blur(10px)',
                                backgroundColor: 'rgba(255, 255, 255, 0.5)'
                            }}>
                                <CircularProgress size="3em" />
                            </Box>
                        </Fade>
                    </Card>
                </form>
            </Fade>
        </Box>
    );
};

const LoginScreen = connect(
    (state) => ({
        didSessionExpire: didSessionExpire(state),
        userName: state.features.profile.name
    }),
    (dispatch) => ({
        userLoggedIn: (tokens) => dispatch(userLoggedIn(tokens)),
        profileReceived: (data) => dispatch(profileReceived(data))
    })
)(LoginScreenBase);

export default LoginScreen;
