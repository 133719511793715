import React, { useEffect, useCallback, useState, useRef } from "react";
import { connect } from "react-redux";
import {
    Box,
    Button,
    Card,
    CardHeader,
    Avatar,
    IconButton,
    Paper,
    Typography,
    useMediaQuery,
    useTheme,
    Tab,
    FormControlLabel,
    Checkbox,
    Divider,
    Chip,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    LinearProgress,
    Skeleton,
    CardActionArea,
    CircularProgress,
    Fab,
    CardActions,
    Fade,
    Slide,
    ButtonBase
} from "@mui/material";

import { AppBarContent } from "../../components/UIBase";
import { Order } from "./components/order";
import { AutoStock, AutoStockEntry, Stock, StockEntry } from "./components/stock";
import { ResultItem } from "./components/result";
import { OptionsMenu } from "./components/options";
import {
    ModalWindow,
    BottomTabs,
    Container,
    DesktopWindowCtrlArea,
    OverflowPanel,
    OverflowPanelsContainer,
    OverflowPanelsSelector,
    SectionHeader,
    SubScreen
} from "../../components/ModalWindow";
import Times from "../../components/Times";
import {
    DesktopActions,
    PseudoToolbar
} from "../../components/Utilities";
import { makeError, RequireConfirmation } from "../../util/util";
import { convertMeasSystemValue, getMeasSystem, measurementSystems, precisionMeasurementSystems } from "../../util/meas";
import { jobTagColors } from "../../config/constants/colors";

import {
    Job,
    jobsReceived,
    jobAdded,
    jobAdjusted,
    jobDeleted,
    getJobs,
    jobsUpdated
} from "../../store/features/jobs";
import {
    getJobAutoStock,
    getJobAvailableStock,
    getJobOrders,
    getJobProperties,
    getJobResult,
    jobAutoStockAdded,
    jobAutoStockCleared,
    jobAutoStockDeleted,
    jobAvailableStockAdded,
    jobAvailableStockCleared,
    jobAvailableStockDeleted,
    jobAvailableStockReceived,
    jobDataReceived,
    jobImported,
    jobModalReset,
    JobOrder,
    jobOrderAdded,
    JobOrderEntry,
    jobOrderEntryAdded,
    jobPropertiesAdjusted,
    jobResultReceived,
    JobStock
} from "../../store/features/job";

import * as colors from '@mui/material/colors';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AssignmentIcon from '@mui/icons-material/Assignment';
import LineStyleIcon from '@mui/icons-material/LineStyle';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import SettingsIcon from '@mui/icons-material/Settings';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CircleIcon from '@mui/icons-material/Circle';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import DeleteIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import RemoveIcon from '@mui/icons-material/Remove';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import LayersIcon from '@mui/icons-material/Layers';
import LockIcon from '@mui/icons-material/Lock';
import ClearIcon from '@mui/icons-material/Clear';
import PaidIcon from '@mui/icons-material/Paid';
import InventoryIcon from '@mui/icons-material/Inventory';
import WarningIcon from '@mui/icons-material/Warning';
import InputIcon from '@mui/icons-material/Input';
import AutoModeIcon from '@mui/icons-material/AutoMode';

import { RESERVED_STOCK_PRIORITY, RESERVER_STOCK_MAX_AMOUNT } from "../../config/constants/stock";
import liveJobs from "../../util/liveJobs";
import { useGlobalUI } from "../../App";
import MaterialSelector from "../StocksScreen/components/MaterialSelector";
import { getMaterials, getMaterialsAsGroups, materialsReceived } from "../../store/features/materials";
import api from "../../api/api";
import { getUsableStocks, getWasteStocks, usableStocksReceived, wasteStocksReceived } from "../../store/features/stocks";
import { getProfile } from "../../store/features/profile";
import { isLoggedIn } from "../../store/auth";
import strings from "../../config/strings";
import { useLocation, useNavigate } from "react-router-dom";


const jobStatusColors = [colors.orange[500], colors.blue[500], colors.lightGreen[500], colors.purple[400]];
const jobStatusAvatars = [
    <HourglassTopIcon />,
    <CircularProgress size="50%" sx={{ color: 'white' }} />,
    <CheckCircleIcon />,
    <FactCheckIcon />
];


function promptMaterialStockReset(availableStockArray) {
    if (availableStockArray.length === 0) {
        return true;
    } else {
        return window.confirm(strings.get('qClearAllStock'));
    }
}


function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value * 100,
                ) / 100}%`}</Typography>
            </Box>
        </Box>
    );
}

function JobCard({ entry, index, onClick, fetchJobs }) {
    const ui = useGlobalUI();

    const [deleting, setDeleting] = useState(false);

    const deleteJob = useCallback(() => {
        RequireConfirmation(strings.get('qDeleteJob'), async () => {
            try {
                setDeleting(true);
                await new Promise(resolve => setTimeout(resolve, 500));
                const response = await api.call('/job?jobId=' + entry.id, 'DELETE');
                setDeleting(false);
                fetchJobs();
                if (response.ok) {
                    ui.showSnackbar(strings.get('succDel'));
                } else {
                    ui.showSnackbar(strings.get('alreadyDel'), 'error');
                }
            } catch (error) {
                ui.showSnackbar(makeError(strings.get('cannotDel'), strings.get('NCServer')), 'error');
                setDeleting(false);
            }
        });
    }, [entry, fetchJobs, ui]);

    return (
        <Card key={index} sx={{ width: '100%', mb: 2, zIndex: 5 }}>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Paper sx={{ width: 15, backgroundColor: jobTagColors[entry.meta.props.colorTag] }} square elevation={3}>
                </Paper>
                <Box sx={{ flex: 1 }}>
                    <CardActionArea
                        onClick={() => onClick(entry.id)}
                        TouchRippleProps={{
                            style: {
                                overflow: 'visible'
                            }
                        }}
                        sx={{
                            '& .MuiCardActionArea-focusHighlight': {
                                display: 'none'
                            }
                        }}
                    >
                        <CardHeader
                            sx={{ flex: 1 }}
                            avatar={
                                <Avatar sx={{ backgroundColor: jobStatusColors[entry.status] }}>
                                    {jobStatusAvatars[entry.status]}
                                </Avatar>
                            }
                            title={entry.meta.props.name}
                            subheader={new Date(entry.date).toLocaleString()}
                        />
                    </CardActionArea>
                    <CardActions sx={{ pt: 0 }}>
                        <ButtonBase
                            sx={{ display: 'flex', flex: 1, alignSelf: 'stretch' }}
                            onClick={() => onClick(entry.id)}
                            TouchRippleProps={{
                                style: {
                                    overflow: 'visible'
                                }
                            }}
                        />
                        <IconButton onClick={deleteJob}>
                            {deleting ? <CircularProgress size="1em" /> : <DeleteIcon color="error" />}
                        </IconButton>
                    </CardActions>
                </Box>
            </Box>
        </Card>
    );
}

function StockRepresentation({ color, number, text, size = 1 }) {
    return (
        <Paper sx={(theme) => ({
            backgroundColor: theme.palette[color].main,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            color: 'white',
            mb: 1.5,
            pr: 2,
            whiteSpace: 'nowrap',
            minWidth: String(size * 100) + '%',
            width: 'fit-content',
            overflow: 'hidden'
        })}>
            <Typography variant="body1" sx={{
                px: 1.5, py: 1, fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.15)'
            }}>
                {number} <Times l />
            </Typography>
            <Divider orientation="vertical" flexItem sx={{ mr: 1.5 }} />
            {text}
        </Paper>
    );
}


function JobsScreen({
    // State
    isLoggedIn,
    userProfile,
    jobOrders, jobAutoStock, jobAvailableStock, jobResult,
    jobProperties, materials, getGroupedMaterials, usableStocks, wasteStocks,
    // Dispatch
    jobModalReset,
    jobsReceived, jobsUpdated,
    jobDataReceived,
    jobOrderAdded,
    jobImported,
    jobAutoStockAdded, jobAutoStockDeleted, jobAutoStockCleared,
    jobAvailableStockReceived,
    jobAvailableStockAdded, jobAvailableStockDeleted, jobAvailableStockCleared, jobAvailableStockClearedForce,
    jobResultReceived,
    jobPropertiesAdjusted,
    materialsReceived,
    usableStocksReceived, wasteStocksReceived,
    ...props
}) {
    // Context hooks
    const theme = useTheme();
    const handheld = useMediaQuery(theme.breakpoints.down('md'));
    const ui = useGlobalUI();
    const navigate = useNavigate();
    const location = useLocation();


    // State
    const [open, setOpen] = useState(false);
    const [waitingState, setWaitingState] = useState(false); // 0 = Not waiting, 1 = Waiting for submit, 2 = Result processing
    const [readonly, setReadonly] = useState(false);
    const [viewingJobId, setViewingJobId] = useState('');
    const [resultNowReady, setResultNowReady] = useState(false);
    const [fetchingJob, setFetchingJob] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);
    const [menuAnchor, setMenuAnchor] = useState(null);
    const [optionsMenuOpen, setOptionsMenuOpen] = useState(false);
    const [angles, setAngles] = useState(false);
    const [labels, setLabels] = useState(true);
    const [detailed, setDetailed] = useState(false);
    const [materialMenuAnchor, setMaterialMenuAnchor] = useState(null);
    const [fetchingMaterials, setFetchingMaterials] = useState(false);
    const [fetchingStock, setFetchingStock] = useState(false);
    const [viewingJobStockError, setViewingJobStockError] = useState(false);
    const [stockVersion, setStockVersion] = useState(-1);
    const [solutionAccepted, setSolutionAccepted] = useState(false);
    const [solutionSnapshot, setSolutionSnapshot] = useState(null);
    const [importing, setImporting] = useState(false);


    // Refs
    const jobsWaiting = useRef(false);
    const waitingJobs = useRef([]);
    // Used for granular control over calling fetchJobs() on the isLoggedIn effect
    const shouldFetchJobs = useRef(true);


    // Derived state

    // True for any window opened after submission (waiting for state 1 only happens for newly submitted jobs)
    // Not necessarily means result has been processed or even loaded
    const viewingSubmitted = readonly && waitingState !== 1;
    // True when viewing a result
    // If the result became ready in the meantime, we assume we're viewing the resuly already, and set a special callback
    // on the result tab button that actually reloads the window with the complete data
    const viewingResult = resultNowReady ? true : (readonly && waitingState === 0);
    const subScreenIndex = tabIndex < 2 ? 0 : 1;

    const materialMenuOpen = materialMenuAnchor !== null;
    const material = jobProperties.material ? (materials.find(x => x.id === jobProperties.material) ?? null) : null;
    const lostMaterial = jobProperties.material && material === null;


    // Event handler callbacks
    const fetchMaterials = useCallback(async (callback) => {
        let materials;
        setFetchingMaterials(true);
        const result = await api.materials.getMaterials();
        if (result.success) {
            materials = result.data;
            materialsReceived(result.data);
            if (callback) {
                callback();
            }
        } else {
            materials = [];
            materialsReceived([]);
            ui.showSnackbar(strings.get('cantFetchMats'), 'error');
        }
        setFetchingMaterials(false);
        return materials;
    }, [ui, materialsReceived]);

    const fetchMaterialStocks = useCallback(async (materialId) => {
        setFetchingStock(true);
        const result = await api.materials.getStocks(materialId);
        if (result.success) {
            usableStocksReceived(result.data.stocks);
        } else {
            usableStocksReceived([]);
            jobPropertiesAdjusted({ material: null });
            ui.showSnackbar(strings.get('cantLoadMat'), 'error');
        }
        setFetchingStock(false);
        if (result.success) {
            return result.data.stocks;
        }
    }, [ui, usableStocksReceived, jobPropertiesAdjusted]);

    const handleOpen = useCallback(async (event, payload) => {
        jobModalReset(userProfile.preferredMeasurementSystem);
        if (payload) {
            console.log('Opening modal with payload');
            console.log(payload);
            jobPropertiesAdjusted({ name: strings.get('reuseTitle') });
        }
        setTabIndex(0);
        setWaitingState(0);
        setReadonly(false);
        setSolutionSnapshot(null);
        setSolutionAccepted(false);
        setOpen(true);
        try {
            if (payload) {
                const usables = [];
                if (payload.materialId) {
                    await fetchMaterials();
                    console.log('Attempting to add stocks from ' + payload.materialId);
                    console.log(payload.materialId);
                    jobPropertiesAdjusted({ material: payload.materialId });
                    const assignableStocks = await fetchMaterialStocks(payload.materialId);
                    console.log(assignableStocks);
                    let notAll = false;
                    payload.use.forEach((item) => {
                        const matchLength = convertMeasSystemValue(
                            item.length, 'metricMillis', 'metricMillis',
                            measurementSystems, precisionMeasurementSystems
                        );
                        const stock = assignableStocks.find(x => x.length === matchLength);
                        if (stock) {
                            usables.push({ ...new JobStock({ length: item.length, qty: stock.count, sku: stock.id })});
                        } else {
                            notAll = true;
                        }
                    });
                    if (notAll) {
                        ui.showSnackbar(strings.get('notAllCouldBeReused'));
                    }
                } else {
                    fetchMaterials();
                    console.log(payload.use);
                    payload.use.forEach((item) => {
                        console.log(item);
                        usables.push({ ...new JobStock({ length: item.length, qty: item.count }) });
                    });
                }
                jobAvailableStockReceived(usables);
            }
        } catch (error) {
            console.log('Could not open optimization window with requested payload: ' + error);
            ui.showSnackbar(strings.get('cannotReuseOptiData'), 'error');
            setOpen(false);
        }
    }, [userProfile, jobModalReset, fetchMaterials, fetchMaterialStocks, jobPropertiesAdjusted, jobAvailableStockReceived, ui]);

    const handleClose = useCallback((force = false) => {
        let allow = (force === true);
        if (!allow) {
            // If not currently submitting job data or solution
            if ((waitingState !== 1) && (waitingState !== 3)) {
                if (viewingSubmitted) {
                    allow = true;
                } else {
                    allow = window.confirm(strings.get('qCloseWindow'));
                }
            }
        }
        if (allow) {
            setOpen(false);
            setViewingJobId('');
            setViewingJobStockError(false);
        }
    }, [waitingState, viewingSubmitted]);

    const handleOptionsMenuOpen = useCallback((event) => {
        setMenuAnchor(event.currentTarget);
        setOptionsMenuOpen(true);
    }, []);

    const handleOptionsMenuClose = useCallback(() => {
        setOptionsMenuOpen(false);
    }, []);

    const onMaterialMenuOpen = useCallback((event) => {
        setMaterialMenuAnchor(event.currentTarget);
    }, []);

    const onMaterialMenuClose = useCallback(() => {
        setMaterialMenuAnchor(null);
    }, []);

    const onMaterialClearButton = useCallback(() => {
        if (window.confirm(strings.get('qRemMaterial'))) {
            jobPropertiesAdjusted({ material: null });
        }
    }, [jobPropertiesAdjusted]);

    const onMaterialMenuSelect = useCallback((material, force = false) => {
        const prevMaterial = jobProperties.material;
        if (material !== prevMaterial) {
            const confirm = force ? true : promptMaterialStockReset(jobAvailableStock);
            if (confirm) {
                jobPropertiesAdjusted({ material });
                jobAvailableStockClearedForce();
                fetchMaterialStocks(material);
            } else {
                return false;
            }
        }
    }, [jobProperties, jobPropertiesAdjusted, jobAvailableStock, jobAvailableStockClearedForce, fetchMaterialStocks]);

    const fetchJobs = useCallback(async (incremental = false) => {
        try {
            console.log('Fetching jobs' + (incremental === true ? ' (incremental)...' : '...'));
            let ids = '?ids=';
            if (incremental === true) {
                for (let i = 0; i < waitingJobs.current.length; i++) {
                    ids += waitingJobs.current[i];
                    if (i < waitingJobs.current.length - 1) {
                        ids += '+';
                    }
                }
            }
            let endpoint = '/jobs';
            if (incremental === true) {
                endpoint += ids;
            }
            const jobsResponse = await api.call(endpoint);
            if (!jobsResponse.ok) {
                // Janky, but the request failure logic is in the exception handler
                throw new Error();
            }
            const jobsResponseBody = jobsResponse.data;
            const receivedJobs = [];
            jobsResponseBody.jobs.forEach((job) => {
                const fields = { ...job };
                fields.meta = JSON.parse(job.meta);
                receivedJobs.push({ ...new Job(fields) });
            });
            let foundActiveOrPending = false;
            for (let i = 0; i < receivedJobs.length; i++) {
                const job = receivedJobs[i];
                // Patch pending job status to 'in progress' if it's the first one in the list,
                // since that means it's about to get picked up in a matter of milliseconds,
                // so we can just directly assume its status
                if (job.status === 0) {
                    if (!foundActiveOrPending) {
                        job.status = 1;
                    }
                }
                if (job.status !== 2) {
                    foundActiveOrPending = true;
                }
            }
            receivedJobs.reverse();
            if (incremental === true) {
                jobsUpdated({ items: receivedJobs, alignment: -1 });
            } else {
                jobsReceived(receivedJobs);
            }
            jobsWaiting.current = false;
            const prevWaitingJobs = [...waitingJobs.current];
            waitingJobs.current = [];
            receivedJobs.forEach((job) => {
                if (job.status < 2) {
                    jobsWaiting.current = true;
                    waitingJobs.current.push(job.id);
                }
            });
            let notifyResult = false;
            // We could just count the waiting jobs, but that would collide with deletions
            // We want to check only for jobs that:
            //    a. Are still in the list (were present previously as waiting)
            //    b. Are now finished (status 2)
            prevWaitingJobs.forEach((jobId) => {
                // If not deleted in the meantime
                let jobIndex = receivedJobs.findIndex(x => x.id === jobId);
                if (jobIndex !== -1) {
                    // If finished now, and this is the job we're currently viewing in the popup window
                    if (receivedJobs[jobIndex].status === 2) {
                        notifyResult = true;
                        if (viewingJobId === jobId) {
                            // If so, then update the flag that shows the result button and clear waiting state so the button
                            // is clickable
                            setResultNowReady(true);
                            setWaitingState(0);
                        }
                    }
                }
            });
            if (notifyResult) {
                ui.showSnackbar(strings.get('resultNowReady'), 'success');
            }
        } catch (error) {
            ui.showSnackbar(makeError(strings.get('cantFetchJobs'), strings.get('NCServer')), 'error');
        }
        if (jobsWaiting.current) {
            if (!liveJobs.isConnected()) {
                liveJobs.startListening();
                setTimeout(() => {
                    // Do a fetch preemptively, to account for any lost messages between processing this result,
                    // and connecting to WS
                    // The fetch will close the WS connection by itself if the optimization job is already processed
                    fetchJobs(true);
                }, 1000);
            }
        } else {
            if (liveJobs.isConnected()) {
                liveJobs.stopListening();
            }
        }
    }, [viewingJobId, jobsReceived, jobsUpdated, ui]);

    const handleCard = useCallback(async (jobId, status) => {
        const processing = status < 2;

        jobModalReset(userProfile.preferredMeasurementSystem);
        setViewingJobId(jobId);
        setWaitingState(processing ? 2 : 0);
        setReadonly(true);
        setTabIndex(processing ? 0 : 2);
        setViewingJobStockError(false);
        setOpen(true);
        setResultNowReady(false);
        setFetchingJob(true);
        setSolutionSnapshot(null);
        setSolutionAccepted(status > 2);

        const updatedMaterials = await fetchMaterials();

        // Send payload
        // TODO: Remove this example for old API
        /*
        let responseContent;

        try {
            console.log('Awaiting job result response...');
            const response = await fetch('https://test.opticut.expert/job?jobId=' + jobId, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Token': account.token
                }
            });
            const responseBody = await response.json();
            console.log('response:'); console.log(responseBody);

            responseContent = responseBody.jobData;
        } catch (error) {
            ui.showSnackbar(makeError(strings.get('cantOpenJob'), strings.get('NCServer')), 'error');
            handleClose(true);
        }
        */

        try {
            let responseContent;

            const response = await api.call('/job?jobId=' + jobId);
            if (response.ok) {
                responseContent = response.data.jobData;
            } else {
                ui.showSnackbar(makeError(strings.get('cantOpenJob'), strings.get('NCServer')), 'error');
                handleClose(true);
                setFetchingJob(false);
                return;
            }

            // Recover metadata
            const meta = JSON.parse(responseContent.meta);

            // Fetch stock data if job uses material
            const jobMaterial = meta.props.material;
            let jobMaterialStocks = null;
            if (jobMaterial) {
                const matExists = updatedMaterials.some(x => x.id === jobMaterial);
                if (matExists) {
                    const result = await api.materials.getStocks(jobMaterial);
                    //if (false) { // Test failure
                    if (result.success) {
                        jobMaterialStocks = result.data.stocks;
                        usableStocksReceived(result.data.stocks);
                        wasteStocksReceived(result.data.waste);
                        setStockVersion(result.data.version);
                    } else {
                        setViewingJobStockError(true);
                        ui.showSnackbar(strings.get('cantLoadStock'), 'error');
                    }
                } else {
                    ui.showSnackbar(strings.get('cantLoadMatMaybeDeleted'), 'warning');
                }
            }

            // Store snapshot in state
            if (meta.snapshot) {
                setSolutionSnapshot(meta.snapshot);
            }

            // Parse cutting cycles
            let resultGroups = responseContent.result;
            let longResult = [];
            if (!processing) {
                resultGroups.forEach((group) => {
                    group.cycles.forEach((cycle) => {
                        let patternStr = cycle.pattern;
                        if (patternStr.endsWith(' ')) {
                            patternStr = patternStr.slice(0, patternStr.length - 1);
                        }
                        const patternList = [];
                        patternStr.split(' ').forEach((patternItem) => {
                            const splits = patternItem.split(':');
                            patternList.push({
                                length: Number(splits[0]),
                                repeat: Number(splits[1])
                            });
                        });
                        cycle.pattern = patternList;
                    });
                });
                console.log('parsed result:'); console.log(resultGroups);
                resultGroups.forEach((group) => {
                    const groupCycles = [];
                    group.cycles.forEach((cycle) => {
                        for (let i = 0; i < cycle.count; i++) {
                            groupCycles.push({ ...cycle, count: 1 });
                        }
                    });
                    longResult.push({ ...group, cycles: groupCycles });
                });
                console.log('long form result:'); console.log(longResult);
            }
            resultGroups = longResult;

            // Rebuild job data from server response
            const parsedOrders = [];
            const parsedAutoStock = [];
            const parsedAvailableStock = [];
            const getSerializedResponseList = (serialized) => {
                let list = [];
                if (serialized.length > 0) {
                    list = serialized.slice(1, serialized.length - 1).split('][');
                }
                return list;
            }
            getSerializedResponseList(responseContent.order).forEach((item) => {
                const splits = item.split(':');
                parsedOrders.push({
                    length: Number(splits[0]),
                    qty: Number(splits[1])
                });
            });
            getSerializedResponseList(responseContent.stocks).forEach((item) => {
                const splits = item.split(':');
                parsedAutoStock.push({
                    length: Number(splits[0]),
                    priority: Number(splits[1])
                });
            });
            getSerializedResponseList(responseContent.remainings).forEach((item) => {
                const splits = item.split(':');
                parsedAvailableStock.push({
                    length: Number(splits[0]),
                    qty: Number(splits[1]),
                    priority: Number(splits[2])
                });
            });
            console.log('received orders:'); console.log(parsedOrders);
            console.log('received stock to buy:'); console.log(parsedAutoStock);
            console.log('received available stock:'); console.log(parsedAvailableStock);
            const reconstrOrders = [];
            const reconstrAutoStock = [];
            const reconstrAvailableStock = [];
            let currentOrderIndex = 0;
            meta.orders.forEach((metaOrder) => {
                const order = { ...new JobOrder({ name: metaOrder.name }) };
                const entries = [];
                const totalEntries = metaOrder.labels.length;
                for (let i = 0; i < totalEntries; i++) {
                    const entry = {
                        ...new JobOrderEntry({
                            ...parsedOrders[currentOrderIndex], // length, qty
                            label: metaOrder.labels[i]
                        })
                    }
                    entries.push(entry);
                    currentOrderIndex++;
                }
                order.entries = entries;
                reconstrOrders.push(order);
            });
            parsedAutoStock.forEach((autoStock) => {
                // Omit reserved stock
                if (autoStock.priority < RESERVED_STOCK_PRIORITY) {
                    reconstrAutoStock.push({
                        ...new JobStock({ ...autoStock })
                    });
                }
            });
            parsedAvailableStock.forEach((availableStock, index) => {
                const push = {
                    ...new JobStock({ ...availableStock })
                };
                // Get available stock quantities from snapshot if present
                if (meta.snapshot) {
                    push.qty = meta.snapshot.stockQtys[index];
                } else {
                    if (jobMaterial !== null) {
                        const skuLength = convertMeasSystemValue(
                            availableStock.length, meta.props.system, 'metricMillis',
                            measurementSystems, precisionMeasurementSystems
                        );
                        // Patch available stock to reflect actual stock counts
                        if (jobMaterialStocks) {
                            const sku = jobMaterialStocks.find(x => x.length === skuLength);
                            if (sku) {
                                push.qty = sku.count;
                            } else {
                                push.qty = 0;
                            }
                        }
                    }
                }
                reconstrAvailableStock.push(push);
            });
            console.log('rebuilt orders:'); console.log(reconstrOrders);
            console.log('rebuilt stock to buy:'); console.log(reconstrAutoStock);
            console.log('rebuilt available stock:'); console.log(reconstrAvailableStock);

            // Create record of available stock for tracking required purchases
            const avStockCounts = [];
            if (!processing) {
                reconstrAvailableStock.forEach((stock) => {
                    const found = avStockCounts.find(x => x.length === stock.length);
                    if (found) {
                        found.qty += stock.qty;
                    } else {
                        avStockCounts.push({
                            length: stock.length,
                            qty: stock.qty
                        });
                    }
                });
                console.log('available stock counts:'); console.log(avStockCounts);
            }

            // Create record of order quantities for reference counting in results
            const orderCounts = [];
            if (!processing) {
                reconstrOrders.forEach((jobOrder) => {
                    const order = [];
                    jobOrder.entries.forEach((orderEntry) => {
                        order.push(orderEntry.qty);
                    });
                    orderCounts.push(order);
                });
                console.log('qty counts:'); console.log(JSON.stringify(orderCounts));
            }

            const CyclesMatch = (a, b) => {
                if (a.offcut !== b.offcut) return false;
                if (a.pattern.length !== b.pattern.length) return false;
                for (let i = 0; i < a.pattern.length; i++) {
                    const api = a.pattern[i];
                    const bpi = b.pattern[i];
                    if (api.length !== bpi.length) return false;
                    if (api.repeat !== bpi.repeat) return false;
                    if (api.orderIndex !== bpi.orderIndex) return false;
                    if (api.entryIndex !== bpi.entryIndex) return false;
                }
                return true;
            };

            // Build result for Redux state, duplicating pattern elements as needed for distinct order elements
            // Ensure result has no redundancy by checking adjacent cycles for symmetry and adjusting repeat factor as needed
            const result = [];
            if (!processing) {
                resultGroups.forEach((group) => {
                    let count = 0;
                    let cycles = [];
                    group.cycles.forEach((cycle) => {
                        count += cycle.count;
                        const pattern = [];
                        cycle.pattern.forEach((patternCut) => {
                            let totalCuts = patternCut.repeat;
                            console.log('Pattern: ' + patternCut.length + ' x ' + patternCut.repeat + ' cuts');
                            while (totalCuts > 0) {
                                let breakout = false;
                                for (const [jobOrderIndex, jobOrder] of reconstrOrders.entries()) {
                                    for (const [orderEntryIndex, orderEntry] of jobOrder.entries.entries()) {
                                        if (orderEntry.length === patternCut.length) {
                                            const orderEntryCuts = orderCounts[jobOrderIndex][orderEntryIndex];
                                            if (orderEntryCuts > 0) {
                                                console.log('Found in order / item ' + jobOrderIndex + ' / ' + orderEntryIndex);
                                                const assignableCuts = totalCuts < orderEntryCuts ? totalCuts : orderEntryCuts;
                                                console.log('Cuts in order entry: ' + orderEntryCuts);
                                                console.log('Assignable: ' + assignableCuts);
                                                orderCounts[jobOrderIndex][orderEntryIndex] -= assignableCuts;
                                                console.log('Remaining in order entry: ' + orderCounts[jobOrderIndex][orderEntryIndex]);
                                                totalCuts -= assignableCuts;
                                                console.log('Remaining cuts to reference for pattern element: ' + totalCuts);
                                                pattern.push({
                                                    orderIndex: jobOrderIndex,
                                                    entryIndex: orderEntryIndex,
                                                    length: patternCut.length,
                                                    repeat: assignableCuts
                                                });
                                                breakout = true;
                                                break;
                                            }
                                        }
                                    }
                                    if (breakout) {
                                        break;
                                    }
                                }
                            }
                        });
                        const cmpCycle = cycles.length === 0 ? null : cycles[cycles.length - 1];
                        const cycleCandidate = {
                            repeat: cycle.count,
                            offcut: cycle.loss,
                            pattern
                        };
                        if (cmpCycle === null) {
                            cycles.push(cycleCandidate);
                        } else {
                            if (CyclesMatch(cmpCycle, cycleCandidate)) {
                                cmpCycle.repeat++;
                            } else {
                                cycles.push(cycleCandidate);
                            }
                        }
                    });
                    let buy = 0;
                    const foundAvStockRecord = avStockCounts.find(x => x.length === group.length);
                    if (foundAvStockRecord) {
                        const deductible = Math.min(foundAvStockRecord.qty, count);
                        foundAvStockRecord.qty -= deductible;
                        buy = count - deductible;
                    } else {
                        buy = count;
                    }
                    const resultGroup = {
                        length: group.length,
                        count,
                        buy,
                        cycles
                    };
                    result.push(resultGroup);
                });
            }
            console.log('normalized result:'); console.log(result);
            console.log('qty counts after building result (should all be 0):'); console.log(JSON.stringify(orderCounts));

            // Update state with rebuilt data
            jobDataReceived({
                properties: meta.props,
                orders: reconstrOrders,
                autoStock: reconstrAutoStock,
                availableStock: reconstrAvailableStock
            });

            if (!processing) {
                // Update state with result
                jobResultReceived(result);
            }
        } catch (error) {
            ui.showSnackbar(strings.get('cantOpenUnexpectedError'), 'error');
            handleClose(true);
            console.log(error);
        }

        setFetchingJob(false);
    }, [
        userProfile,
        jobDataReceived,
        jobModalReset,
        jobResultReceived,
        ui,
        handleClose,
        fetchMaterials,
        usableStocksReceived,
        wasteStocksReceived
    ]);

    const getMaterialSummary = useCallback(() => {
        if (solutionSnapshot) {
            return solutionSnapshot.matSummary;
        }

        let materialSummary = null;

        if (material) {
            let maxLength = Number.NEGATIVE_INFINITY;
            const stBuy = [], stUse = [], stReusable = [], stWaste = [], stAll = [];
            // Build summary
            jobResult.forEach((sku) => {
                if (sku.length > maxLength) maxLength = sku.length;
                if (sku.buy > 0) {
                    let insert = {
                        count: sku.buy,
                        length: sku.length
                    };
                    stBuy.push(insert);
                    stAll.push(insert);
                }
                const fromStock = sku.count - sku.buy;
                if (fromStock > 0) {
                    let insert = {
                        count: fromStock,
                        length: sku.length
                    };
                    stUse.push(insert);
                    stAll.push(insert);
                }
                sku.cycles.forEach((cycle) => {
                    if (cycle.offcut > 0) {
                        const reusable = cycle.offcut >= jobProperties.minReusableLength;
                        let insert = {
                            count: cycle.repeat,
                            length: cycle.offcut
                        };
                        const findPredicate = x => x.length === insert.length;
                        const found = reusable ? stReusable.find(findPredicate) : stWaste.find(findPredicate);
                        if (found) {
                            found.count += insert.count;
                        } else {
                            reusable ? stReusable.push(insert) : stWaste.push(insert);
                            stAll.push(insert);
                        }
                    }
                });
            });
            // Normalize size for bar graph
            stAll.forEach((item) => {
                if (item.length === 0) {
                    item.size = 0;
                } else {
                    item.size = item.length / maxLength;
                }
            });
            materialSummary = {
                buy: stBuy,
                use: stUse,
                reusable: stReusable,
                waste: stWaste
            };
        }

        return materialSummary;
    }, [solutionSnapshot, material, jobResult, jobProperties]);

    const buildJobMetadata = useCallback((otherProps) => {
        let meta = {
            props: {
                ...jobProperties
            },
            orders: [],
            ...(otherProps && otherProps)
        };
        jobOrders.forEach((jobOrder) => {
            let labels = [];
            jobOrder.entries.forEach((entry) => {
                labels.push(entry.label)
            });
            meta.orders.push({
                name: jobOrder.name,
                labels
            });
        });

        return meta;
    }, [jobProperties, jobOrders]);

    const handleSolution = useCallback(async () => {
        if (waitingState !== 0) return;

        const summary = getMaterialSummary();
        const addUsable = [], adjUsable = [], remUsable = [], addWaste = [], adjWaste = [];
        const solution = {
            insertStocks: addUsable,
            adjustStocks: adjUsable,
            removeStocks: remUsable,
            insertWaste: addWaste,
            adjustWaste: adjWaste
        };

        // Perform deep copy of stocks
        const usable = [], waste = [];
        usableStocks.forEach((item) => usable.push({ ...item }));
        wasteStocks.forEach((item) => waste.push({ ...item }));

        function matchLength(length) {
            return convertMeasSystemValue(
                length, jobProperties.system, 'metricMillis',
                measurementSystems, precisionMeasurementSystems
            );
        }

        // Subtract used stock
        summary.use.forEach((item) => {
            const sku = usable.find(x => x.length === matchLength(item.length));
            sku.count -= item.count;
        });

        // Add reusable stock
        summary.reusable.forEach((item) => {
            const matchedLength = matchLength(item.length);
            const sku = usable.find(x => x.length === matchedLength);
            if (sku) {
                sku.count += item.count;
            } else {
                addUsable.push({
                    ...item,
                    length: matchedLength
                });
            }
        });

        // Add waste
        summary.waste.forEach((item) => {
            const matchedLength = matchLength(item.length);
            const sku = waste.find(x => x.length === matchedLength);
            if (sku) {
                sku.count += item.count;
            } else {
                addWaste.push({
                    ...item,
                    length: matchedLength
                });
            }
        });

        // Stock adjustments and null stock removals
        usable.forEach((sku, index) => {
            if (sku.count === 0) {
                remUsable.push(sku.id);
            } else {
                if (sku.count !== usableStocks[index].count) {
                    adjUsable.push(sku);
                }
            }
        });

        // Waste adjustments
        waste.forEach((sku, index) => {
            if (sku.count !== wasteStocks[index].count) {
                adjWaste.push(sku);
            }
        });

        // Trim
        const trimmedSolution = {};
        for (const key in solution) {
            if (solution[key].length > 0) {
                solution[key].forEach((item) => {
                    delete item.size;
                });
                trimmedSolution[key] = solution[key];
            }
        }

        // Create solution snapshot
        const avStockSnapshot = [];
        jobAvailableStock.forEach((item) => {
            avStockSnapshot.push(item.qty);
        });
        const snapshot = {
            stockQtys: avStockSnapshot,
            matSummary: summary
        };

        console.log('solution:'); console.log(trimmedSolution);
        console.log('snapshot:'); console.log(snapshot);

        const body = {
            jobRef: {
                id: viewingJobId,
                meta: JSON.stringify(buildJobMetadata({ snapshot })),
            },
            materialId: material.id,
            version: stockVersion,
            ...trimmedSolution
        };

        console.log('body:'); console.log(body);

        let generalError = false;
        setWaitingState(3);
        try {
            const response = await api.call('/all-stock', 'PATCH', JSON.stringify(body));

            if (response.ok) {
                ui.showSnackbar(strings.get('solutionAcceptedSuccessfully'), 'success');
                setSolutionAccepted(true);
                jobsUpdated({
                    items: [
                        { id: viewingJobId, status: 3 }
                    ]
                });
            } else {
                if (response.data.version !== undefined) {
                    ui.showSnackbar(strings.get('cantAcceptSolutionRequiresRefresh'), 'warning');
                    handleCard(viewingJobId, 2);
                } else {
                    generalError = true;
                }
            }
        } catch (error) {
            console.log(error);
            generalError = true;
        }
        setWaitingState(0);

        if (generalError) {
            ui.showSnackbar(strings.get('somethingWentWrongWithYourRequest'), 'error');
        }
    }, [
        waitingState,
        ui,
        getMaterialSummary,
        jobProperties,
        usableStocks,
        wasteStocks,
        jobAvailableStock,
        stockVersion,
        material,
        handleCard,
        viewingJobId,
        buildJobMetadata,
        jobsUpdated
    ]);

    const handleTabChange = useCallback((event, newValue) => {
        // Same thing as with the desktop FAB
        // If the result became ready in the meantime, the result tab
        // actually needs to reload the data before we have anything to
        // display; to the user, it looks like it just switches to the
        // correct tab and loads the data, even though we're actually
        // running through the whole loading process as when
        // opening the window
        if (newValue === 2) {
            if (resultNowReady) {
                handleCard(viewingJobId, 2);
                return;
            }
        }
        setTabIndex(newValue);
    }, [handleCard, resultNowReady, viewingJobId]);

    const handleDefaultFAB = useCallback(async () => {
        // Editing
        if (!viewingResult) {
            const reservedStocks = [];

            // Form validity check
            let errorSnack = null;

            const checkValidity = (object, fields) => {
                if (object.length === 0) {
                    return false;
                }
                let valid = true;
                fields.forEach((field) => {
                    if (object[field] === null) {
                        valid = false;
                    }
                });
                return valid;
            }
            let fieldsValid = true;
            let valuesWithIssue = '';
            let ov = true, asv = true, sv = true;
            jobOrders.forEach((jobOrder) => {
                jobOrder.entries.forEach((entry) => {
                    if (!checkValidity(entry, ['length', 'qty'])) {
                        fieldsValid = false;
                        if (ov) {
                            ov = false;
                            valuesWithIssue += strings.get('order');
                        }
                    }
                });
            });
            jobAutoStock.forEach((stock) => {
                if (!checkValidity(stock, ['length', 'priority'])) {
                    fieldsValid = false;
                    if (asv) {
                        asv = false;
                        if (valuesWithIssue !== '') {
                            valuesWithIssue += ', ';
                        }
                        valuesWithIssue += strings.get('stockToBuy');
                    }
                }
            });
            jobAvailableStock.forEach((stock) => {
                if (!checkValidity(stock, ['length', 'priority', 'qty'])) {
                    fieldsValid = false;
                    if (sv) {
                        sv = false;
                        if (valuesWithIssue !== '') {
                            valuesWithIssue += strings.get('_and_');
                        }
                        valuesWithIssue += strings.get('availableStock');
                    }
                }
            });
            if (!fieldsValid) {
                errorSnack = strings.get('invalidValuesForJob', [valuesWithIssue]);
            }

            // Completeness check
            if (!errorSnack) {
                let haveOrders = false;
                let haveStock = false;
                jobOrders.forEach((jobOrder) => {
                    if (jobOrder.entries.length > 0) {
                        haveOrders = true;
                    }
                });
                if (jobAutoStock.length + jobAvailableStock.length > 0) {
                    haveStock = true;
                }
                if ((!haveOrders) || (!haveStock)) {
                    errorSnack = strings.get('cannotOptimizeWithoutStock');
                }
            }

            // Stock reservation and impossible result check
            if (!errorSnack) {
                let maxCut = Number.NEGATIVE_INFINITY;
                jobOrders.forEach((jobOrder) => {
                    jobOrder.entries.forEach((entry) => {
                        if (entry.length > maxCut) {
                            maxCut = entry.length;
                        }
                    });
                });
                let shouldReserve = true;
                jobAutoStock.forEach((stock) => {
                    if (stock.length >= maxCut) {
                        shouldReserve = false;
                    }
                });
                if (shouldReserve) {
                    const topStocks = [...jobAvailableStock];
                    topStocks.sort((a, b) => b.length - a.length);
                    for (let i = 0; i < Math.min(topStocks.length, RESERVER_STOCK_MAX_AMOUNT); i++) {
                        if (topStocks[i].length >= maxCut) {
                            reservedStocks.push({ ...topStocks[i], priority: RESERVED_STOCK_PRIORITY, qty: null });
                        }
                    }
                    if (reservedStocks.length === 0) {
                        errorSnack = strings.get('optimizationImpossible');
                    }
                }
            }

            if (errorSnack) {
                ui.showSnackbar(errorSnack, 'error');
                return;
            }
            if (!material) {
                if (!window.confirm(strings.get('optimizeWithoutMaterialConfirm'))) return;
            }

            // Build metadata
            let meta = buildJobMetadata();

            // Request structure
            const requestBody = {
                meta: JSON.stringify(meta),
                stocks: [],
                remainings: [],
                order: []
            };

            // Collect data for request
            jobOrders.forEach((jobOrder) => {
                jobOrder.entries.forEach((entry) => {
                    requestBody.order.push(String(entry.length) + ':' + String(entry.qty));
                });
            });
            const allAutoStock = [...reservedStocks, ...jobAutoStock];
            allAutoStock.forEach((stock) => {
                requestBody.stocks.push(String(stock.length) + ':' + String(stock.priority));
            });
            jobAvailableStock.forEach((stock) => {
                requestBody.remainings.push(String(stock.length) + ':' + String(stock.qty) + ':' + String(stock.priority));
            });

            setWaitingState(1);
            setReadonly(true);
            setTimeout(async () => {
                console.log('Sending optimization job request...');
                try {
                    // Test delays
                    // requestBody.sleep = 5000;
                    console.log('body:'); console.log(requestBody);
                    const response = await api.call('/job', 'POST', requestBody);
                    if (!response.ok) {
                        throw new Error();
                    }
                    waitingJobs.current.push(response.data.jobId);
                    handleClose(true);
                    fetchJobs(true);
                } catch (error) {
                    ui.showSnackbar(makeError(strings.get('unableToStartJob'), strings.get('NCServer')), 'error');
                    setWaitingState(0);
                    setReadonly(false);
                }
            }, 500);
        }
        // Viewing
        else {
            // If result became ready in the meantime, the result viewing flag is set in order to display the button
            // However, we still need to actually load the complete data before we can display the result
            // This takes care of that
            if (resultNowReady) {
                handleCard(viewingJobId, 2);
            } else {
                setTabIndex(tabIndex === 2 ? 0 : 2);
            }
        }
    }, [
        material,
        handleClose,
        fetchJobs,
        jobAutoStock,
        jobAvailableStock,
        jobOrders,
        buildJobMetadata,
        viewingResult,
        tabIndex,
        resultNowReady,
        handleCard,
        viewingJobId,
        ui
    ]);

    const handleMobileFAB = useCallback(() => {
        if (tabIndex === 0) {
            setTabIndex(1);
        } else {
            handleDefaultFAB();
        }
    }, [handleDefaultFAB, tabIndex]);

    const onAnglesToggle = useCallback(() => {
        setAngles(!angles);
    }, [angles]);

    const onLabelsToggle = useCallback(() => {
        setLabels(!labels);
    }, [labels]);

    const onDetailedToggle = useCallback(() => {
        setDetailed(!detailed);
    }, [detailed]);

    const onWSMessage = useCallback((message) => {
        console.log('liveJobs WS:');
        console.log(message);
        if (message.topic === 'auth-conflict') {
            ui.showSnackbar(strings.get('liveRefreshDisabledBecauseYouRoam'));
            liveJobs.stopListening();
        }
        else if (message.topic === 'jobs-notify') {
            fetchJobs(true);
        }
    }, [fetchJobs, ui]);
    liveJobs.setNotifyCallback(onWSMessage);

    const onWSDCError = useCallback(() => {
        console.log('liveJobs WS disconnected unexpectedly.');
    }, []);
    liveJobs.setDisconnectionCallback(onWSDCError);


    // Side effects
    useEffect(() => {
        return () => {
            liveJobs.onUnmount();
        }
    }, []);

    useEffect(() => {
        if (isLoggedIn) {
            if (shouldFetchJobs.current) {
                fetchJobs();
                shouldFetchJobs.current = false;
            }
        } else {
            shouldFetchJobs.current = true;
        }
    }, [isLoggedIn, fetchJobs]);

    useEffect(() => {
        const urlPayload = new URLSearchParams(location.search).get('premade');
        if (urlPayload) {
            if (!open) {
                try {
                    handleOpen(null, JSON.parse(atob(urlPayload)));
                } catch (error) {
                    console.log('Could not parse URL payload: ' + error);
                    ui.showSnackbar(strings.get('cannotReuseOptiData'), 'error');
                }
            }
            navigate('/jobs');
        }
    }, [location, navigate, handleOpen, open, ui]);


    // Render
    const measSys = getMeasSystem(jobProperties.system);

    let percentageLoss = 0;
    let totalStockLength = 0;
    let totalLoss = 0;
    jobResult.forEach((stockUnit) => {
        totalStockLength += stockUnit.length * stockUnit.count;
        stockUnit.cycles.forEach((cycle) => {
            if (cycle.offcut < jobProperties.minReusableLength) {
                totalLoss += cycle.offcut * cycle.repeat;
            }
        });
    });
    if (totalStockLength > 0) {
        percentageLoss = (totalLoss / totalStockLength) * 100;
    }

    const StartButtonAdornment = waitingState > 0 ? CircularProgress : PlayArrowIcon;
    const startButtonAdornmentProps = waitingState > 0 ? {
        sx: {
            mr: 1
        },
        color: 'inherit',
        size: '1em'
    } : {
        sx: {
            mr: 1
        }
    };
    const startButtonText = waitingState === 0 ? strings.get('start') : strings.get('processing');

    const StartButtonContent = () => <><StartButtonAdornment {...startButtonAdornmentProps} />{' ' + startButtonText}</>;

    const materialSummary = getMaterialSummary();

    let spinOffHref = null;
    if (solutionAccepted) {
        try {
            const payload = {
                materialId: (material ? material.id : null),
                use: materialSummary.reusable.map(
                    x => ({ length: x.length, count: x.count })
                )
            };
            spinOffHref = '/jobs?premade=' + btoa(JSON.stringify(payload));
        } catch (error) {}
    }
    
    return (
        <Box sx={{ width: '100%', height: '100%', overflowY: 'auto' }}>
            <AppBarContent title={strings.get('jobsTitle')}>
                <Box sx={{
                    display: {
                        md: 'none'
                    }
                }}>
                    <IconButton color="inherit" onClick={fetchJobs} sx={{ mr: 1 }}>
                        <RefreshIcon />
                    </IconButton>
                    <IconButton color="inherit" onClick={handleOpen}>
                        <AddCircleIcon />
                    </IconButton>
                </Box>
            </AppBarContent>
            <DesktopActions>
                <PseudoToolbar />
                <Paper square elevation={3} sx={{
                    flex: 1,
                    pl: 2,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                }}>
                    <Button
                        variant="outlined"
                        startIcon={<AddCircleIcon />}
                        onClick={handleOpen}
                        sx={{ mr: 2 }}
                    >
                        {strings.get('newOptimization')}
                    </Button>
                    <Button
                        variant="outlined"
                        startIcon={<RefreshIcon />}
                        onClick={fetchJobs}
                    >
                        {strings.get('refresh')}
                    </Button>
                </Paper>
            </DesktopActions>
            {/*
                TODO: Fix login form text boxes when displayed over the modal window
            */}
            <ModalWindow
                open={open}
                onClose={handleClose}
                title={<Box sx={{ display: 'flex', alignItems: 'center' }}>{jobProperties.name === '' ? (fetchingJob ? strings.get('loading') : strings.get('newOptimization')) : jobProperties.name}<CircleIcon sx={{ fontSize: 'small', color: jobTagColors[jobProperties.colorTag], ml: 1 }} /></Box>}
                sx={{ p: 0, display: 'flex', flexDirection: 'column' }}
                handheldToolbar={subScreenIndex === 0 && <>
                    {!viewingSubmitted && <label htmlFor="import-from-json-button">
                        <IconButton component="span" color="inherit" sx={{ mr: 1 }}>
                            {importing ? <CircularProgress size="1em" color="inherit" disableShrink /> : <InputIcon />}
                        </IconButton>
                    </label>}
                    <IconButton color="inherit" onClick={handleOptionsMenuOpen}>
                        <SettingsIcon />
                    </IconButton>
                </>}
                desktopToolbar={subScreenIndex === 0 && <>
                    <Button variant="outlined" size="small" startIcon={<SettingsIcon />} onClick={handleOptionsMenuOpen}>
                        {viewingSubmitted ? strings.get('viewConf') : strings.get('configure')}
                    </Button>
                    <Box sx={{ mr: 2 }} />
                    {!viewingSubmitted && <>
                        <label htmlFor="import-from-json-button">
                            <Button
                                variant="outlined"
                                component="span"
                                size="small"
                                startIcon={importing ? <CircularProgress size="1em" disableShrink /> : <InputIcon />}
                            >
                                {strings.get('import')}
                            </Button>
                        </label>
                        <Box sx={{ mr: 3 }} />
                    </>}
                </>}
            >
                <input
                    hidden
                    type="file"
                    accept=".json"
                    id="import-from-json-button"
                    onChange={({ target }) => {
                        const fileReader = new FileReader();
                        fileReader.readAsText(target.files[0]);
                        fileReader.onload = async (event) => {
                            const data = event.target.result;
                            console.log('Finished reading imported data from file:'); console.log(data);
                            try {
                                const parsed = JSON.parse(data);
                                console.log(parsed);
                                setImporting(true);
                                const materials = await fetchMaterials();
                                setTimeout(() => {
                                    jobImported(parsed);
                                    try {
                                        const matchedMaterial = materials.find(
                                            x => (x.name + x.variant).toLowerCase() === parsed.steel_type.toLowerCase()
                                        );
                                        console.log('matched material: '); console.log(matchedMaterial);
                                        if (matchedMaterial) {
                                            onMaterialMenuSelect(matchedMaterial.id);
                                        }
                                    } catch (error) {
                                        console.log('Could not match material: ' + error);
                                        ui.showSnackbar(strings.get('importDidntWork'), 'error');
                                    }
                                });
                            } catch (error) {
                                console.log('Could not import data from JSON: ' + error);
                                ui.showSnackbar(strings.get('importDidntWork'), 'error');
                            }
                            setTimeout(() => {
                                setImporting(false);
                            });
                        }
                    }}
                    onClick={(event) => {
                        event.target.value = null;
                    }}
                />
                <OptionsMenu
                    readonly={readonly}
                    anchor={menuAnchor}
                    open={optionsMenuOpen}
                    onClose={handleOptionsMenuClose}
                    properties={jobProperties}
                    onPropertiesAdjusted={jobPropertiesAdjusted}
                />
                <MaterialSelector
                    unique
                    anchor={materialMenuAnchor}
                    open={materialMenuOpen}
                    onClose={onMaterialMenuClose}
                    materialGroups={getGroupedMaterials()}
                    selection={jobProperties.material}
                    onChange={onMaterialMenuSelect}
                />
                <Container>
                    <Slide direction="right" in={subScreenIndex === 0} appear={false}>
                        <SubScreen visible={true}>
                            {!handheld && <Typography variant="overline" component="div" sx={{ width: '100%', display: 'flex' }}>
                                <SectionHeader>{strings.get('demand')}</SectionHeader>
                                <SectionHeader secondary>{strings.get('stock')}</SectionHeader>
                            </Typography>}
                            <OverflowPanelsContainer>
                                <OverflowPanelsSelector panel={tabIndex}>
                                    <OverflowPanel separated={!handheld} sx={{ pb: handheld ? 12 : 5 }}>
                                        <Paper sx={{
                                            width: '100%',
                                            height: 60,
                                            p: 2,
                                            position: 'sticky',
                                            top: 0,
                                            zIndex: theme.zIndex.appBar - 2,
                                            display: 'flex',
                                            alignItems: 'center'
                                        }} elevation={2} square>
                                            <Box sx={{ flex: 1 }}>
                                                <Button
                                                    disabled={readonly}
                                                    variant="contained"
                                                    size="small"
                                                    onClick={() => jobOrderAdded({ ...new JobOrder() })}
                                                >
                                                    {strings.get('addOrder')}
                                                </Button>
                                            </Box>
                                            {false && <Box sx={{ flex: 1 }}>
                                                <FormControlLabel
                                                    control={<Checkbox disabled={readonly} checked={angles} onClick={onAnglesToggle} />}
                                                    label={strings.get('angles')}
                                                />
                                            </Box>}
                                            <Box sx={{ flex: 1 }}>
                                                <FormControlLabel
                                                    control={<Checkbox disabled={readonly} checked={labels} onClick={onLabelsToggle} />}
                                                    label={strings.get('labels')}
                                                />
                                            </Box>
                                        </Paper>
                                        {jobOrders.map((order, index) => <Order key={index} readonly={readonly} index={index} data={order} angles={angles} labels={labels} />)}
                                    </OverflowPanel>
                                    <OverflowPanel sx={{ pb: handheld ? 12 : 5 }}>
                                        <Paper sx={{
                                                width: '100%',
                                                height: 60,
                                                p: 2,
                                                position: 'sticky',
                                                top: 0,
                                                zIndex: theme.zIndex.appBar - 2,
                                                display: 'flex',
                                                alignItems: 'center'
                                            }} elevation={2} square>
                                                <Box sx={{ flex: 1 }}>
                                                    <Button
                                                        disabled={
                                                            // While submitting but not while viewing
                                                            (readonly && !viewingSubmitted) ||
                                                            // While viewing submitted job that has no material
                                                            (viewingSubmitted && material === null) ||
                                                            fetchingMaterials || jobProperties.system === 'abstract'
                                                        }
                                                        startIcon={
                                                            (fetchingStock || fetchingMaterials || fetchingJob) ? (
                                                                <CircularProgress size="1em" color="inherit" />
                                                            ) : (
                                                                viewingSubmitted ? (material !== null ? <LockIcon /> : <ClearIcon />) : <LayersIcon />
                                                            )
                                                        }
                                                        variant={(material && !fetchingStock && !viewingSubmitted) ? 'contained' : 'outlined'}
                                                        size="small"
                                                        color="secondary"
                                                        onClick={(event) => {
                                                            if (!viewingSubmitted) {
                                                                if (materials.length === 0) {
                                                                    const eventData = { currentTarget: event.currentTarget };
                                                                    fetchMaterials(() => onMaterialMenuOpen(eventData));
                                                                } else {
                                                                    if (!fetchingStock) {
                                                                        onMaterialMenuOpen(event);
                                                                    }
                                                                }
                                                            }
                                                        }}
                                                        sx={{
                                                            pointerEvents: viewingSubmitted ? 'none' : 'inherit'
                                                        }}
                                                    >
                                                        {jobProperties.system === 'abstract' ? strings.get('noMatsInAbstract') : (
                                                            material ? (
                                                                material.name + ' ' + material.variant
                                                            ) : (
                                                                (fetchingJob || fetchingMaterials) ? strings.get('loading') : (
                                                                    viewingSubmitted ? (
                                                                        lostMaterial ? strings.get('delMat') : strings.get('noMat')
                                                                    ) : strings.get('selMat')
                                                                )
                                                            )
                                                        )}
                                                    </Button>
                                                    {(material && !viewingResult && !fetchingStock && !readonly) && (
                                                        <IconButton sx={{ ml: 1.5 }} onClick={onMaterialClearButton}>
                                                            <DeleteIcon color="error" />
                                                        </IconButton>
                                                    )}
                                                </Box>
                                        </Paper>
                                        <Box sx={{
                                            px: 3,
                                            pt: 3
                                        }}>
                                            <AutoStock readonly={readonly} suggestions0={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]} onAddButton={() => jobAutoStockAdded({ ...new JobStock() })} onClearButton={jobAutoStockCleared}>
                                                {jobAutoStock.map((item, index) => <AutoStockEntry key={index} readonly={readonly} index={index} data={item} />)}
                                            </AutoStock>
                                            <Divider sx={{ mt: 4, mb: 3 }} />
                                            {
                                                // TODO: Quick select buttons for available stock when using materials
                                            }
                                            <Stock snapshot={solutionSnapshot} readonly={readonly} linkedMaterialName={lostMaterial ? strings.get('lostMaterial') : (material && (material.name + ' ' + material.variant))} lostMaterial={lostMaterial} fetching={fetchingStock} fetchError={viewingJobStockError} onAddButton={() => jobAvailableStockAdded({ ...new JobStock({ qty: 1 }) })} onClearButton={jobAvailableStockCleared}>
                                                {jobAvailableStock.map((item, index) => <StockEntry key={index} readonly={readonly} index={index} data={item} canned={jobProperties.material !== null && jobProperties.system !== 'abstract' && !viewingSubmitted} />)}
                                            </Stock>
                                        </Box>
                                    </OverflowPanel>
                                </OverflowPanelsSelector>
                            </OverflowPanelsContainer>
                        </SubScreen>
                    </Slide>
                    <Slide direction="left" in={subScreenIndex === 1} appear={false}>
                        <SubScreen visible={true} sx={{ pb: handheld ? /*12*/ 5 : 5, overflowY: fetchingJob ? 'hidden' : 'scroll' }}>
                            <Paper sx={{
                                width: '100%',
                                height: 60,
                                p: 2,
                                position: 'sticky',
                                top: 0,
                                zIndex: theme.zIndex.appBar - 2,
                                mb: 3,
                                display: 'flex', flexDirection: 'row', alignItems: 'center'
                            }} elevation={2} square>
                                <FormControlLabel
                                    control={<Checkbox checked={detailed} onClick={onDetailedToggle} />}
                                    label={strings.get('detailed')}
                                />
                                    <Chip label={<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        {detailed && <>
                                            <Typography variant="body2" sx={{ mr: 0.5, opacity: 0.75, fontWeight: 'bold' }}>
                                                {strings.get('orderLabel')}
                                            </Typography>
                                            <Divider orientation="vertical" flexItem sx={{ mr: 0.5 }} variant="middle" />
                                        </>}
                                        <Typography variant="body1" sx={{ mr: 0.5 }}>
                                            {strings.get('length')}
                                        </Typography>
                                        <Typography variant="body2" sx={(theme) => ({ color: theme.palette.background.paper, backgroundColor: theme.palette.primary.main, borderRadius: 100, pl: 0.75, pr: 0.75 })}>
                                            {strings.get('noOfCuts')}
                                        </Typography>
                                    </Box>} size="small" variant="outlined" color="primary" sx={(theme) => ({ opacity: 0.75, backgroundColor: theme.palette.background.paper, marginLeft: 'auto', '& .MuiChip-label': { pr: '1px' } })} />
                            </Paper>
                            {fetchingJob && <Box sx={{ width: '100%', flex: 1, pl: 3, pr: 3, pt: 1 }}>
                                {[0, 1, 2].map((item) => (<Box key={item} sx={{ mb: 5 }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: 3 }}>
                                        <Skeleton variant="circular" width={40} height={40} />
                                        <Box sx={{ ml: 2, flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}>
                                            <Skeleton variant="text" animation="wave" width="100%" height={15} />
                                            <Skeleton variant="text" animation="wave" width="50%" height={15} />
                                        </Box>
                                    </Box>
                                    <Skeleton variant="rectangular" animation="wave" width="100%" height={200} />
                                </Box>))}
                            </Box>}
                            <Fade in={!fetchingJob} mountOnEnter unmountOnExit>
                                <Box>
                                    {(material || lostMaterial) && <Fade in={true} timeout={350}>
                                        <Chip
                                            icon={lostMaterial ? <ClearIcon /> : <LayersIcon />}
                                            color={lostMaterial ? 'error' : 'secondary'}
                                            variant="outlined"
                                            label={lostMaterial ? <b>{strings.get('lostMaterial')}</b> : <>
                                                {strings.get('usingMaterial', [material.name + ' ' + material.variant])}
                                            </>}
                                            sx={{
                                                mx: 2,
                                                mb: 2,
                                                minHeight: 32,
                                                height: 'auto',
                                                '& .MuiChip-label': {
                                                    whiteSpace: 'normal'
                                                }
                                            }}
                                        />
                                    </Fade>}
                                    <Slide in={true} direction="up" timeout={350}>
                                        <Box>
                                            {jobResult.map((stockUnit, index) => <ResultItem key={index} stockUnit={stockUnit} index={index} detailed={detailed} />)}
                                        </Box>
                                    </Slide>
                                    <Slide in={true} direction="up" timeout={350} style={{ transitionDelay: '80ms' }}>
                                        <Paper elevation={5} sx={{ mt: 1, ml: 2, mr: 2 }}>
                                            <Typography variant="h6" sx={{ fontWeight: 'bold', p: 2 }}>
                                                {strings.get('offcutSummary')}
                                            </Typography>
                                            <Divider />
                                            <TableContainer>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell><b>{strings.get('stock')}</b></TableCell>
                                                            <TableCell align="right"><b>{strings.get('offcut')}</b></TableCell>
                                                            <TableCell align="right"><b>{strings.get('reusable')}</b></TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {jobResult.map((stockUnit, index) => (<React.Fragment key={index}>
                                                            {stockUnit.cycles.map((cycle, subIndex) => (
                                                                <TableRow
                                                                    key={subIndex}
                                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                >
                                                                    <TableCell component="th" scope="row">
                                                                        {measSys.encode(stockUnit.length) + ' ' + measSys.notation}<Times lr />{cycle.repeat}
                                                                    </TableCell>
                                                                    <TableCell align="right">
                                                                        {measSys.encode(cycle.offcut) + ' ' + measSys.notation}{cycle.offcut > 0 && <><Times lr />{cycle.repeat}</>}
                                                                    </TableCell>
                                                                    <TableCell align="right">
                                                                        {cycle.offcut === 0 ? <RemoveIcon /> : (cycle.offcut < jobProperties.minReusableLength ? <CloseIcon color="error" /> : <DoneIcon color="success" />)}
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </React.Fragment>))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <Divider />
                                            <Typography variant="body1" sx={{ fontWeight: 'bold', p: 2 }}>
                                                {strings.get('percentageLoss')}
                                            </Typography>
                                            <Box sx={{ p: 2, pt: 0 }}>
                                                <LinearProgressWithLabel value={percentageLoss} color={percentageLoss < 7 ? 'success' : (percentageLoss < 15 ? 'warning' : 'error')} />
                                            </Box>
                                        </Paper>
                                    </Slide>
                                    {(material || solutionSnapshot) && <Slide in={true} direction="up" timeout={350} style={{ transitionDelay: '160ms' }}>
                                        <Paper elevation={5} sx={{ mt: 3, ml: 2, mr: 2 }}>
                                            <Typography variant="h6" sx={{ fontWeight: 'bold', p: 2, display: 'flex', alignItems: 'center' }}>
                                                {material ? (material.name + ' ' + material.variant) : strings.get('lostMaterial')}
                                                {(viewingJobStockError || lostMaterial) && <WarningIcon color="error" sx={{ ml: 1 }} />}
                                            </Typography>
                                            <Divider />
                                            <Box sx={{ p: 2 }}>
                                                {materialSummary.buy.length > 0 && (<>
                                                    <Chip
                                                        icon={<PaidIcon />}
                                                        color="warning"
                                                        variant="outlined"
                                                        label={strings.get('StockToBuy')}
                                                        sx={{ mb: 2 }}
                                                    />
                                                    {materialSummary.buy.map((item, index) => (
                                                        <StockRepresentation
                                                            key={'buy-' + index}
                                                            color="warning"
                                                            number={item.count}
                                                            text={measSys.encode(item.length) + ' ' + measSys.notation}
                                                            size={item.size}
                                                        />
                                                    ))}
                                                </>)}
                                                {materialSummary.use.length > 0 && (<>
                                                    <Chip
                                                        icon={<InventoryIcon sx={{ pl: 0.5 }} />}
                                                        color="primary"
                                                        variant="outlined"
                                                        label={strings.get('useFromAvStock')}
                                                        sx={{ mt: 2, mb: 2 }}
                                                    />
                                                    {materialSummary.use.map((item, index) => (
                                                        <StockRepresentation
                                                            key={'use-' + index}
                                                            color="primary"
                                                            number={item.count}
                                                            text={measSys.encode(item.length) + ' ' + measSys.notation}
                                                            size={item.size}
                                                        />
                                                    ))}
                                                </>)}
                                                {materialSummary.reusable.length > 0 && (<>
                                                    <Chip
                                                        icon={<CheckCircleIcon sx={{ pl: 0.5 }} />}
                                                        color="success"
                                                        variant="outlined"
                                                        label={strings.get('resultingReusable')}
                                                        sx={{ mt: 2, mb: 2 }}
                                                    />
                                                    {materialSummary.reusable.map((item, index) => (
                                                        <StockRepresentation
                                                            key={'reusable-' + index}
                                                            color="success"
                                                            number={item.count}
                                                            text={measSys.encode(item.length) + ' ' + measSys.notation}
                                                            size={item.size}
                                                        />
                                                    ))}
                                                </>)}
                                                {materialSummary.waste.length > 0 && (<>
                                                    <Chip
                                                        icon={<DeleteIcon sx={{ pl: 0.5 }} />}
                                                        color="error"
                                                        variant="outlined"
                                                        label={strings.get('resultingWaste')}
                                                        sx={{ mt: 2, mb: 2 }}
                                                    />
                                                    {materialSummary.waste.map((item, index) => (
                                                        <StockRepresentation
                                                            key={'waste-' + index}
                                                            color="error"
                                                            number={item.count}
                                                            text={measSys.encode(item.length) + ' ' + measSys.notation}
                                                            size={item.size}
                                                        />
                                                    ))}
                                                </>)}
                                                <Divider sx={{ mt: 3 }} />
                                                <Box sx={{
                                                    width: '100%',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    mt: 4,
                                                    mb: 2
                                                }}>
                                                    <Fab
                                                        variant="extended"
                                                        size="large"
                                                        color="success"
                                                        disabled={viewingJobStockError || solutionAccepted || waitingState !== 0}
                                                        onClick={handleSolution}
                                                    >
                                                        {waitingState === 3 ? <CircularProgress color="inherit" size="1em" sx={{ mr: 1 }} /> : <CheckCircleIcon sx={{ mr: 1 }} />}
                                                        {solutionAccepted ? strings.get('solutionAccepted') : strings.get('acceptSolution')}
                                                    </Fab>
                                                    {(!viewingJobStockError && !solutionAccepted) && <Typography variant="caption" sx={{ mt: 2, textAlign: 'center' }}>
                                                        {strings.get('acceptSolutionTip', [(material.name + ' ' + material.variant).trim()])}
                                                    </Typography>}
                                                    {(!viewingJobStockError && solutionAccepted) && <Typography variant="caption" sx={{ mt: 2, textAlign: 'center' }}>
                                                        {strings.get('solutionAcceptedTip')}
                                                    </Typography>}
                                                    {viewingJobStockError && <Typography variant="caption" sx={{ mt: 2, textAlign: 'center' }} color="error">
                                                        {strings.get('noInventorySolutionTip')}
                                                    </Typography>}
                                                    <br />
                                                    {spinOffHref && <>
                                                        <a
                                                            href={spinOffHref}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            style={{ all: 'unset' }}
                                                        >
                                                            <Fab
                                                                variant="extended"
                                                                size="large"
                                                                color="primary"
                                                            >
                                                                <AutoModeIcon sx={{ mr: 1 }} />
                                                                {strings.get('reuseStock')}
                                                            </Fab>
                                                        </a>
                                                        {<Typography variant="caption" sx={{ mt: 2, textAlign: 'center' }}>
                                                            {strings.get('reuseStockTip')}
                                                        </Typography>}
                                                    </>}
                                                </Box>
                                            </Box>
                                        </Paper>
                                    </Slide>}
                                </Box>
                            </Fade>
                        </SubScreen>
                    </Slide>
                    {((handheld) && (tabIndex < 2) && (!viewingResult) && (waitingState < 2)) && <Fab
                        variant="extended"
                        disabled={waitingState > 0}
                        color={(() => ["secondary", "success", "primary"][tabIndex])()}
                        sx={(theme) => ({ position: 'absolute', bottom: theme.spacing(3), right: theme.spacing(3) })}
                        onClick={handleMobileFAB}
                    >
                        {tabIndex === 0 && <><SkipNextIcon sx={{ mr: 1 }} />{strings.get('nextStep')}</>}
                        {tabIndex === 1 && <StartButtonContent />}
                    </Fab>}
                </Container>
                {handheld && <Paper elevation={10} sx={{ zIndex: 1 }} square>
                    <Box sx={{ width: '100%', position: 'relative' }}>
                        <BottomTabs value={tabIndex} onChange={handleTabChange}>
                            <Tab icon={<AssignmentIcon />} label={strings.get('demand')} />
                            <Tab icon={<LineStyleIcon />} label={strings.get('stock')} />
                            <Tab disabled={!viewingResult} icon={
                                waitingState !== 2 ? <FactCheckIcon /> : <CircularProgress color="inherit" size={24} />
                            } label={strings.get('result')} />
                        </BottomTabs>
                        {!viewingResult && <Box sx={{ position: 'absolute', width: '33%', height: '100%', top: 0, right: 0 }}>
                            <Box sx={{ display: { md: 'none' }, width: '100%', height: '100%' }}>
                                <Box sx={{ width: '100%', height: '100%' }} onClick={() => ui.showSnackbar(strings.get('noJobResultYet'))} />
                            </Box>
                        </Box>}
                    </Box>
                </Paper>}
                {!handheld && <>
                    <Divider />
                    <DesktopWindowCtrlArea>
                        <Box sx={{ flex: subScreenIndex === 0 ? 1 : 0, transition: 'flex 0.1s ease-out' }} />
                        <Fab
                            variant="extended"
                            disabled={waitingState > 0}
                            size="medium"
                            color={viewingResult ? (subScreenIndex === 0 ? 'secondary' : 'primary') : 'success'}
                            onClick={handleDefaultFAB}
                        >
                            {!viewingResult ? <StartButtonContent /> : <>
                                {subScreenIndex === 0 ? <>
                                    <FactCheckIcon sx={{ mr: 1 }} /> {strings.get('viewResult')}
                                </> : <>
                                    <AssignmentIcon sx={{ mr: 1 }} /> {strings.get('viewJobDetails')}
                                </>}
                            </>}
                        </Fab>
                    </DesktopWindowCtrlArea>
                </>}
            </ModalWindow>
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2, pt: 3 }}>
                {props.jobs.map((entry, index) => (
                    <JobCard
                        key={index}
                        entry={entry}
                        index={index}
                        onClick={(jobId) => handleCard(jobId, entry.status)}
                        fetchJobs={fetchJobs}
                    />
                ))}
            </Box>
        </Box>
    );
}


export default connect(
    (state) => ({
        isLoggedIn: isLoggedIn(state),
        userProfile: getProfile(state),
        jobs: getJobs(state),
        jobOrders: getJobOrders(state),
        jobAutoStock: getJobAutoStock(state),
        jobAvailableStock: getJobAvailableStock(state),
        jobResult: getJobResult(state),
        jobProperties: getJobProperties(state),
        materials: getMaterials(state),
        getGroupedMaterials: getMaterialsAsGroups(state),
        usableStocks: getUsableStocks(state),
        wasteStocks: getWasteStocks(state)
    }),
    (dispatch) => ({
        jobModalReset: (defaultSystem) => dispatch(jobModalReset(defaultSystem)),
        jobsReceived: (jobs) => dispatch(jobsReceived(jobs)),
        jobsUpdated: (jobs) => dispatch(jobsUpdated(jobs)),
        jobAdded: (job) => dispatch(jobAdded(job)),
        jobDataReceived: (jobData) => dispatch(jobDataReceived(jobData)),
        jobAdjusted: (job) => dispatch(jobAdjusted(job)),
        jobDeleted: (job) => dispatch(jobDeleted(job)),
        jobOrderAdded: (order) => dispatch(jobOrderAdded(order)),
        jobOrderEntryAdded: (orderIndex, entry) => dispatch(jobOrderEntryAdded({ orderIndex, entry })),
        jobImported: (importedData) => dispatch(jobImported(importedData)),
        jobAutoStockAdded: (stock) => dispatch(jobAutoStockAdded(stock)),
        jobAutoStockDeleted: (stockId) => dispatch(jobAutoStockDeleted(stockId)),
        jobAutoStockCleared: () => RequireConfirmation(strings.get('qClearAllFields'), () => dispatch(jobAutoStockCleared())),
        jobAvailableStockReceived: (stock) => dispatch(jobAvailableStockReceived(stock)),
        jobAvailableStockAdded: (stock) => dispatch(jobAvailableStockAdded(stock)),
        jobAvailableStockDeleted: (stockId) => dispatch(jobAvailableStockDeleted(stockId)),
        jobAvailableStockCleared: () => RequireConfirmation(strings.get('qClearAllFields'), () => dispatch(jobAvailableStockCleared())),
        jobAvailableStockClearedForce: () => dispatch(jobAvailableStockCleared()),
        jobResultReceived: (result) => dispatch(jobResultReceived(result)),
        jobPropertiesAdjusted: (fields) => dispatch(jobPropertiesAdjusted(fields)),
        materialsReceived: (materials) => dispatch(materialsReceived(materials)),
        usableStocksReceived: (stocks) => dispatch(usableStocksReceived(stocks)),
        wasteStocksReceived: (waste) => dispatch(wasteStocksReceived(waste))
    })
)(JobsScreen);
