import AddIcon from '@mui/icons-material/Add';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import LayersIcon from '@mui/icons-material/Layers';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import LineStyleIcon from '@mui/icons-material/LineStyle';
import RefreshIcon from '@mui/icons-material/Refresh';
import StraightenIcon from '@mui/icons-material/Straighten';
import {
    Box,
    Button, Chip, CircularProgress, Divider, Fab, Fade, ListSubheader,
    Menu,
    MenuItem,
    Paper, Skeleton, Stack, Tab, Typography, Zoom
} from "@mui/material";
import IconButton from '@mui/material/IconButton';
import React from "react";
import { connect } from "react-redux";
import api from '../../api/api';
import { withGlobalUI } from '../../App';
import { BottomTabs, ModalWindow, OverflowPanel, OverflowPanelsContainer, OverflowPanelsSelector, SectionHeader } from '../../components/ModalWindow';
import { AppBarContent } from "../../components/UIBase";
import { DesktopActions, PseudoToolbar } from '../../components/Utilities';
import strings from '../../config/strings';
import withMediaQuery from '../../HOCs/withMediaQuery';
import { isLoggedIn } from '../../store/auth';
import { getMaterialsAsGroups, Material, materialAdjusted, materialDeleted, materialsAdded, materialsReceived } from '../../store/features/materials';
import { getProfile } from '../../store/features/profile';
import {
    getUsableStocks, getWasteStocks, usableStocksAdded,
    usableStocksAdjusted,
    usableStocksDeleted, usableStocksReceived, wasteStocksAdded,
    wasteStocksAdjusted,
    wasteStocksDeleted, wasteStocksReceived
} from "../../store/features/stocks";
import { getPrecisionMeasSystem, preferredMeasSystemMenu } from "../../util/meas";
import { getAltRowStyleIndex, multiplyComponent } from '../../util/util';
import Item from './components/Item';
import MaterialCard from './components/MaterialCard';
import MaterialSelector from './components/MaterialSelector';
import NewMaterialMenu from './components/NewMaterialMenu';
import { AddWidget, SelectWidget } from './components/widgets';


class StocksScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            logoutSnackbarVisible: false,
            filterMenuAnchor: null,
            newMenuAnchor: null,
            viewAsMenuAnchor: null,
            stocksContainerRef: { current: null },
            materialFilters: [],
            selectedMaterial: null,
            modalOpen: false,
            tabIndex: 0,
            addingStock: false,
            addingWaste: false,
            checkedStock: [],
            checkedWaste: [],
            partialStockAmounts: {},
            partialWasteAmounts: {},
            stockItemsToLoad: 0,
            wasteItemsToLoad: 0,
            loadingStockData: false,
            movingStocks: false,
            movingWaste: false,
            working: false,
            deleting: false,
            dataVersion: -1,
            measurementSystem: props.userProfile.preferredMeasurementSystem
        };
        this.ui = this.props.ui;
    }

    beginWorking = () => {
        this.setState({ working: true });
    };

    doneWorking = () => {
        this.setState({ working: false });
    };

    getMaterials = async () => {
        const result = await api.materials.getMaterials();
        if (result.success) {
            this.props.materialsReceived(result.data);
        } else {
            this.ui.showSnackbar(strings.get('cantFetchMatsInInventory'), 'error');
        }
    };

    componentDidMount() {
        this.getMaterials();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isLoggedIn !== this.props.isLoggedIn) {
            if (this.props.isLoggedIn) {
                this.getMaterials();
            }
        }
    }

    onFilterMenuClick = (event) => {
        this.setState({ filterMenuAnchor: event.currentTarget });
    };

    onFilterMenuClose = () => {
        this.setState({ filterMenuAnchor: null });
    };

    onNewMenuClick = (event) => {
        this.setState({ newMenuAnchor: event.currentTarget });
    };

    onNewMenuClose = () => {
        this.setState({ newMenuAnchor: null });
    };

    onViewAsMenuClick = (event) => {
        this.setState({ viewAsMenuAnchor: event.currentTarget });
    };

    onViewAsMenuClose = (event, reason, selection = null) => {
        const deltas = {
            viewAsMenuAnchor: null
        }
        if (selection) {
            deltas.measurementSystem = selection;
            deltas.addingStock = false;
            deltas.addingWaste = false;
        }
        this.setState(deltas);
    };

    checkMaterialFilter = (id) => {
        if (this.state.materialFilters.length === 0) {
            return true;
        } else {
            return this.state.materialFilters.indexOf(id) !== -1;
        }
    };

    isMaterialExplicitlyFiltered = (id) => {
        return this.state.materialFilters.indexOf(id) !== -1;
    };

    getMaterialStocks = async (material) => {
        const result = await api.materials.getStocks(material.id);
        if (result.success) {
            return result.data;
        } else {
            this.ui.showSnackbar(strings.get('cantFetchStockForMat'), 'error');
            this.onModalClose();
            return null;
        }
    };

    onMaterialClick = (material) => {
        this.setState({
            modalOpen: true,
            selectedMaterial: material,
            tabIndex: 0,
            stockItemsToLoad: material.stocks,
            wasteItemsToLoad: material.waste,
            loadingStockData: true
        }, async () => {
            const allStocks = await this.getMaterialStocks(material);
            if (allStocks !== null) {
                this.props.usableStocksReceived(allStocks.stocks);
                this.props.wasteStocksReceived(allStocks.waste);
                this.setState({ loadingStockData: false, dataVersion: allStocks.version });
            } else {
                this.setState({ loadingStockData: false });
            }
        });
    };

    onModalClose = () => {
        if (this.state.working) {
            return false;
        }
        if (this.state.checkedStock.length > 0 || this.state.checkedWaste.length > 0) {
            if (!window.confirm('Are you sure you want to close this window?')) {
                return false;
            }
        }
        this.setState({
            modalOpen: false,
            checkedStock: [],
            checkedWaste: [],
            partialStockAmounts: [],
            partialWasteAmounts: [],
            addingStock: false,
            addingWaste: false
        }, this.getMaterials);
    };

    handleTabChange = (event, newValue) => {
        this.setState({ tabIndex: newValue });
    };

    onAddStockButton = () => {
        this.setState({ addingStock: true });
    };

    onAddStockWidgetClose = () => {
        this.setState({ addingStock: false });
    };

    onAddWasteButton = () => {
        this.setState({ addingWaste: true });
    };

    onAddWasteWidgetClose = () => {
        this.setState({ addingWaste: false });
    };

    onStockChecked = (item) => {
        const checkedStock = [...this.state.checkedStock];
        if (!checkedStock.some(x => x === item.id)) {
            checkedStock.push(item.id);
        }
        this.setState({ checkedStock, addingStock: false });
    }

    onStockUnchecked = (item) => {
        const checkedStock = [...this.state.checkedStock];
        const partialStockAmounts = { ...this.state.partialStockAmounts };
        delete partialStockAmounts[item.id];
        const index = checkedStock.findIndex(x => x === item.id);
        if (index !== -1) {
            checkedStock.splice(index, 1);
        }
        this.setState({ checkedStock, partialStockAmounts });
    }

    onWasteChecked = (item) => {
        const checkedWaste = [...this.state.checkedWaste];
        if (!checkedWaste.some(x => x === item.id)) {
            checkedWaste.push(item.id);
        }
        this.setState({ checkedWaste, addingWaste: false });
    }

    onWasteUnchecked = (item) => {
        const checkedWaste = [...this.state.checkedWaste];
        const partialWasteAmounts = { ...this.state.partialWasteAmounts };
        delete partialWasteAmounts[item.id];
        const index = checkedWaste.findIndex(x => x === item.id);
        if (index !== -1) {
            checkedWaste.splice(index, 1);
        }
        this.setState({ checkedWaste, partialWasteAmounts });
    }

    onCheckAllButton = (tabIndex) => {
        const checked = [];
        const stocks = tabIndex === 0 ? this.props.usableStocks :  this.props.wasteStocks;
        stocks.forEach((stock) => {
            checked.push(stock.id);
        });
        this.setState({ [tabIndex === 0 ? 'checkedStock' : 'checkedWaste']: checked });
    }

    onClearAllButton = (tabIndex) => {
        this.setState({
            [tabIndex === 0 ? 'checkedStock' : 'checkedWaste']: [],
            [tabIndex === 0 ? 'partialStockAmounts' : 'partialWasteAmounts']: {}
        });
    }

    onPartialStockAmountChanged = (event) => {
        const partialStockAmounts = { ...this.state.partialStockAmounts };
        if (event.amount === event.item.count) {
            delete partialStockAmounts[event.item.id];
        } else {
            partialStockAmounts[event.item.id] = event.amount;
        }
        console.log(partialStockAmounts);
        this.setState({ partialStockAmounts });
    }

    onPartialWasteAmountChanged = (event) => {
        const partialWasteAmounts = { ...this.state.partialWasteAmounts };
        if (event.amount === event.item.count) {
            delete partialWasteAmounts[event.item.id];
        } else {
            partialWasteAmounts[event.item.id] = event.amount;
        }
        console.log(partialWasteAmounts);
        this.setState({ partialWasteAmounts });
    }

    onMaterialsAdded = async (materials) => {
        const insert = [];
        materials.forEach((material) => {
            insert.push({
                ...material,
                meta: '{}'
            });
        });
        try {
            const body = {
                insert
            };
            const response = await api.call('/materials', 'POST', body);
            if (response.ok) {
                const inserted = [];
                const newIds = response.data.inserted;
                for (let i = 0; i < newIds.length; i++) {
                    inserted.push({
                        ...new Material({
                            ...insert[i],
                            id: newIds[i],
                            meta: {}
                        })
                    });
                }
                this.props.materialsAdded(inserted);
                if (this.state.materialFilters.length > 0) {
                    this.setState({
                        materialFilters: [...this.state.materialFilters, ...newIds]
                    });
                }
                this.ui.showSnackbar(strings.get('matsAddedSuccessfully'), 'success');
            }
            return response.ok;
        } catch (error) {
            this.ui.showSnackbar(strings.get('cantAddMats'), 'error');
            console.log(error);
            return false;
        }
    }

    onMaterialAdjust = async (adjustment) => {
        try {
            const body = {
                adjust: [
                    adjustment
                ]
            };
            const response = await api.call('/materials', 'PUT', body);
            if (response.ok) {
                this.props.materialAdjusted(adjustment);
                this.ui.showSnackbar(strings.get('matAdjSuccessfully'), 'success');
            }
        } catch (error) {
            this.ui.showSnackbar(strings.get('cantAdjMat'), 'error');
            console.log(error);
        }
    }

    onMaterialDelete = async (materialId) => {
        try {
            const body = {
                delete: [
                    materialId
                ]
            };
            const response = await api.call('/materials', 'DELETE', body);
            if (response.ok) {
                this.props.materialDeleted(materialId);
                const filters = [...this.state.materialFilters];
                const filterIndex = filters.indexOf(materialId);
                if (filterIndex !== -1) {
                    filters.splice(filterIndex, 1);
                    this.setState({ materialFilters: filters });
                }
                this.ui.showSnackbar(strings.get('matDelSuccessfully'), 'success');
            }
        } catch (error) {
            this.ui.showSnackbar(strings.get('cantDelMat'), 'error');
            console.log(error);
        }
    }

    patchStock = async (direction = 1, deletion = false, add = null) => {
        this.beginWorking();

        let allStocks = {
            stocks: [],
            waste: []
        };
        let initialStocks = {
            stocks: [],
            waste: []
        };
        this.props.usableStocks.forEach((item) => {
            allStocks.stocks.push({ ...item });
            initialStocks.stocks.push({ ...item });
        });
        this.props.wasteStocks.forEach((item) => {
            allStocks.waste.push({ ...item });
            initialStocks.waste.push({ ...item });
        });

        let success = true;
        let reconciliationError = true;

        try {
            const checked = direction === 1 ? this.state.checkedStock : this.state.checkedWaste;
            const partials = direction === 1 ? this.state.partialStockAmounts : this.state.partialWasteAmounts;
            const srcKey = direction === 1 ? 'stocks' : 'waste';
            const destKey = direction === 1 ? 'waste' : 'stocks';
            const SrcKey = direction === 1 ? 'Stocks' : 'Waste';
            const DestKey = direction === 1 ? 'Waste' : 'Stocks';
            const adjustSrc = [], insertSrc = [], removeSrc = [];
            const adjustDest = [], insertDest = [];
            if (add) {
                add.forEach((newStock) => {
                    const stock = allStocks[srcKey].find(x => x.length === newStock.length);
                    if (stock) {
                        adjustSrc.push({
                            ...stock,
                            count: stock.count + newStock.count
                        });
                        // Adjust directly in internal state
                        stock.count += newStock.count;
                    } else {
                        insertSrc.push(newStock);
                    }
                });
            } else {
                checked.forEach((id) => {
                    const stock = allStocks[srcKey].find(x => x.id === id);
                    const partial = partials[id];
                    const transferAmount = partial ? partial : stock.count;
                    const destStock = allStocks[destKey].find(x => x.length === stock.length);
                    if (partial) {
                        adjustSrc.push({
                            ...stock,
                            count: stock.count - transferAmount
                        });
                        // Adjust source amount in internal state
                        stock.count -= transferAmount;
                    } else {
                        removeSrc.push(id);
                    }
                    if (!deletion) {
                        if (destStock) {
                            adjustDest.push({
                                ...destStock,
                                count: destStock.count + transferAmount
                            });
                            // Adjust destination amount in internal state
                            destStock.count += transferAmount;
                        } else {
                            insertDest.push({
                                length: stock.length,
                                count: transferAmount
                            });
                        }
                    }
                });
            }
            // Remove source items by id in internal state
            removeSrc.forEach((id) => {
                allStocks[srcKey].splice(
                    allStocks[srcKey].findIndex(x => x.id === id),
                1);
            });
            const body = {
                materialId: this.state.selectedMaterial.id,
                version: this.state.dataVersion
            };
            if (adjustSrc.length > 0) body['adjust' + SrcKey] = adjustSrc;
            if (insertSrc.length > 0) body['insert' + SrcKey] = insertSrc;
            if (removeSrc.length > 0) body['remove' + SrcKey] = removeSrc;
            if (adjustDest.length > 0) body['adjust' + DestKey] = adjustDest;
            if (insertDest.length > 0) body['insert' + DestKey] = insertDest;

            const response = await api.call('/all-stock', 'PATCH', body);
            const responsePayload = response.data;

            if (response.ok) {
                // Add inserted items to internal state
                insertSrc.forEach((item, index) => {
                    allStocks[srcKey].push({
                        ...item,
                        id: responsePayload['inserted' + SrcKey][index]
                    });
                });
                insertDest.forEach((item, index) => {
                    allStocks[destKey].push({
                        ...item,
                        id: responsePayload['inserted' + DestKey][index]
                    });
                });
                this.setState({ dataVersion: responsePayload.version });
            } else {
                success = false;

                let updatedStocks = {
                    stocks: responsePayload.stocks,
                    waste: responsePayload.waste,
                    version: responsePayload.version
                };

                // Try fetching the proper data if version is not specified
                // The version field is only present if we get a reply with the stocks and waste data
                if (updatedStocks.version === undefined) {
                    updatedStocks = await this.getMaterialStocks(this.state.selectedMaterial);
                }

                // If we did get the updated data from either the PATCH reply or the subsequent GET
                if (updatedStocks.version !== undefined) {
                    // Remove selections and partial amounts for items that do not exist anymore
                    const newSelStocks = this.state.checkedStock.filter(x => updatedStocks.stocks.some(y => y.id === x));
                    const newSelWaste = this.state.checkedWaste.filter(x => updatedStocks.waste.some(y => y.id === x));
                    const newPartialStocks = { ...this.state.partialStockAmounts };
                    for (const pStockKey in newPartialStocks) {
                        if (!updatedStocks.stocks.some(x => String(x.id) === pStockKey)) {
                            delete newPartialStocks[pStockKey];
                        }
                    }
                    const newPartialWaste = { ...this.state.partialWasteAmounts };
                    for (const pWasteKey in newPartialWaste) {
                        if (!updatedStocks.waste.some(x => String(x.id) === pWasteKey)) {
                            delete newPartialWaste[pWasteKey];
                        }
                    }

                    // Iterate over selected stocks and waste and check the new counts
                    // If they're different, ensure the correct value is present in partial amounts by inserting it if missing
                    // Missing partial amounts mean the entirety of that stock is selected, but if that value has changed,
                    // we need to explicitly state what value was selected beforehand, to avoid the implicit full selection to persist
                    // when the value gets updated
                    // Also make sure partial counts are removed if the new stock counts match the existing partial counts
                    // This is due do the same behaviour described above - fully selected stock counts result in no partial record
                    // If there were a partial record with a count equal to the stock count, the stock unit would be adjusted to 0
                    // instead of it being deleted; by removing these partial record counts, we ensure fully selected stock units
                    // still get deleted after a reconciliation if the counts now match
                    newSelStocks.forEach((id) => {
                        const predicate = x => x.id === id;
                        const oldStock = initialStocks.stocks.find(predicate);
                        const newStock = updatedStocks.stocks.find(predicate);
                        if (newStock.count !== oldStock.count) {
                            if (newPartialStocks[newStock.id] === undefined) {
                                newPartialStocks[newStock.id] = oldStock.count;
                            } else {
                                if (newPartialStocks[newStock.id] === newStock.count) {
                                    delete newPartialStocks[newStock.id];
                                }
                            }
                        }
                    });
                    newSelWaste.forEach((id) => {
                        const predicate = x => x.id === id;
                        const oldWaste = initialStocks.waste.find(predicate);
                        const newWaste = updatedStocks.waste.find(predicate);
                        if (newWaste.count !== oldWaste.count) {
                            if (newPartialWaste[newWaste.id] === undefined) {
                                newPartialWaste[newWaste.id] = oldWaste.count;
                            }  else {
                                if (newPartialWaste[newWaste.id] === newWaste.count) {
                                    delete newPartialWaste[newWaste.id];
                                }
                            }
                        }
                    });

                    // Copy updated stocks into working memory
                    allStocks.stocks = [...updatedStocks.stocks];
                    allStocks.waste = [...updatedStocks.waste];

                    // Update state with adjusted selections
                    this.setState({
                        checkedStock: newSelStocks,
                        checkedWaste: newSelWaste,
                        partialStockAmounts: newPartialStocks,
                        partialWasteAmounts: newPartialWaste,
                        dataVersion: updatedStocks.version
                    });

                    reconciliationError = false;
                }
            }
        } catch (error) {
            success = false;
            console.log(error);
        }

        if (success) {
            if (direction === 1) {
                this.onClearAllButton(0);
            } else {
                this.onClearAllButton(1);
            }
        } else {
            if (!reconciliationError) {
               this.ui.showSnackbar(strings.get('stockManOpFailed'), 'warning');
            } else {
               this.ui.showSnackbar(
                    strings.get('stockManCantComm'),
                    'error'
                );
            }
        }

        // Update stock from working memory into Redux store
        const revert = success ? false : reconciliationError;
        if (revert) {
            this.props.usableStocksReceived(initialStocks.stocks);
            this.props.wasteStocksReceived(initialStocks.waste);
        } else {
            this.props.usableStocksReceived(allStocks.stocks);
            this.props.wasteStocksReceived(allStocks.waste);
        }

        this.doneWorking();

        if (success) {
            this.ui.showSnackbar(
                strings.get(
                    'stockOpSuccessful',
                    [
                        strings.get((direction === 1 ? 'stockOpItemStock' : 'stockOpItemWaste')),
                        strings.get((deletion ? 'stockOpDidRem' : (add ? 'stockOpDidAdd' : 'stockOpDidMove')))
                    ]
                ), 'success'
            );
        }

        return success;
    };

    onStocksMoved = async () => {
        this.setState({ movingStocks: true });
        await this.patchStock();
        this.setState({ movingStocks: false });
    };

    onWasteMoved = async () => {
        this.setState({ movingWaste: true });
        await this.patchStock(-1);
        this.setState({ movingWaste: false });
    };

    render() {
        const { handheld, groupMaterials } = this.props;
        const { addingStock, addingWaste } = this.state;
        const hasCheckedStock = this.state.checkedStock.length > 0;
        const hasCheckedWaste = this.state.checkedWaste.length > 0;
        const handheldSelItems = this.state.tabIndex === 0 ? this.state.checkedStock.length : this.state.checkedWaste.length;

        const filterMenuOpen = Boolean(this.state.filterMenuAnchor);
        const newMenuOpen = Boolean(this.state.newMenuAnchor);
        const viewAsMenuOpen = Boolean(this.state.viewAsMenuAnchor);

        const working = this.state.working;

        const groupedMaterials = groupMaterials((material) => this.isMaterialExplicitlyFiltered(material.id));

        let modalTitle = null;
        let modalHandheldToolbar = null;
        if (this.state.selectedMaterial) {
            const overrideTitle = handheld && handheldSelItems > 0;
            modalTitle = <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {overrideTitle && <Fade in={true}>
                    <Box>
                        {strings.get('nSelected', [handheldSelItems])}
                    </Box>
                </Fade>}
                {!overrideTitle && <>
                    {this.state.selectedMaterial.name}
                    {this.state.selectedMaterial.variant !== '' && <>
                        <Divider orientation="vertical" flexItem sx={{ mx: 1.5 }} />
                        <Box sx={{ fontWeight: 'bold', fontSize: '0.8em', opacity: 0.75 }}>
                            {this.state.selectedMaterial.variant}
                        </Box>
                    </>}
                    {handheld && <>
                        <Chip
                            sx={{ ml: 1.5, mr: 1 }}
                            size="small"
                            variant="outlined"
                            color="primary"
                            label={getPrecisionMeasSystem(this.state.measurementSystem).notation}
                        />
                    </>}
                </>}
                {!handheld && <>
                    <Button variant="outlined" size="small" sx={{ ml: 2 }} startIcon={<StraightenIcon />} onClick={this.onViewAsMenuClick}>
                        {strings.get(preferredMeasSystemMenu.find(x => x.value === this.state.measurementSystem).label)}
                    </Button>
                </>}
            </Box>
        }

        let stockSelectionErrors = false;
        let wasteSelectionErrors = false;
        let handheldToolbarSelErr = false;
        for (const key in this.state.partialStockAmounts) {
            const pStock = this.state.partialStockAmounts[key];
            let pStockError = pStock === null;
            if (!pStockError) {
                const checkStock = this.props.usableStocks.find(x => String(x.id) === key);
                if (pStock > checkStock.count) {
                    pStockError = true;
                }
            }
            if (pStockError) {
                stockSelectionErrors = true;
                if (this.state.tabIndex === 0) {
                    handheldToolbarSelErr = true;
                }
            }
        }
        for (const key in this.state.partialWasteAmounts) {
            const pWaste = this.state.partialWasteAmounts[key];
            let pWasteError = pWaste === null;
            if (!pWasteError) {
                const checkWaste = this.props.wasteStocks.find(x => String(x.id) === key);
                if (pWaste > checkWaste.count) {
                    pWasteError = true;
                }
            }
            if (pWasteError) {
                wasteSelectionErrors = true;
                if (this.state.tabIndex === 1) {
                    handheldToolbarSelErr = true;
                }
            }
        }

        if (handheld) {
            if (handheldSelItems > 0) {
                modalHandheldToolbar = <Fade in={true}>
                    <Stack direction="row" spacing={2}>
                        <IconButton
                            color="inherit"
                            onClick={() => this.onCheckAllButton(this.state.tabIndex)}
                            disabled={working || handheldToolbarSelErr}
                        >
                            <LibraryAddCheckIcon />
                        </IconButton>
                        <IconButton
                            color="inherit"
                            onClick={() => this.onClearAllButton(this.state.tabIndex)}
                            disabled={working || handheldToolbarSelErr}
                        >
                            <FilterNoneIcon />
                        </IconButton>
                        <IconButton color="error" disabled={working || handheldToolbarSelErr} onClick={() => {
                            if (this.state.tabIndex === 0) {
                                this.patchStock(1, true);
                            } else {
                                this.patchStock(-1, true);
                            }
                        }}>
                            {!this.state.deleting && <DeleteIcon />}
                            {this.state.deleting && <CircularProgress size="1em" color="inherit" />}
                        </IconButton>
                    </Stack>
                </Fade>;
            } else {
                modalHandheldToolbar = <>
                    <IconButton
                        color="inherit"
                        onClick={this.onViewAsMenuClick}
                    >
                        <StraightenIcon />
                    </IconButton>
                </>
            }
        }

        return (
            <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
                <AppBarContent title={strings.get('inventoryTitle')}>
                    <Box sx={{
                        display: {
                            md: 'none'
                        }
                    }}>
                        <IconButton onClick={this.getMaterials} color="inherit" sx={{ mr: 2 }}>
                            <RefreshIcon />
                        </IconButton>
                        <IconButton onClick={this.onNewMenuClick} color="inherit" sx={{ mr: 2 }}>
                            <AddCircleIcon />
                        </IconButton>
                        <IconButton onClick={this.onFilterMenuClick} color="inherit">
                            <LayersIcon />
                        </IconButton>
                    </Box>
                </AppBarContent>
                <DesktopActions>
                    <PseudoToolbar />
                    <Paper square elevation={3} sx={{
                        flex: 1,
                        pl: 2,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center'
                    }}>
                        <Button
                            variant="outlined"
                            startIcon={<LayersIcon />}
                            onClick={this.onFilterMenuClick}
                        >
                            {strings.get('materials')}
                        </Button>
                        <Button
                            variant="outlined"
                            startIcon={<AddCircleIcon />}
                            onClick={this.onNewMenuClick}
                            sx={{ mx: 2 }}
                        >
                            {strings.get('newMat')}
                        </Button>
                        <Button
                            variant="outlined"
                            startIcon={<RefreshIcon />}
                            onClick={this.getMaterials}
                        >
                            {strings.get('refresh')}
                        </Button>
                    </Paper>
                </DesktopActions>
                <MaterialSelector
                    anchor={this.state.filterMenuAnchor}
                    open={filterMenuOpen}
                    onClose={this.onFilterMenuClose}
                    materialGroups={groupedMaterials}
                    filters={this.state.materialFilters}
                    onFiltersChanged={(filters) => this.setState({ materialFilters: filters })}
                />
                <NewMaterialMenu
                    anchor={this.state.newMenuAnchor}
                    open={newMenuOpen}
                    onClose={this.onNewMenuClose}
                    onMaterialsAdded={this.onMaterialsAdded}
                />
                <Menu
                    anchorEl={this.state.viewAsMenuAnchor}
                    open={viewAsMenuOpen}
                    onClose={this.onViewAsMenuClose}
                    MenuListProps={{
                        subheader: <ListSubheader sx={{ fontWeight: 'bold' }}>
                            {strings.get('viewAs')}
                        </ListSubheader>
                    }}
                >
                    {preferredMeasSystemMenu.map((option) => (
                        <MenuItem
                            key={option.value}
                            onClick={(event, reason) => this.onViewAsMenuClose(event, reason, option.value)}
                        >
                            {strings.get(option.label)}
                        </MenuItem>
                    ))}
                </Menu>
                <ModalWindow
                    open={this.state.modalOpen}
                    onClose={this.onModalClose}
                    title={modalTitle}
                    handheldToolbar={modalHandheldToolbar}
                    sx={{ p: 0, display: 'flex', flexDirection: 'column' }}
                >
                    {!handheld && <Typography variant="overline" component="div" sx={{ width: '100%', display: 'flex' }}>
                        <SectionHeader color="success">{strings.get('usableStock')}</SectionHeader>
                        <SectionHeader color="error">{strings.get('waste')}</SectionHeader>
                    </Typography>}
                    <OverflowPanelsContainer>
                        <OverflowPanelsSelector panel={this.state.tabIndex}>
                            <OverflowPanel separated={!handheld} sx={{ position: 'relative', display: 'inline-flex', flexDirection: 'column', overflow: 'hidden' }}>
                                <Box sx={{ width: '100%', flex: 1, pb: handheld ? 21 : 12, overflowY: this.state.loadingStockData ? 'hidden' : 'auto', direction: handheld ? 'ltr' : 'rtl' }}>
                                    <Box sx={{ direction: 'ltr' }}>
                                        {this.state.loadingStockData && <Box sx={{ px: 2 }}>
                                            {multiplyComponent(
                                                this.state.stockItemsToLoad,
                                                (index) => <Skeleton key={index} variant="text" sx={{ height: 60 }} />
                                            )}
                                        </Box>}
                                        {!this.state.loadingStockData && this.props.usableStocks.map((item, index) => <Item
                                            key={'stock-' + index}
                                            item={item}
                                            index={index}
                                            amount={this.state.partialStockAmounts[item.id]}
                                            amountMargin={handheld ? 0 : 3}
                                            checked={this.state.checkedStock.some(x => x === item.id)}
                                            onChecked={this.onStockChecked}
                                            onUnchecked={this.onStockUnchecked}
                                            onAmountChanged={this.onPartialStockAmountChanged}
                                            measurementSystem={this.state.measurementSystem}
                                        />)}
                                    </Box>
                                </Box>
                                {!handheld && <SelectWidget
                                    open={hasCheckedStock > 0}
                                    items={this.state.checkedStock.length}
                                    onSelectAll={() => this.onCheckAllButton(0)}
                                    onSelectNone={() => this.onClearAllButton(0)}
                                    onDelete={() => this.patchStock(1, true)}
                                    locked={stockSelectionErrors || working}
                                />}
                                <AddWidget
                                    open={addingStock}
                                    locked={working}
                                    onSubmit={(stock) => this.patchStock(1, false, [stock])}
                                    onClose={this.onAddStockWidgetClose}
                                    measurementSystem={this.state.measurementSystem}
                                />
                                <Zoom in={!addingStock && !this.state.loadingStockData && !hasCheckedStock} mountOnEnter unmountOnExit>
                                    <Fab color="primary" sx={(theme) => ({
                                        position: 'absolute',
                                        bottom: theme.spacing((!handheld && hasCheckedStock && false) ? 11 : 3),
                                        right: theme.spacing(3)
                                    })} onClick={this.onAddStockButton}>
                                        <AddIcon />
                                    </Fab>
                                </Zoom>
                            </OverflowPanel>
                            <OverflowPanel sx={{
                                position: 'relative', display: 'inline-flex', flexDirection: 'column', overflow: 'hidden'
                            }}>
                                <Box sx={{ width: '100%', flex: 1, pb: 12, overflowY: this.state.loadingStockData ? 'hidden' : 'auto' }}>
                                    {this.state.loadingStockData && <Box sx={{ px: 2 }}>
                                        {multiplyComponent(
                                            this.state.wasteItemsToLoad,
                                            (index) => <Skeleton key={index} variant="text" sx={{ height: 60 }} />
                                        )}
                                    </Box>}
                                    {!this.state.loadingStockData && this.props.wasteStocks.map((item, index) => <Item
                                        key={'waste-' + index}
                                        item={item}
                                        index={index}
                                        amount={this.state.partialWasteAmounts[item.id]}
                                        checkboxMargin={handheld ? 0 : 4}
                                        checked={this.state.checkedWaste.some(x => x === item.id)}
                                        onChecked={this.onWasteChecked}
                                        onUnchecked={this.onWasteUnchecked}
                                        onAmountChanged={this.onPartialWasteAmountChanged}
                                        measurementSystem={this.state.measurementSystem}
                                    />)}
                                </Box>
                                {!handheld && <SelectWidget
                                    open={hasCheckedWaste > 0}
                                    items={this.state.checkedWaste.length}
                                    onSelectAll={() => this.onCheckAllButton(1)}
                                    onSelectNone={() => this.onClearAllButton(1)}
                                    onDelete={() => this.patchStock(-1, true)}
                                    locked={wasteSelectionErrors || working}
                                />}
                                <AddWidget
                                    open={addingWaste}
                                    locked={working}
                                    onSubmit={(waste) => this.patchStock(-1, false, [waste])}
                                    onClose={this.onAddWasteWidgetClose}
                                    measurementSystem={this.state.measurementSystem}
                                />
                                <Zoom in={!addingWaste && !this.state.loadingStockData && !hasCheckedWaste} mountOnEnter unmountOnExit>
                                    <Fab color="primary" sx={(theme) => ({
                                        position: 'absolute',
                                        bottom: theme.spacing((!handheld && hasCheckedWaste && false) ? 11 : 3),
                                        right: theme.spacing(3)
                                    })} onClick={this.onAddWasteButton}>
                                        <AddIcon />
                                    </Fab>
                                </Zoom>
                            </OverflowPanel>
                        </OverflowPanelsSelector>
                        {!handheld && <Box sx={{
                            position: 'absolute',
                            width: 0,
                            marginLeft: '50%',
                            marginRight: '50%',
                            height: '100%',
                            overflow: 'visible',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <Stack spacing={4}>
                                <Zoom in={hasCheckedStock} mountOnEnter unmountOnExit>
                                    <Box sx={(theme) => ({
                                        borderStyle: 'solid',
                                        borderWidth: 1,
                                        borderRadius: '50%',
                                        borderColor: theme.palette.divider,
                                        backgroundColor: (stockSelectionErrors || working) ? theme.palette.background.paper : theme.palette.error.main
                                    })}>
                                        <IconButton
                                            size="large"
                                            sx={(theme) => ({ color: theme.palette.background.paper })}
                                            disabled={stockSelectionErrors || working}
                                            onClick={this.onStocksMoved}
                                        >
                                            {!this.state.movingStocks && <ArrowForwardIcon />}
                                            {this.state.movingStocks && <CircularProgress size={24} color="inherit" /> }
                                        </IconButton>
                                    </Box>
                                </Zoom>
                                <Zoom in={hasCheckedWaste} mountOnEnter unmountOnExit>
                                    <Box sx={(theme) => ({
                                        borderStyle: 'solid',
                                        borderWidth: 1,
                                        borderRadius: '50%',
                                        borderColor: theme.palette.divider,
                                        backgroundColor: (wasteSelectionErrors || working) ? theme.palette.background.paper : theme.palette.success.main
                                    })}>
                                        <IconButton
                                            size="large"
                                            sx={(theme) => ({ color: theme.palette.background.paper })}
                                            disabled={wasteSelectionErrors || working}
                                            onClick={this.onWasteMoved}
                                        >
                                            {!this.state.movingWaste && <ArrowBackIcon />}
                                            {this.state.movingWaste && <CircularProgress size={24} color="inherit" /> }
                                        </IconButton>
                                    </Box>
                                </Zoom>
                            </Stack>
                        </Box>}
                        {handheld && <>
                            <Zoom in={this.state.tabIndex === 0 && hasCheckedStock} mountOnEnter unmountOnExit>
                                <Fab color="error" disabled={stockSelectionErrors || working} onClick={this.onStocksMoved} sx={(theme) => ({
                                    position: 'absolute', bottom: theme.spacing(3), right: theme.spacing(3)
                                })}>
                                    {!this.state.movingStocks && <ArrowForwardIcon />}
                                    {this.state.movingStocks && <CircularProgress size={24} color="inherit" /> }
                                </Fab>
                            </Zoom>
                            <Zoom in={this.state.tabIndex === 1 && hasCheckedWaste} mountOnEnter unmountOnExit>
                                <Fab color="success" disabled={wasteSelectionErrors || working} onClick={this.onWasteMoved} sx={(theme) => ({
                                    position: 'absolute', bottom: theme.spacing(addingWaste ? 12 : 3), left: theme.spacing(3)
                                })}>
                                    {!this.state.movingWaste && <ArrowBackIcon />}
                                    {this.state.movingWaste && <CircularProgress size={24} color="inherit" /> }
                                </Fab>
                            </Zoom>
                        </>}
                    </OverflowPanelsContainer>
                    {handheld && <Paper elevation={10} sx={{ zIndex: 1 }} square>
                        <BottomTabs value={this.state.tabIndex} onChange={this.handleTabChange}>
                            <Tab icon={<LineStyleIcon />} label={strings.get('usableStock')} disabled={working} />
                            <Tab icon={<DeleteIcon />} label={strings.get('waste')} disabled={working} />
                        </BottomTabs>
                    </Paper>}
                </ModalWindow>
                <Box sx={{ pb: 4, flex: 1, overflow: 'hidden', overflowY: 'auto' }}>
                    {groupedMaterials.map((materialGroup, groupIndex) => materialGroup.variants.map((material, index) => (
                        this.checkMaterialFilter(material.id) ? <MaterialCard
                            key={material.id}
                            index={getAltRowStyleIndex(groupIndex, index)}
                            material={material}
                            onClick={() => this.onMaterialClick(material)}
                            onAdjust={this.onMaterialAdjust}
                            onDelete={this.onMaterialDelete}
                        /> : null
                    )))}
                </Box>
            </Box>
        );
    }
}


const mapStateToProps = (state) => ({
    isLoggedIn: isLoggedIn(state),
    userProfile: getProfile(state),
    usableStocks: getUsableStocks(state),
    wasteStocks: getWasteStocks(state),
    groupMaterials: getMaterialsAsGroups(state)
});

const mapDispatchToProps = (dispatch) => ({
    materialsReceived: (materials) => dispatch(materialsReceived(materials)),
    materialsAdded: (materials) => dispatch(materialsAdded(materials)),
    materialAdjusted: (material) => dispatch(materialAdjusted(material)),
    materialDeleted: (materialId) => dispatch(materialDeleted(materialId)),
    usableStocksReceived: (stocks) => dispatch(usableStocksReceived(stocks)),
    usableStocksAdded: (stocks) => dispatch(usableStocksAdded(stocks)),
    usableStocksAdjusted: (stocks) => dispatch(usableStocksAdjusted(stocks)),
    usableStocksDeleted: (stocks) => dispatch(usableStocksDeleted(stocks)),
    wasteStocksReceived: (stocks) => dispatch(wasteStocksReceived(stocks)),
    wasteStocksAdded: (stocks) => dispatch(wasteStocksAdded(stocks)),
    wasteStocksAdjusted: (stocks) => dispatch(wasteStocksAdjusted(stocks)),
    wasteStocksDeleted: (stocks) => dispatch(wasteStocksDeleted(stocks))
});

export default connect(mapStateToProps, mapDispatchToProps)(
    withGlobalUI(
        withMediaQuery([
            ['handheld', theme => theme.breakpoints.down('md')]
        ])(StocksScreen)
    )
);
