import { createSlice } from "@reduxjs/toolkit";

import crust from "./base/crust";


// Job records
/*
    {
        id: string,
        name: string,
        description: string,
        meta: string,
        date: int, // timestamp; used to display to user and also for tracking expiration for auto-deletion
        endDate: int // timestamp; OPTIONAL, only present if status is 2 or 3, -1 otherwise
        status: int // 0 = pending, 1 = in progress, 2 = finished, 3 = solution accepted
    }
*/

class JobMetadata {
    name = '';
    description = '';
    colorTag = 4;
    system = 'metricMillis';
    bladeWidth = 0;
    maxCuts = 999999;
    minReusableLength = 0;
    material = null;

    constructor(props) {
        crust.updateProps(this, props);
    }
}

class Job {
    id = '';
    meta = {};
    date = new Date().getTime();
    endDate = -1;
    status = 0;

    constructor(props) {
        crust.updateProps(this, props);
    }
}


const slice = createSlice({
    name: "jobs",
    initialState: {
        list: []
    },
    reducers: crust.getSliceReducers({
        list: {
            jobsReceived: crust.entitiesReceived,
            jobsUpdated: crust.entitiesUpdated,
            jobAdded: crust.entityAdded,
            jobAdjusted: crust.entityAdjusted,
            jobDeleted: crust.entityDeleted
        }
    })
});


export default slice.reducer;


export { Job, JobMetadata };


export const {
    jobsReceived,
    jobsUpdated,
    jobAdded,
    jobAdjusted,
    jobDeleted
} = slice.actions;


export const getJobs = state => state.features.jobs.list;
