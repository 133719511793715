import { Material } from "../store/features/materials";
import api from "./api";

const getMaterials = async () => {
    try {
        const response = await api.call('/materials');
        if (!response.ok) {
            return {
                success: false
            };
        }
        const materials = [];
        response.data.materials.forEach((material) => {
            const parsed = { ...material };
            try {
                parsed.meta = JSON.parse(parsed.meta);
            } catch (error) {}
            materials.push({ ...new Material(parsed) });
        });
        return {
            success: true,
            data: materials
        };
    } catch (error) {
        console.log(error);
        return {
            success: false,
            error
        }
    }
};

const getStocks = async (materialId) => {
    try {
        const response = await api.call('/all-stock?materialId=' + materialId);
        if (!response.ok) {
            return {
                success: false
            };
        }
        const responsePayload = response.data;
        return {
            success: true,
            data: {
                stocks: responsePayload.stocks,
                waste: responsePayload.waste,
                version: responsePayload.version
            }
        };
    } catch (error) {
        console.log(error);
        return {
            success: false,
            error
        };
    }
};

const materials = { getMaterials, getStocks };

export default materials;
