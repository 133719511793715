import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
// import NumbersIcon from '@mui/icons-material/Numbers';
import {
    Box,
    Button, Checkbox, Grid,
    InputAdornment,
    List,
    ListSubheader,
    Menu, Stack,
    TextField
} from "@mui/material";
import IconButton from '@mui/material/IconButton';
import { useCallback, useState } from "react";
import strings from '../../../config/strings';
import { rgbaMul } from '../../../util/util';

function getSelectorButtonWidth(text) {
    if (text.length <= 5) {
        return 4;
    }
    if (text.length <= 10) {
        return 6;
    }
    if (text.length <= 15) {
        return 8;
    }
    return 12;
}


function MaterialSelector({
    anchor, open, onClose, materialGroups, filters, onFiltersChanged,
    unique = false, selection, onChange
}) {
    const [checkedItems, setCheckedItems] = useState(null);
    const [filterText, setFilterText] = useState('');

    let currentlyCheckedItems;
    if (!unique) {
        if (filters) {
            // When controlled, pick up the array supplied as a prop initially, use the internal state for all subsequent renders
            // This allows the component to work with its own state until it's closed, avoiding re-renders in the parent on every single
            // state change
            if (checkedItems === null) {
                currentlyCheckedItems = filters;
            } else {
                currentlyCheckedItems = checkedItems;
            }
        } else {
            if (checkedItems === null) {
                currentlyCheckedItems = [];
            } else {
                currentlyCheckedItems = checkedItems;
            }
        }
    }

    const isChecked = useCallback((id) => {
        if (unique) {
            return (id === selection);
        } else {
            return currentlyCheckedItems.indexOf(id) !== -1;
        }
    }, [currentlyCheckedItems, unique, selection]);

    const isGroupChecked = useCallback((group) => {
        if (unique) {
            return false;
        } else {
            const predicate = item => currentlyCheckedItems.indexOf(item.id) !== -1;
            if (group.every(predicate)) {
                return 1;
            } else {
                if (group.some(predicate)) {
                    return -1;
                } else {
                    return 0;
                }
            }
        }
    }, [currentlyCheckedItems, unique]);

    const handleClose = useCallback(() => {
        if (!unique) {
            if (onFiltersChanged) {
                onFiltersChanged(currentlyCheckedItems);
            }
        }
        // Reset the internal state back to null when controlled, in order to pick up the actual props on the next time
        // the menu is opened
        if (filters) {
            setCheckedItems(null);
        }
        if (onClose) onClose();
    }, [onFiltersChanged, currentlyCheckedItems, filters, onClose, unique]);

    const onItemsToggled = useCallback((ids) => {
        if (unique) {
            let close = false;
            if (onChange) {
                close = (onChange(ids[0]) !== false);
            }
            if (close) {
                handleClose();
            }
        } else {
            const items = [...currentlyCheckedItems];
            ids.forEach((id) => {
                const foundIndex = items.indexOf(id);
                if (foundIndex !== -1) {
                    items.splice(foundIndex, 1);
                } else {
                    items.push(id);
                }
            });
            setCheckedItems(items);
        }
    }, [currentlyCheckedItems, unique, onChange, handleClose]);

    const onGroupCheckbox = useCallback((group) => {
        const checked = isGroupChecked(group);
        const toggle = [];
        if (checked !== -1) {
            group.forEach((item) => toggle.push(item.id));
        } else {
            group.forEach((item) => {
                if (!isChecked(item.id)) {
                    toggle.push(item.id);
                }
            });
        }
        onItemsToggled(toggle);
    }, [isChecked, isGroupChecked, onItemsToggled]);

    const onClearAllFilters = useCallback(() => {
        if (window.confirm(strings.get('qClearSel'))) {
            setCheckedItems([]);
        }
    }, []);

    const filterSplits = filterText.split(',').map(filter => filter.toLowerCase().trim());

    return (
        <Menu
            variant="menu"
            anchorEl={anchor}
            open={open}
            onClose={handleClose}
        >
            <Box sx={{ p: 2, pr: 1, width: 350 }}>
                <Stack spacing={2} direction="row" sx={{ pr: 1, width: '100%' }}>
                    <TextField
                        label={strings.get('filterByTypes')}
                        variant="outlined"
                        size="small"
                        sx={{ flex: 1 }}
                        value={filterText}
                        onChange={(event) => setFilterText(event.target.value)}
                        autoComplete="off"
                        InputProps={{
                            endAdornment: filterText.length > 0 ? <InputAdornment position="end">
                                <IconButton
                                    size="small"
                                    onClick={() => setFilterText('')}
                                    edge="end"
                                >
                                    <ClearIcon fontSize="inherit" />
                                </IconButton>
                            </InputAdornment> : null
                        }}
                    />
                    {!unique && <IconButton onClick={onClearAllFilters} disabled={!unique && currentlyCheckedItems.length === 0}>
                        <FilterAltOffIcon />
                    </IconButton>}
                </Stack>
                <List
                    sx={{
                        width: '100%',
                        bgcolor: 'background.paper',
                        position: 'relative',
                        overflow: 'auto',
                        maxHeight: 400,
                        '& ul': { padding: 0 },
                        mt: 1
                    }}
                    subheader={<li />}
                >
                    {materialGroups.map((materialGroup, index) => {
                        if (filterText.length > 0) {
                            const matches = filterSplits.some(
                                filter => filter.length > 0 && materialGroup.name.toLowerCase().includes(filter)
                            );
                            if (!matches) return null;
                        }
                        const groupChecked = isGroupChecked(materialGroup.variants);
                        return (
                            <li key={`section-${index}`}>
                                <ul>
                                    <ListSubheader sx={(theme) => ({ p: 0, backgroundColor: theme.palette.background.paper })}>
                                        <Box sx={(theme) => ({
                                            display: 'flex',
                                            p: 0,
                                            pl: 2,
                                            pr: 0.5,
                                            my: 1,
                                            mr: 1,
                                            borderRadius: theme.shape.borderRadius / 4,
                                            fontWeight: 'bold',
                                            backgroundColor: rgbaMul(theme.palette.divider, 1, 1, 1, 0.5)
                                        })}>
                                            {materialGroup.name}
                                            {!unique && <Box sx={{
                                                flex: 1,
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                                alignItems: 'center'
                                            }}>
                                                <Checkbox
                                                    checked={groupChecked === 1}
                                                    indeterminate={groupChecked === -1}
                                                    onClick={() => onGroupCheckbox(materialGroup.variants)}
                                                />
                                            </Box>}
                                        </Box>
                                    </ListSubheader>
                                    <Grid container spacing={2} sx={{ pr: 1, mb: 2 }}>
                                        {materialGroup.variants.map((variant, variantIndex) => {
                                            const checked = isChecked(variant.id);
                                            return (
                                                <Grid
                                                    item
                                                    key={`opt-${variantIndex}`}
                                                    xs={variant.variant === '' ? 12 : getSelectorButtonWidth(variant.variant)}
                                                >
                                                    <Button
                                                        variant={checked ? "contained" : "outlined"}
                                                        size="large"
                                                        sx={{ width: '100%' }}
                                                        startIcon={checked ? <CheckIcon /> : null}
                                                        onClick={() => onItemsToggled([variant.id])}
                                                    >
                                                        {variant.variant === '' ? materialGroup.name : variant.variant}
                                                    </Button>
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </ul>
                            </li>
                        );
                    })}
                </List>
            </Box>
        </Menu>
    );
}

export default MaterialSelector;
