import strings from "../strings";

const materialLibrary = [
    {
        label: 'Steel',
        altLabels: {
            ro: 'Oțel'
        },
        subName: 'Type',
        altSubNames: {
            ro: 'Tip'
        },
        sub: [
            {
                label: 'Rebar',
                altLabels: {
                    ro: 'Armătură'
                },
                subName: 'Material',
                sub: [
                    {
                        label: 'BST500',
                        variants: [
                            {
                                label: 'S',
                                weight: 100
                            },
                            {
                                label: 'Φ 20',
                                weight: 10
                            },
                            {
                                label: 'Φ 40',
                                weight: 20
                            },
                            {
                                label: 'Φ 60',
                                weight: 30
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        label: 'Wood',
        altLabels: {
            ro: 'Lemn'
        },
        variants: [
            {
                label: '',
            }
        ]
    }
];

function getMaterialLabel(material) {
    if (!material.altLabels) return material.label;
    return material.altLabels[strings.language] ?? material.label;
}

function getMaterialSubName(material) {
    if (!material.altSubNames) return material.subName;
    return material.altSubNames[strings.language] ?? material.subName;
}

export { getMaterialLabel, getMaterialSubName };
export default materialLibrary;
