import ClearIcon from '@mui/icons-material/Clear';
import StraightenIcon from '@mui/icons-material/Straighten';
import {
    Box, Checkbox, Divider, InputAdornment, TextField, Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import strings from '../../../config/strings';
import { convertPrecisionMeasSystemValue, getPrecisionMeasSystem } from "../../../util/meas";
import { rgbaMul } from '../../../util/util';


function Item({
    item, index, checkboxMargin = 0, amountMargin = 0, onChecked, onUnchecked, checked, amount, onAmountChanged, measurementSystem
}) {
    const [amountText, setAmountText] = useState('');
    const [userInputError, setUserInputError] = useState(false);

    const measSys = getPrecisionMeasSystem(measurementSystem);
    const metricMillisSys = getPrecisionMeasSystem('metricMillis');

    const reconv = convertPrecisionMeasSystemValue(
        convertPrecisionMeasSystemValue(item.length, 'metricMillis', measurementSystem),
        measurementSystem,
        'metricMillis'
    );
    const millimetricEquivalent = item.length !== reconv;

    useEffect(() => {
        if (!checked) {
            setAmountText('');
            setUserInputError(false);
        }
    }, [checked, userInputError]);

    let value;
    if (userInputError) {
        value = amountText;
    } else {
        if (typeof(amount) === 'number') {
            value = String(amount);
        } else {
            // No value (default) means all pieces
            value = String(item.count);
        }
    }

    let error = false;
    if (typeof(amount) === 'number') {
        // Show invalid values that come from prop (in case of list reconciliation after a /PATCH error)
        if (amount > item.count) {
            error = true;
        }
    } else {
        error = userInputError;
    }

    return (
        <Box key={'stock-' + item.id} sx={
            (theme) => ({ backgroundColor: index % 2 === 0 ? null : rgbaMul(theme.palette.divider, 1, 1, 1, 0.25) })
        }>
            <Box sx={{ width: '100%', height: 60, display: 'flex', overflow: 'hidden' }}>
                <Box sx={{
                    width: 70,
                    ml: checkboxMargin,
                    mr: 1,
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <Checkbox checked={checked} onChange={(event) => {
                        const checked = event.target.checked;
                        if (checked) {
                            if (onChecked) {
                                onChecked(item);
                            }
                        } else {
                            if (onUnchecked) {
                                onUnchecked(item);
                            }
                        }
                    }} />
                </Box>
                <Box sx={{ flex: 1, height: '100%', display: 'flex', overflow: 'hidden' }}>
                    <Box sx={{ flex: 1, height: '100%', display: 'flex', alignItems: 'center', overflow: 'hidden', pl: 1, pr: 1 }}>
                        <StraightenIcon sx={{ mr: 1 }} />
                        <Typography sx={{ flex: 1, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            {measSys.encode(convertPrecisionMeasSystemValue(item.length, 'metricMillis', measurementSystem)) + ' ' + measSys.notation}
                            {millimetricEquivalent && <>
                                <br />
                                <span style={{ opacity: 0.5 }}>
                                    ≈ {metricMillisSys.encode(item.length) + ' ' + metricMillisSys.notation}
                                </span>
                            </>}
                        </Typography>
                    </Box>
                    <Box sx={{ flex: 1, height: '100%', display: 'flex', alignItems: 'center', overflow: 'hidden', mr: 2 + amountMargin }}>
                        {!checked && <>
                            <ClearIcon sx={{ mr: 1, fontSize: 20 }} />
                            <Typography sx={{ flex: 1, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                {item.count} {strings.get('pcs')}
                            </Typography>
                        </>}
                        {checked && <TextField
                            type="number"
                            size="small"
                            sx={{ width: '30ch' }}
                            value={value}
                            error={error}
                            onChange={(event) => {
                                const value = event.target.value;
                                let parsed;
                                if (value !== '') {
                                    parsed = Number(value);
                                }
                                if (typeof(parsed) !== 'number') {
                                    setUserInputError(true);
                                    if (onAmountChanged) {
                                        onAmountChanged({ index, item, amount: null });
                                    }
                                } else {
                                    parsed = Math.round(parsed);
                                    let inputError = parsed <= 0 || parsed > item.count;
                                    setUserInputError(inputError);
                                    if (onAmountChanged) {
                                        onAmountChanged({ index, item, amount: inputError ? null : parsed });
                                    }
                                }
                                setAmountText(event.target.value);
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end"> / {item.count} {strings.get('pcs')}</InputAdornment>,
                                inputProps: {
                                    min: 0,
                                    max: item.count,
                                    step: 1
                                }
                            }}
                        />}
                    </Box>
                </Box>
            </Box>
            <Divider />
        </Box>
    );
}

export default Item;
