import AddIcon from '@mui/icons-material/Add';
import BookIcon from '@mui/icons-material/Book';
import CheckIcon from '@mui/icons-material/Check';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import {
    Autocomplete, Avatar, Box,
    Button, Card,
    CardContent, Checkbox, CircularProgress, Collapse, Divider, InputAdornment,
    List, ListItem, ListItemIcon, ListItemText, Menu, Stack, TextField, ToggleButton, ToggleButtonGroup, Typography
} from "@mui/material";
import IconButton from '@mui/material/IconButton';
import { useCallback, useState } from "react";
import { TransitionGroup } from 'react-transition-group';
import materialLibrary, { getMaterialLabel, getMaterialSubName } from '../../../config/constants/materialLibrary';
import strings from '../../../config/strings';
import { rgbaMul } from '../../../util/util';


function LibrarySelector({ label, options, onChange, ...props }) {
    return (
        <Autocomplete
            fullWidth
            size="small"
            options={options}
            autoHighlight
            {...props}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    inputProps={{
                        ...params.inputProps
                    }}
                />
            )}
            onChange={(event, newValue) => onChange && onChange(newValue)}
        />
    )
}

function librarySelectionHasSubcategory(selection) {
    if (!selection) return false;
    return selection.sub !== undefined;
}

function librarySelectionsYieldMaterials(selections) {
    const last = selections[selections.length - 1];
    if (!last) return false;
    return last.variants !== undefined;
}

function NewMaterialMenu({ anchor, open, onClose, onMaterialsAdded }) {
    const [tabIndex, setTabIndex] = useState(0);
    const [name, setName] = useState('');
    const [density, setDensity] = useState(null);
    const [nameError, setNameError] = useState(false);
    const [densityError, setDensityError] = useState(false);
    const [variants, setVariants] = useState([]);
    const [varDensities, setVarDensities] = useState([]);
    const [variantErrors, setVariantErrors] = useState([]);
    const [varDensityErrors, setVarDensityErrors] = useState([]);
    const [animateVariants, setAnimateVariants] = useState(true);
    const [librarySelections, setLibrarySelections] = useState([null]);
    const [libraryChecked, setLibraryChecked] = useState([]);
    const [working, setWorking] = useState(false);

    const reset = useCallback(() => {
        setTabIndex(0);
        setName('');
        setDensity(null);
        setNameError(false);
        setDensityError(false);
        setVariants([]);
        setVarDensities([]);
        setVariantErrors([]);
        setVarDensityErrors([]);
    }, []);

    const parseDensity = useCallback((density) => {
        if (density === null) {
            return 0;
        } else {
            return Number(density);
        }
    }, []);

    const onFinishButton = useCallback(async () => {
        let success = true;
        if (tabIndex === 0) {
            if (name.length === 0) {
                setNameError(true);
                success = false;
            }
            if (density !== null) {
                if (Number(density) <= 0) {
                    setDensityError(true);
                    success = false;
                }
            }
        }
        let vError = false;
        const newVariantErrors = [...variantErrors];
        variants.forEach((variant, index) => {
            if (variant.length === 0) {
                newVariantErrors[index] = true;
                vError = true;
            }
        });
        if (vError) {
            setVariantErrors(newVariantErrors);
            success = false;
        }
        vError = false;
        const newVarDensityErrors = [...varDensityErrors];
        varDensities.forEach((density, index) => {
            if (density !== null) {
                if (Number(density) === 0) {
                    newVarDensityErrors[index] = true;
                    vError = true;
                }
            }
        });
        if (vError) {
            setVarDensityErrors(newVarDensityErrors);
            success = false;
        }
        if (success) {
            if (onMaterialsAdded) {
                const materials = [];
                if (tabIndex === 0) {
                    const finalVariants = [...variants];
                    if (finalVariants.length === 0) {
                        finalVariants.push('');
                    }
                    finalVariants.forEach((variant, index) => {
                        materials.push({
                            name,
                            variant,
                            weight: variants.length === 0 ? parseDensity(density) : (
                                varDensities[index] === null ? parseDensity(density) : Number(varDensities[index])
                            )
                        });
                    });
                } else if (tabIndex === 1) {
                    const selectedMaterial = librarySelections[librarySelections.length - 1];
                    libraryChecked.forEach((current) => {
                        const variant = selectedMaterial.variants[current];
                        materials.push({
                            name: getMaterialLabel(selectedMaterial),
                            variant: getMaterialLabel(variant),
                            weight: variant.weight ? variant.weight : (selectedMaterial.weight ? selectedMaterial.weight : 0)
                        });
                    });
                }
                setWorking(true);
                await onMaterialsAdded(materials);
                setWorking(false);
            }
            if (onClose) {
                onClose();
            }
        }
    }, [
        tabIndex,
        name,
        density,
        variants,
        varDensities,
        variantErrors,
        varDensityErrors,
        onClose,
        onMaterialsAdded,
        parseDensity,
        librarySelections,
        libraryChecked
    ]);

    const selectedLibraryMaterial = librarySelections[librarySelections.length - 1];

    return (
        <Menu
            variant="menu"
            anchorEl={anchor}
            open={open}
            onClose={() => {
                if (working || !open) return false;
                if (window.confirm(strings.get('qDiscard'))) {
                    if (onClose) {
                        onClose();
                    }
                } else {
                    return false;
                }
            }}
            TransitionProps={{
                onExited: reset
            }}
        >
            <Box sx={{
                py: 2,
                width: 350,
                maxWidth: '80vw',
                maxHeight: '80vh',
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column'
            }}>
                <Box sx={{ px: 2 }}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 2 }}>
                        {strings.get('addMat')}
                    </Typography>
                    <ToggleButtonGroup
                        color="primary"
                        size="small"
                        value={tabIndex}
                        exclusive
                        onChange={() => {
                            let perform = true;
                            if (tabIndex === 1) {
                                if (librarySelections[0] !== null) {
                                    perform = window.confirm(strings.get('qAbandonSelection'));
                                }
                            }
                            if (perform) {
                                setTabIndex(tabIndex === 0 ? 1 : 0);
                                setLibrarySelections([null]);
                                setLibraryChecked([]);
                            }
                        }}
                        sx={{ display: 'flex', flex: 1 }}
                    >
                        <ToggleButton value={0} sx={{ flex: 1 }}>{strings.get('custom')}</ToggleButton>
                        <ToggleButton value={1} sx={{ flex: 1 }}>{strings.get('standardized')}</ToggleButton>
                    </ToggleButtonGroup>
                </Box>
                <Divider sx={{ mt: 1.5 }} />
                <Box sx={{ overflowY: 'auto' }}>
                    <Collapse in={tabIndex === 0} mountOnEnter unmountOnExit>
                        <Stack direction="column" spacing={1.5}>
                            <TextField
                                sx={{ mt: 1.5, mx: 2 }}
                                label={strings.get('matName')}
                                variant="outlined"
                                size="small"
                                value={name}
                                onChange={(event) => {
                                    setName(event.target.value);
                                    setNameError(false);
                                }}
                                error={nameError}
                                helperText={nameError ? strings.get('invalidName') : null}
                            />
                            <Stack direction="row" spacing={1} sx={{ alignItems: 'center', px: 2 }}>
                                <Checkbox checked={density !== null} onChange={(event) => setDensity(event.target.checked ? '' : null)} />
                                {density !== null && <TextField
                                    fullWidth
                                    label={strings.get('dens')}
                                    type="number"
                                    variant="outlined"
                                    size="small"
                                    value={density === null ? '' : density} // Prevent controlled-uncontrolled swap error
                                    onChange={(event) => {
                                        setDensity(event.target.value);
                                        setDensityError(false);
                                    }}
                                    error={densityError}
                                    helperText={densityError ? strings.get('enterAValidValue') : null}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            kg / m
                                        </InputAdornment>
                                    }}
                                />}
                                {density === null && <Typography variant="overline" component="div">
                                    {strings.get('useDens')}
                                </Typography>}
                            </Stack>
                            <Typography variant="overline" sx={{ px: 2 }}>
                                {strings.get('matVars')}
                            </Typography>
                            <Stack direction="column">
                                <Collapse in={variants.length === 0} mountOnEnter unmountOnExit>
                                    <Card variant="outlined" sx={{ mx: 2 }}>
                                        <CardContent sx={{ display: 'flex' }}>
                                            <Avatar sx={(theme) => ({ bgcolor: theme.palette.warning.light, mr: 2 })}>
                                                <LightbulbIcon />
                                            </Avatar>
                                            <Typography variant="caption" sx={{ flex: 1 }}>
                                                {strings.getMultiline('stockVariantsTip')}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Collapse>
                                <TransitionGroup enter={animateVariants}>
                                    {variants.map((variant, index) => <Collapse key={index}>
                                        <Stack direction="column" spacing={2} sx={(theme) => ({
                                                px: 2,
                                                py: 2,
                                                bgcolor: index % 2 === 0 ? rgbaMul(theme.palette.divider, 1, 1, 1, 0.2) : null
                                        })}>
                                            <Stack direction="row" spacing={2}>
                                                <TextField
                                                    fullWidth
                                                    label={strings.get('nVarName', [index + 1])}
                                                    variant="outlined"
                                                    size="small"
                                                    value={variant}
                                                    onChange={(event) => {
                                                        const newVariants = [...variants];
                                                        const newErrors = [...variantErrors];
                                                        newVariants[index] = event.target.value;
                                                        newErrors[index] = false;
                                                        setVariants(newVariants);
                                                        setVariantErrors(newErrors);
                                                    }}
                                                    error={variantErrors[index]}
                                                    helperText={index === 0 ? (
                                                        variantErrors[0] ? strings.get('invalidVariantName') : (
                                                            variants[0].length === 0 ? strings.get('varNameTip') : null
                                                        )
                                                    ) : (
                                                        variantErrors[index] ? strings.get('invalidVariantName') : null
                                                    )}
                                                />
                                                <Box>
                                                    <IconButton color="error">
                                                        <RemoveCircleIcon onClick={() => {
                                                            const newVariants = [...variants];
                                                            const newErrors = [...variantErrors];
                                                            const newVarDensityErrors = [...varDensityErrors];
                                                            newVariants.splice(index, 1);
                                                            newErrors.splice(index, 1);
                                                            newVarDensityErrors.splice(index, 1);
                                                            setVariants(newVariants);
                                                            setVariantErrors(newErrors);
                                                            setVarDensityErrors(newVarDensityErrors);
                                                        }} />
                                                    </IconButton>
                                                </Box>
                                            </Stack>
                                            <Stack direction="row" spacing={1} sx={{ alignItems: 'center', mb: 1.5 }}>
                                                <Checkbox checked={varDensities[index] !== null} onChange={(event) => {
                                                    const newDensities = [...varDensities];
                                                    newDensities[index] = event.target.checked ? '' : null;
                                                    setVarDensities(newDensities);
                                                }} />
                                                {varDensities[index] !== null && <TextField
                                                    fullWidth
                                                    label={strings.get('nVarDens', [index + 1])}
                                                    type="number"
                                                    variant="outlined"
                                                    size="small"
                                                    value={varDensities[index] === null ? '' : varDensities[index]}
                                                    onChange={(event) => {
                                                        const newDensities = [...varDensities];
                                                        const newDensityErrors = [...varDensityErrors];
                                                        newDensities[index] = event.target.value;
                                                        newDensityErrors[index] = false;
                                                        setVarDensities(newDensities);
                                                        setVarDensityErrors(newDensityErrors);
                                                    }}
                                                    error={varDensityErrors[index]}
                                                    helperText={varDensityErrors[index] ? strings.get('enterAValidValue') : null}
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">
                                                            kg / m
                                                        </InputAdornment>
                                                    }}
                                                />}
                                                {varDensities[index] === null && <Typography variant="overline" component="div">
                                                    {strings.get('diffDens')}
                                                </Typography>}
                                            </Stack>
                                        </Stack>
                                    </Collapse>)}
                                </TransitionGroup>
                            </Stack>
                            <Box
                                id="add-material-variant-container"
                                sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', px: 2, pb: 1.5 }}
                            >
                                <Typography variant="overline">
                                    {strings.get('addVar')}
                                </Typography>
                                <IconButton
                                    color="success"
                                    sx={{ ml: 1 }}
                                    onClick={() => {
                                        setVariants([...variants, '']);
                                        setVariantErrors([...variantErrors, false]);
                                        setVarDensities([...varDensities, null]);
                                        setVarDensityErrors([...varDensityErrors, false]);
                                        setAnimateVariants(variants.length < 1);
                                        setTimeout(() => {
                                            document.getElementById('add-material-variant-container').scrollIntoView({
                                                behavior: 'smooth',
                                                block: 'end'
                                            });
                                        }, 50);
                                    }}
                                >
                                    <AddIcon />
                                </IconButton>
                            </Box>
                        </Stack>
                    </Collapse>
                    <Collapse in={tabIndex === 1} mountOnEnter unmountOnExit>
                        <Stack direction="column" spacing={2} sx={{ mx: 1.5, mt: 1.5 }}>
                            <Stack direction="row" spacing={2} sx={{ p: 1 }}>
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Avatar sx={(theme) => ({ bgcolor: theme.palette.primary.light })}>
                                        <BookIcon />
                                    </Avatar>
                                </Box>
                                <Typography variant="body2">
                                    {strings.getMultiline('matLibTip')}
                                </Typography>
                            </Stack>
                            <Autocomplete
                                fullWidth
                                size="small"
                                options={materialLibrary.map((item) => ({
                                    ...item,
                                    label: getMaterialLabel(item)
                                }))}
                                autoHighlight
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={strings.get('matCat')}
                                        inputProps={{
                                            ...params.inputProps
                                        }}
                                    />
                                )}
                                onChange={(event, newValue) => { setLibrarySelections([newValue]); setLibraryChecked([]); }}
                            />
                            <TransitionGroup>
                                {librarySelections.map((selection, index) => {
                                    if (librarySelectionHasSubcategory(selection)) {
                                        return (
                                            <Collapse key={'libsel-' + index}>
                                                <LibrarySelector
                                                    label={getMaterialSubName(selection)}
                                                    options={selection.sub.map((item) => ({
                                                        ...item,
                                                        label: getMaterialLabel(item)
                                                    }))}
                                                    onChange={(value) => {
                                                        const selections = [...librarySelections];
                                                        selections[index + 1] = value;
                                                        selections.length = index + 2;
                                                        setLibrarySelections(selections);
                                                        setLibraryChecked([]);
                                                    }}
                                                    sx={{ mb: 2 }}
                                                />
                                            </Collapse>
                                        );
                                    }
                                    return null;
                                })}
                                {librarySelectionsYieldMaterials(librarySelections) && <Collapse>
                                    <Divider sx={{ mb: 1 }} />
                                    <Typography variant="overline">
                                        {strings.get('selImportVar')}
                                    </Typography>
                                    <List dense sx={{ mb: 1 }}>
                                        {selectedLibraryMaterial.variants.map((variant, index) => (
                                            <ListItem
                                                key={'variant-' + index}
                                                sx={(theme) => ({
                                                    backgroundColor: index % 2 === 0 ? null : rgbaMul(
                                                        theme.palette.divider, 1, 1, 1, 0.25
                                                    ),
                                                    borderRadius: theme.shape.borderRadius / 4
                                                })}
                                            >
                                                <ListItemIcon>
                                                    <Checkbox
                                                        edge="start"
                                                        checked={libraryChecked.indexOf(index) !== -1}
                                                        onClick={() => {
                                                            const checked =[...libraryChecked];
                                                            const listIndex = checked.indexOf(index);
                                                            if (listIndex === -1) {
                                                                checked.push(index);
                                                            } else {
                                                                checked.splice(listIndex, 1);
                                                            }
                                                            setLibraryChecked(checked);
                                                        }}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={<>
                                                        {getMaterialLabel(selectedLibraryMaterial)}
                                                        <Divider />
                                                        <b>{getMaterialLabel(variant).length === 0 ? <i>{strings.get('genericVariant')}</i> : getMaterialLabel(variant)}</b>
                                                    </>}
                                                    secondary={variant.weight ? (variant.weight + ' kg/m') : (
                                                        selectedLibraryMaterial.weight ? (selectedLibraryMaterial.weight + ' kg/m') : null
                                                    )}
                                                />
                                            </ListItem>
                                        ))}
                                    </List>
                                </Collapse>}
                            </TransitionGroup>
                        </Stack>
                    </Collapse>
                </Box>
                <Box>
                    <Divider sx={{ mb: 2 }} />
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                            variant="contained"
                            color="success"
                            startIcon={working ? <CircularProgress size="1em" color="inherit" /> : <CheckIcon />}
                            onClick={onFinishButton}
                            disabled={(tabIndex === 1 && libraryChecked.length === 0) || working}
                        >
                            {strings.get('addMat')}
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Menu>
    );
}

export default NewMaterialMenu;
